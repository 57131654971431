import React, { useEffect, useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { HiArrowSmUp } from 'react-icons/hi';
import Select from "react-select";
import ApiClient from '../../methods/api/apiClient';

const Html = ({
    tab,
    edit,
    ChangeStatus,
    Changetype,
    statusChange,
    pageChange,
    deleteItem,
    filters,
    ShowDeleteModal,
    ShowActiveModal,
    setShowActiveModal,
    setShowDeleteModal,
    loaging,
    loading,
    data,
    isAllow,
    setFilter,
    getData,
    ProfessionsFilter,
    total = { total }
}) => {
    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId)
    }
    const [StatusData, setStatusData] = useState({});
    const ConfirmFunction = () => {
        statusChange(StatusData);
    }
    const [Professions, setProfessions] = useState([]);

    const GetAllProfession = () => {
        ApiClient.get("skill/types").then((res) => {
            if (res?.success) {
                const data = res.data;
                const MyArray = [{ label: "All", value: "" }];
                data.map((item) => {
                    MyArray.push({ label: item.name, value: item.id });
                });
                console.log(MyArray, "professions data");
                setProfessions(MyArray);
            }
        });
    }

    useEffect(() => {
        GetAllProfession();
    }, []);

    const sorting = (key) => {
        let sorder = 'asc';
        if (filters.key === key) {
            sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
        }
        setFilter({ key, sorder });

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getData({ sortBy, key, sorder });
    };

    const renderSortIcon = (columnKey) => {
        if (filters.key !== columnKey)
            return <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
        else {
            if (filters.sorder === 'asc') {
                <HiArrowSmUp className="shrink-0 inline text-sm" />;
            } else {
                <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
            }
        }
        return filters.sorder === 'asc' ? (
            <HiArrowSmUp className="shrink-0 inline text-sm" />
        ) : (
            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
        );
    };

    return (
        <Layout>
            <div className='heightVH'>
                <CommonActiveModal show={ShowActiveModal} setShow={setShowActiveModal} status={StatusData.status} confirm={ConfirmFunction} />
                <CommonDeleteModal show={ShowDeleteModal} setShow={setShowDeleteModal} confirm={Delete} />
                <div className="normalFlex align-items-center gap-2">
                    <h3 className="hedding">
                        Professions
                    </h3>
                    <div className="d-flex">
                       
                        <div className="dropdown addDropdown mt-0">
                            <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                            </button>
                            <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                                <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                                <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                                <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                            </div>
                        </div>
                        <div className="dropdownRadius all_selects mt-0 ml-2">
                            <Select
                                value={Professions.find(option => option.value === filters?.id)} // Ensure the selected value matches one of the options
                                placeholder="All"
                                onChange={e => ProfessionsFilter(e.value)}
                                options={Professions}
                            />
                        </div>
                        <div>{isAllow('addSkillType') ? <Link className="btn btn-primary ms-2" to="/addeditmasterskils">
                            <i class="fa fa-plus me-2" aria-hidden="true"></i>   Add  Profession
                        </Link> : <></>}</div>
                    </div>
                </div>
                {tab == 'grid' ? <>
                </> : <>
                    <div className="table-responsive">
                        <div className='table_section'>
                            <table class="table">
                                <thead className='table_head'>
                                    <tr className='heading_row'>
                                        <th onClick={(e) => sorting('name')} scope="col" className='table_data'> Professions{" "}<span className="mr-2">
                                            {renderSortIcon('name')}
                                        </span></th>
                                        <th scope="col" className='table_data'>Hard Skills</th>
                                        <th scope="col" className='table_data'>Status</th>
                                        <th scope="col" className='table_data'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((itm, index) => (<tr className='data_row'>
                                        <td className='table_dats' onClick={e => edit(itm?.hardSkillDetails?._id)}>{itm?.name}</td>
                                        <td className='table_dats' onClick={e => edit(itm?.hardSkillDetails?._id)}>{itm?.hardSkillDetails?.name}</td>
                                        <td className='table_dats'>
                                            <div className={`${itm.status}`} >
                                                <span className='contract'>
                                                    {itm.status == 'deactive' ? <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={false} /> : <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={true} />}
                                                </span>
                                            </div>
                                        </td>
                                        <td className='table_dats'>
                                            <div className="action_icons gap-3">
                                                {isAllow('editSkillType') ? <>
                                                    <a className="edit_icon iconsWidthAdjust" onClick={e => edit(itm?.hardSkillDetails?._id)}>
                                                        <i class="material-icons edit" title="Edit">edit</i>
                                                    </a>
                                                </> : <></>}
                                                {isAllow('deleteSkillType') ? <span className='edit_icon iconsWidthAdjust' onClick={() => { setDeleteId(itm.id); setShowDeleteModal("block") }}>
                                                    <i class="material-icons delete" title='Delete'> delete</i>
                                                </span> : <></>}
                                            </div>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>}
                {/* {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>} */}
                {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
                            <div className="text-danger text-center">No Data</div>
                        ) : null}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper'>
                        <span>Show {filters.count} from {total} Skill Types</span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>
        </Layout>
    );
};

export default Html;



