import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme style file
import { IoIosArrowDown } from "react-icons/io";

const CustomDateRangePicker = ({ onChange, value }) => {
    const ref = useRef()
    const [open, setOpen] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const handleSelect = (ranges,) => {
        setSelectionRange(ranges.selection);
        onChange(ranges.selection, [ranges?.selection?.startDate, ranges?.selection?.endDate])

    };
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    return (

        <div ref={ref}>

            <div data-tooltip-id={`tooltip`} className="calendar-n form-control " onClick={e => setOpen(!open)}>
                <p> {value?.startDate == '' ? 'Select Date' : `${moment(selectionRange.startDate).format('MM/DD/YYYY')} -${moment(selectionRange.endDate).format('MM/DD/YYYY')}`}</p>
                <IoIosArrowDown className={`h-[18px] text-bold w-5 text-[#75757A]`} />
            </div>

            {open && (
                <>
                    <div className=' calendar-main'>
                        <DateRangePicker
                            onChange={handleSelect}
                            ranges={[value]}
                            showSelectionPreview={false}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            direction="horizontal"
                            preventSnapRefocus={true}
                            calendarFocus="backwards"
                        />
                        <div className='calendar-apply d-flex justify-between align-items-center'>
                           
                            <button className='btn btn-primary ml-auto' onClick={e => setOpen(false)}>Apply</button>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
};

export default CustomDateRangePicker;
