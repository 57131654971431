import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../methods/api/apiClient';
import './style.scss';
import loader from '../../methods/loader';
import userTableModel from '../../models/table.model';
import categoryType from '../../models/categoryType.model';
import Html from './html';
// import { AssessmentsType } from '../../models/type.model';
import { useHistory } from 'react-router-dom';
import environment from '../../environment';
import axios from 'axios';
import { toast } from 'react-toastify';

const Blogs = (p) => {
    const user = useSelector(state => state.user)
    const [ShowActiveModal, setShowActiveModal] = useState("none");
    const [ShowDeleteModal, setShowDeleteModal] = useState("none");
    const searchState = useSelector((state) => state.search);
    const satusfilter = localStorage.getItem("blogstatus")
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '', category: "", status: satusfilter || '' })
    const [data, setData] = useState([])
    const [tab, setTab] = useState('list')
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const [tableCols, setTableCols] = useState([])
    const [form, setform] = useState()
    const [loading, setloading] = useState(true)



    // const [form, setform] = useState({
    //     id: "",
    //     title: "",
    //     description: "",
    //     category: "id",
    //     image: "",
    //   });
    const types = categoryType.list
    const history = useHistory()
    useEffect(() => {
        let cols = []
        for (let i = 0; i <= 2; i++) {
            cols.push(userTableModel.category[i])
        }
        setTableCols(cols)
    }, [])

    useEffect(() => {
        // GetcategoriesTypes()
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])


    const uTableCols = () => {
        let exp = []
        if (tableCols) exp = tableCols
        let value = []
        userTableModel.category.map(itm => {
            if (itm != exp.find(it => it.key == itm.key)) {
                value.push(itm)
            }
        })
        return value
    }

    const addCol = (itm) => {
        setTableCols([...tableCols, itm])
    }


    const removeCol = (index) => {
        let exp = tableCols
        exp.splice(index, 1);
        setTableCols([...exp])
    }

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('blogs', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
            setloading(false)
        })
    }


    const GetcategoriesTypes = () => {
        loader(true);
        ApiClient.get(`categories`).then(res => {
            if (res.success) {


                setCategoriesdata(res.data);
            }
            loader(false)
        })
    }


    const clear = () => {
        setFilter({ ...filters, search: '', page: 1 })
        getData({ search: '', page: 1 })
    }

    const deleteItem = (id) => {
        // if (window.confirm("Do you want to delete this")) {
        loader(true)
        ApiClient.delete(`delete?model=blogs&id=${id}`).then(res => {
            if (res.success) {
                toast.success(res.message)
                setShowDeleteModal("none");
                clear()
            }
            loader(false)
        })
        // }
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })
    }

    const modalResult = (e) => {
        modalClosed()
    }

    const openModal = (itm) => {
        let extra = new Date().getTime()
        setform({ ...itm, extra })
        document.getElementById("openuserModal").click()
    }

    const ChangeRole = (e) => {
        setFilter({ ...filters, catType: e, page: 1 })
        getData({ catType: e, page: 1 })
    }
    const ChangeStatus = (e) => {
        localStorage.setItem('blogstatus', e)
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }

    const exportCsv = () => {
        loader(true)
        ApiClient.get('user/csv').then(res => {
            if (res.success) {
                let url = res.path
                let downloadAnchor = document.getElementById("downloadJS")
                downloadAnchor.href = url
                downloadAnchor.click()
            }
            loader(false)
        })
    }

    const colClick = (col, itm) => {
        if (col.key == 'healthClinicId') {
        }
    }

    const statusChange = (itm) => {
        let modal = 'blogs'
        let status = 'active'
        if (itm.status == 'active') status = 'deactive'

        // if(window.confirm(`Do you want to ${status=='active'?'Activate':'Deactivate'} this blogs`)){
        loader(true)
        ApiClient.put(`change/status?model=blogs`, { status, id: itm.id }).then(res => {
            if (res.success) {
                getData()
                setShowActiveModal("none")
                toast.success(`blogs ${status == 'active' ? 'Activated' : 'Deactivated'} Successfully`)
            }
            loader(false)
        })
        // }
    }


    const edit = (id) => {
        history.push(`/AddEditBlogs/${id}`)
    }

    const view = (id) => {
        history.push("/userDetail/" + id)
    }
    const tabChange = (tab) => {
        setTab(tab)
    }

    const exportfun = async () => {
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}api/export/region`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Roles.xlsx`;
        link.click();
    }


    const isAllow = (key = '') => {
        let permissions = user.role?.permissions
        let value = permissions?.[key]
        if (user?.role && user.role.id == environment.adminRoleId) value = true
        return value
    }
    return <><Html
        isAllow={isAllow}
        edit={edit}
        colClick={colClick}
        tabChange={tabChange}
        exportfun={exportfun}
        tab={tab}
        types={types}
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        openModal={openModal}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem}
        exportCsv={exportCsv}
        uTableCols={uTableCols}
        removeCol={removeCol}
        ShowDeleteModal={ShowDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        view={view}
        filters={filters}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        total={total}
        ShowActiveModal={ShowActiveModal}
        setShowActiveModal={setShowActiveModal}
        statusChange={statusChange}
        loading={loading}
    />
    </>;
};

export default Blogs;
