import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { HiOutlineArrowDown } from 'react-icons/hi';
import { HiArrowSmUp } from 'react-icons/hi';

const Html = ({
  tab,
  edit,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  HandleSkillType,
  ShowDeleteModal,
  setShowDeleteModal,
  ShowActiveModal,
  setShowActiveModal,
  loaging,
  data,
  isAllow,
  setFilter,
  getData,
  loading,
  total = { total },
}) => {
  const [SelectedType, setSelectedType] = useState("");
  const [skilltypesdata, setskillstypedata] = useState([]);
  const [show, setShow] = useState("");

  const GetskillTypes = () => {
    loader(true);
    ApiClient.get(`skill/types`).then((response) => {
      if (response.success) {
        setskillstypedata(response.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetskillTypes();
  }, []);
  const Findskillname = (id) => {
    skilltypesdata.filter((item, index) => {
      if (item.id == id) {
        setSelectedType(item.name);
      }
    });
  };
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const ConfirmFunction = () => {
    statusChange(StatusData);
  };

  const sorting = (key) => {
    let sorder = 'asc';
    if (filters.key === key) {
      sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
    }
    setFilter({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setFilter({ ...filters, sortBy, key, sorder });
    getData({ sortBy, key, sorder });
  };

  const renderSortIcon = (columnKey) => {
    if (filters.key !== columnKey)
      return <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
    else {
      if (filters.sorder === 'asc') {
        <HiArrowSmUp className="shrink-0 inline text-sm" />;
      } else {
        <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
      }
    }
    return filters.sorder === 'asc' ? (
      <HiArrowSmUp className="shrink-0 inline text-sm" />
    ) : (
      <HiOutlineArrowDown className="shrink-0 inline text-sm" />
    );
  };
  return (
    <Layout>
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        status={StatusData.status}
        confirm={ConfirmFunction}
      />
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <div className="normalFlex align-items-center">
        <h3 className="hedding mb-2">Soft Skills</h3>

        <div className="d-flex gap-2 articleReverse">
         

          <div className="dropdown addDropdown mt-0 fullWidth">
            <button
              className="btn btn-primary dropdown-toggle removeBg fullWidth"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters.status
                ? filters.status == "deactive"
                  ? "Inactive"
                  : filters.status
                : "All Status"}
            </button>
            <div
              className="dropdown-menu shadow bg_hover"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("")}
              >
                All Status
              </a>
              <a
                className={
                  filters.status == "active"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("active")}
              >
                Active
              </a>
              <a
                className={
                  filters.status == "Inactive"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("deactive")}
              >
                Inactive
              </a>
            </div>
          </div>
          <div className="fullWidth">
            {" "}
            {isAllow("addSkills") ? (
              <Link className="btn btn-primary  fullWidth" to="/addeditskill">
                <i className="fa fa-plus me-2"></i>
                Add Soft Skill
              </Link>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="dropdown addDropdown chnagesg ml-3">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.skillType==""?"All Types":SelectedType}
                        </button>
                        <div className="dropdown-menu shadow bg_hover " aria-labelledby="dropdownMenuButton">
                            <a className={filters.role == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => HandleSkillType('')}>All Types</a>
                            {skilltypesdata.map(itm => { 
                                return <a className={filters.skillType == itm.id ? 'dropdown-item active' : 'dropdown-item'} onClick={() =>{HandleSkillType(itm.id);Findskillname(itm.id)}}>{itm.name}</a>
                            })}
                        </div>
                    </div> */}
          {/* <div className="dropdown addDropdown mt-0 chnagesg  position-relative fullWidth">
            <select
              className="form-control  ccttype fullWidth"
              required
              value={filters?.skillType}
              onChange={(e) => HandleSkillType(e.target.value)}
            >
              <option value="">Select Category </option>
              {skilltypesdata &&
                skilltypesdata.map((item, index) => (
                  <option className="text-capitalize" value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <span class="material-icons dropCustom">arrow_drop_down</span>
          </div> */}
        </div>
      </div>

      {tab == "grid" ? (
        <></>
      ) : (
        <>
          <div className="table-responsive">
            <div className="table_section">
              <table class="table">
                <thead className="table_head">
                  <tr className="heading_row">
                    {/* <th scope="col" className="table_data">
                      Professions
                    </th> */}
                    <th onClick={(e) => sorting('name')} scope="col" className="table_data">
                      Soft Skills{" "}<span className="mr-2">
                        {renderSortIcon('name')}
                      </span>
                    </th>

                    <th scope="col" className="table_data">
                      Status
                    </th>
                    <th scope="col" className="table_data">
                      Actions
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {data?.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )?.map((itm, index) => (
                    <tr className="data_row">

                      <td className="table_data text-capitalize">
                        {itm && itm?.name}
                      </td>

                      {" "}
                      <td className="table_dats">
                        {" "}
                        <div className={` ${itm.status}`}>
                          <span className="contract">
                            {itm.status == "deactive" ? (
                              <Switch
                                onChange={(e) => {
                                  setStatusData(itm);
                                  setShowActiveModal("block");
                                }}
                                checked={false}
                              />
                            ) : (
                              <Switch
                                onChange={(e) => {
                                  setStatusData(itm);
                                  setShowActiveModal("block");
                                }}
                                checked={true}
                              />
                            )}{" "}
                          </span>
                        </div>
                      </td>
                      <td className="table_dats">
                        <div className="action_icons">
                          {isAllow("editSkills") ? (
                            <>
                              <a
                                className="edit_icon iconsWidthAdjust"
                                onClick={(e) => edit(itm._id)}
                              >
                                <i class="material-icons edit" title="Edit">
                                  edit
                                </i>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}

                          {isAllow("deleteSkills") ? (
                            <span
                              className="edit_icon"
                              onClick={() => {
                                setDeleteId(itm._id);
                                setShowDeleteModal("block");
                              }}
                            >
                              <i
                                class="material-icons delete"
                                title="Delete"
                              >
                                {" "}
                                delete
                              </i>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>

                      {/* end */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
        <div className="text-danger text-center">No Reports here</div>
      ) : null}

      {total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} Skills
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {/* {loaging ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>} */}
    </Layout>
  );
};

export default Html;
