let host = document.location.host;
let apiUrl = 'https://endpoint.myvirtualpal.com/'

const environment = {
  api: 'https://endpoint.myvirtualpal.com/',

  tinyKey: '4yb9dvs5u5mawk0i0ym4g6p48e0pgkutuhw9wtn86r80pyui',
  map_api_key: 'AIzaSyAiC7k_vVg7q1k0mgJs3OpIlc0P1IIP8NM',
  planTypeId: '64ad2ebce5aa778d26a54f02',
  EmployerId: "64e5cdfc5bb9a041a39046d5",
  VirtualId: "64e83a928599356bddc2fa00",
  RecruiterId: "652393ee64451a10065b76eb",
  userRoleId: '64b15102b14de6c28838f7d2',
  adminRoleId: '64b6af9ed91c0152642aa581',
  resellerTypeId: '64b23b7d690d1d5f7ae76102',
  googleMapAPiKey: "AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs",
  productTypeId: '64a7d198fa039f179c0320ca',
  stripe_publish_key: "pk_test_51KyZElEGnmCJcey2kk3glVh6N3xDcL9EGmxOOX6POD0xsaLNUJIBVZcNM4Kvg2Pe5Gyln1bn7Ge0WkdkbyQCRb1J00stuxPQRm",
};
export default environment;
