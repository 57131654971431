import { useEffect, useState } from 'react';

export default function CommonDelete({show,confirm,setShow}) {
    const [ShowModal,setShowModal]=useState("none")
    useEffect(()=>{
    setShowModal(show);
    },[show])
  return (
    <div> 
  
<div className={`modal  d-${show}`}  tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div  className="modal-dialog">
    <div  className="modal-content p-md-5 p-2">
 
      <div  className="modal-body text-center"> 
      <img src='/assets/img/delete.png' className='delete_icon' />
      <h5 className='mt-3 mb-3 suretext'>Are you sure want to delete?</h5>
      <div className='text-center'>
      <button type="button" onClick={e=>setShow("none")}  className="btn btn-secondary mr-3" data-dismiss="modal">Cancel</button>
        <button type="button" onClick={e=>confirm()}  className="btn btn-primary">Delete</button>
    
      </div>
      </div> 
       
    </div>
  </div>
</div>
    </div>
  )
}
