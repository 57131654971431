import React, { useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { useHistory } from 'react-router-dom';
import Switch from "react-switch";
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import moment from 'moment';

const Html = ({
    view,
    edit,
    user,
    reset,
    add,
    colClick,
    tab,
    tabChange,
    ChangeRole,
    ChangeStatus,
    openModal,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    ChangeDocumentStatus,
    tableCols,
    loading,
    blockunblock,
    loaging,
    data,
    exportfun,
    roles,
    role,
    ShowActiveModal,
    setShowActiveModal,
    ShowDeleteModal,
    setShowDeleteModal,
    isAllow,
    Adduserfeature,
    GetEmployerStaff,
    empStaff,
    checkemplist,
    setCheckemplist,
    total = { total }
}) => {
    const Navigate = useHistory()

    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId);
    }

    const [StatusData, setStatusData] = useState({});
    const StatusCh = () => {
        statusChange(StatusData);
    }

    const [color, setcolor] = useState("success")

    const Handlecolor = () => {
        setcolor("primary")

    }


    const [userHistory, setUserHistory] = useState([])


    const GetUserHistory = (id) => {
        loader(true)
        ApiClient.get(`user-history?userId=${id}`).then((res) => {
            if (res?.success) {
                setUserHistory(res?.data)
            }
            loader(false)
        })
    }



    const OpenModal = (id) => {
        document.getElementById("OpenOldUserDetail").click()
        GetUserHistory(id)
    }

    const hanldeOpenChat = (item) => {
        document.getElementById('openChat').click() // Id of the chat icon.It open the chat app
        localStorage.setItem('openChat', JSON.stringify(item)) // Set the user data in localstorage to open the perticular chat
    }

    return (
        <Layout>
            <div className='heightVH'><CommonDeleteModal show={ShowDeleteModal} setShow={setShowDeleteModal} confirm={Delete} />
                <CommonActiveModal show={ShowActiveModal} setShow={setShowActiveModal} confirm={StatusCh} status={StatusData.status} />
                <div className="d-flex justify-content-between align-items-center">

                    <h3 className="hedding">
                        {role ? rolesModel.name(role) : 'Users'}
                    </h3>

                    <div className="d-flex filterFlex phView align-items-center flex-wrap gap-2 mb-sm-0 mb-5 buttonTabs">

                       
                        {/* <button onClick={exportfun} className="btn btn-primary">
                        Export
                    </button> */}
                        {checkemplist ? null :
                            <div className="dropdown addDropdown chnagesg mt-0 mr-1">
                                <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                                </button>
                                <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                                    <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                                    <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                                    <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                                </div>
                            </div>}

                        {/* <div className="dropdown addDropdown chnagesg mr-2">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.isVerifiedDocument ? filters.isVerifiedDocument == "pending" ? "Pending" : filters.isVerifiedDocument : 'All '}
                        </button>
                        <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                            <a className={filters.isVerifiedDocument == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("")}>All Status</a>
                            <a className={filters.isVerifiedDocument == 'pending' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("pending")} >Pending</a>
                            <a className={filters.isVerifiedDocument == 'accepted' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("accepted")} >Accepted</a>
                            <a className={filters.isVerifiedDocument == 'rejected' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("rejected")} >Rejected</a>
                        </div>
                    </div> */}
                        {role || checkemplist ? null : <div className="dropdown addDropdown chnagesg mt-0 mr-1">
                            <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.role ? methodModel.find(roles, filters.role, 'id')?.name : 'All User'}
                            </button>
                            <div className="dropdown-menu shadow bg_hover mr-1" aria-labelledby="dropdownMenuButton">
                                <a className={filters.role == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeRole('')}>All User</a>
                                {roles && roles.map(itm => {
                                    // if (itm.id != environment.adminRoleId)
                                    return <a className={filters.role == itm.id ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeRole(itm.id)}>{itm?.name}</a>
                                })}
                            </div>
                        </div>}


                        {filters.status || filters.role || checkemplist ? <>
                            <a className="btn btn-danger text-white mr-1" onClick={e => reset()}>
                                Reset
                            </a>
                        </> : <></>}
                        <div>
                            {checkemplist ? null : <ul className="nav nav-tabs border userstabs main_usertabs">
                                <li className="nav-item d-flex align-items-center">
                                    <a className={`nav-link  ${filters.isVerifiedDocument == '' ? "usertabcolor" : ""}`} aria-current="page" onClick={() => ChangeDocumentStatus("")} >All </a>
                                </li>
                                <li className="nav-item d-flex align-items-center">
                                    <a className={`nav-link ${filters.isVerifiedDocument == 'accepted' ? "usertabcolor" : ""}`} aria-current="page" onClick={() => ChangeDocumentStatus("accepted")} >Accepted </a>
                                </li>
                                <li className="nav-item d-flex align-items-center">
                                    <a className={`nav-link ${filters.isVerifiedDocument == 'rejected' ? "usertabcolor" : ""}`} onClick={() => ChangeDocumentStatus("rejected")} >Rejected </a>
                                </li>
                                <li className="nav-item d-flex align-items-center">
                                    <a className={`nav-link ${filters.isVerifiedDocument == 'pending' ? "usertabcolor" : ""}`} onClick={() => ChangeDocumentStatus("pending")} >Pending</a>
                                </li>
                                {/* <li className="nav-item">
                                <a className={`nav-link  active ${filters.isVerifiedDocument == '' ? "bg-primary" : "bg-light"}`} aria-current="page" onClick={() => ChangeDocumentStatus("")} >All </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${filters.isVerifiedDocument == 'accepted' ? "bg-primary" : ""}`} aria-current="page" onClick={() => ChangeDocumentStatus("accepted")} >Accepted </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${filters.isVerifiedDocument == 'rejected' ? "bg-danger" : ""}`} onClick={() => ChangeDocumentStatus("rejected")} >Rejected </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${filters.isVerifiedDocument == 'pending' ? "bg-success" : ""}`} onClick={() => ChangeDocumentStatus("pending")} >Pending</a>
                            </li> */}
                            </ul>}

                        </div>

                        {isAllow('addAdmins') ? <>


<span>       <button className="btn btn-primary mr-1 mb-0" onClick={e => add()}>
    <i class="fa fa-plus plusAdd" aria-hidden="true"></i>   Add {role ? rolesModel.name(role) : 'User'}
</button></span>
</> : <></>}


                        {/* 
                    <div className='icons_tab'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link ${tab == 'grid' ? 'active' : ''}`} id="employee-tab" onClick={e => tabChange('grid')}>
                                    <i className="fa fa-th"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link staff ${tab == 'list' ? 'active' : ''}`} id="staff-tab" onClick={e => tabChange('list')}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </li>

                        </ul>
                    </div> */}

                        {/* <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Add Columns
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            {uTableCols().map(itm => {
                                return <a className="dropdown-item" onClick={() => addCol(itm)} key={itm.value}>{itm.value}</a>
                            })}
                        </div>
                    </div> */}
                        {/* <button onClick={exportCsv} className="btn btn-primary" type="button">
                        Export
                    </button> */}
                    </div>


                </div>


                {tab == 'grid' ? <>
                    <div className="cardList">
                        <div className='row'>
                            {!loaging && data && data.map((itm, i) => {
                                return <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4'>
                                    <div className='new_cards'>
                                        <div className='user_card'>
                                            <div className='user_detail' onClick={e => view(itm.id)}>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />

                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm.fullName}
                                                    </h4>
                                                    <p className='user_info'>
                                                        {itm.email}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                <span className='contract'>
                                                    {itm.status}
                                                </span>
                                            </div>
                                        </div>


                                        <div className='user_proff user_proff1'>
                                            <div className='id_name'>
                                                <ul className='user_list'>
                                                    <li className='list_name'>
                                                        <a className='id'>
                                                            Role
                                                        </a>
                                                    </li>
                                                    <li className='list_name'>
                                                        <a className='id'>
                                                            Phone number
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='detail_list'>
                                                <ul className='user_list'>
                                                    <li className='list_names'>
                                                        <a className='id_name' onClick={e => edit(itm.id)}>
                                                            {itm.role?.name}

                                                        </a>
                                                    </li>
                                                    <li className='list_names'>
                                                        <a className='id_name' onClick={e => edit(itm.id)}>
                                                            <span className='call_icon'></span>
                                                            {itm.mobileNo ?
                                                                <>
                                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                                    {itm.dialCode} {itm.mobileNo}
                                                                </>
                                                                :
                                                                ''
                                                            }
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>

                    </div>
                </> : <>

                    <div className="table-responsive table_section">

                        <table class="table">
                            <thead className='table_head'>
                                <tr className='heading_row'>
                                    <th scope="col" className='table_data'>Name</th>
                                    <th scope="col" className='table_data'>Email</th>
                                    <th scope="col" className='table_data'>Role</th>
                                    {/* <th scope="col" className='table_data'>Last Login</th> */}
                                    <th scope="col" className='table_data'>Status</th>
                                    <th scope="col" className='table_data'>Profile Status</th>

                                    <th scope="col" className='table_data'>Date Created</th>
                                    {/* <th scope="col" className='table_data'>Last Modified</th> */}
                                    <th scope="col" className='table_data'>Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {checkemplist ? <>  {!loaging && empStaff && empStaff.map((itm, i) => {
                                    return <tr className='data_row'>


                                        <td className='table_dats' onClick={e => edit(itm.id)}>

                                            <div className='user_detail'>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                {/* <img alt="image" src={methodModel.userImg(user.image)} className="rounded-circle mr-1 user_imgs" /> */}




                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm.fullName}
                                                    </h4>
                                                    {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                                                </div>
                                            </div></td>
                                        <td className='table_dats' style={{ textTransform: "lowercase" }}><span className='t_padding'>{itm.email}</span></td>
                                        <td className='table_dats'><span className='t_padding'>{itm.role?.name} </span> </td>
                                        {/* <td className='table_dats'>{datepipeModel.date(itm.lastLogin)}</td> */}
                                        <td className='table_dats'>   <div className={` ${itm.status}`} >
                                            <span className=''>
                                                {itm.status == 'deactive' ? <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={false} /> : <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={true} />}
                                            </span>
                                        </div></td>
                                        <td className='table_dats'>   <div className={` ${itm.isVerifiedDocument && itm?.role?._id == environment.EmployerId ? itm.isVerifiedDocument : "accepted"} text-capitalize`} >
                                            {itm?.role?._id == environment.VirtualId || itm?.role?._id == environment.EmployerId || itm?.role?._id == environment.RecruiterId ? <span className='t_padding'> {itm?.isVerifiedDocument}</span> : <span className='t_padding'> Accepted</span>}
                                        </div></td>
                                        <td className='table_dats'> <span className='t_padding'>{moment(itm.createdAt).format("MM/DD/YYYY")}</span></td>
                                        {/* <td className='table_dats'>{datepipeModel.date(itm.updatedAt)}</td> */}
                                        {/* <td className='table_dats'> <span className='call_icon'></span>
                                        {itm.mobileNo ?
                                            <>
                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                {itm.dialCode} {itm.mobileNo}
                                            </>
                                            :
                                            ''
                                        }
                                    </td> */}



                                        {/* dropdown */}
                                        <td className='table_dats'>
                                            <div className="action_icons pt-1">
                                                {isAllow('editAdmins') ? <>
                                                    <a className='edit_icon' title="Edit" onClick={e => edit(itm.id)}>
                                                        <i class="material-icons edit" title="Edit">edit</i>
                                                    </a>
                                                </> : <></>}

                                                {isAllow('deleteAdmins') ? <>
                                                    <span className='edit_icon' title='Delete' onClick={() => { setDeleteId(itm.id); setShowDeleteModal("block") }}>
                                                        <i class="material-icons delete" title='Delete'> delete</i>
                                                    </span>
                                                </> : <></>}
                                                {
                                                    isAllow('viewAdmins') ? <>
                                                        <span className='edit_icon' title='View' onClick={() => Navigate.push(`userdetail/${itm.id}`)}>

                                                            <i className='fa fa-eye'></i>
                                                        </span>

                                                    </> : null
                                                }
                                                {itm?.role?._id == environment.EmployerId && itm?.accountManager_created || itm?.directManager_created ?
                                                    <span className='edit_icon' title='View Staff' >

                                                        <i onClick={e => { setCheckemplist(true); GetEmployerStaff(itm?.id) }} class="fa fa-male text-primary" ></i>
                                                    </span>

                                                    : null}

                                                {itm?.role?._id == environment.VirtualId ? <span className='star_icon my-1 ml-2' title='Feature' >

                                                    <i onClick={e => Adduserfeature(itm.id)} className={`fa fa-star text-${itm?.isfeatured ? "warning" : ""}`}></i>
                                                </span> : ""}



                                            </div>

                                        </td>

                                    </tr>

                                })
                                }</> : <>

                                    {!loaging && data && data.map((itm, i) => {
                                        return <tr className='data_row'>


                                            <td className='table_dats' onClick={e => edit(itm.id)}>

                                                <div className='user_detail'>
                                                    <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                    {/* <img alt="image" src={methodModel.userImg(user.image)} className="rounded-circle mr-1 user_imgs" /> */}




                                                    <div className='user_name'>
                                                        <h4 className='user'>
                                                            {itm.fullName}
                                                        </h4>
                                                        {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                                                    </div>
                                                </div></td>
                                            <td className='table_dats' style={{ textTransform: "lowercase" }}><span className='t_padding'>{itm.email}</span></td>
                                            <td className='table_dats'><span className='t_padding'>{itm.role?.name} </span> </td>
                                            {/* <td className='table_dats'>{datepipeModel.date(itm.lastLogin)}</td> */}
                                            <td className='table_dats'>   <div className={` ${itm.status}`} >
                                                <span className=''>
                                                    {itm.status == 'deactive' ? <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={false} /> : <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={true} />}
                                                </span>
                                            </div></td>
                                            <td className='table_dats'>   <div className={` ${itm.isVerifiedDocument && itm?.role?._id == environment.EmployerId ? itm.isVerifiedDocument : "accepted"} text-capitalize`} >
                                                {itm?.role?._id == environment.VirtualId || itm?.role?._id == environment.EmployerId || itm?.role?._id == environment.RecruiterId ? <span className='t_padding'> {itm?.isVerifiedDocument}</span> : <span className='t_padding'> Accepted</span>}
                                            </div></td>
                                            <td className='table_dats'> <span className='t_padding'>{datepipeModel.date(itm.createdAt)}</span></td>
                                            {/* <td className='table_dats'>{datepipeModel.date(itm.updatedAt)}</td> */}
                                            {/* <td className='table_dats'> <span className='call_icon'></span>
                                        {itm.mobileNo ?
                                            <>
                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                {itm.dialCode} {itm.mobileNo}
                                            </>
                                            :
                                            ''
                                        }
                                    </td> */}



                                            {/* dropdown */}
                                            <td className='table_dats'>
                                                <div className="action_icons pt-1">
                                                    {isAllow('editAdmins') ? <>
                                                        <a className='edit_icon edit_icon iconsWidthAdjust pr-1' title="Edit" onClick={e => edit(itm.id)}>
                                                            <i class="material-icons edit" title="Edit">edit</i>
                                                        </a>
                                                    </> : <></>}

                                                    {isAllow('deleteAdmins') ? <>
                                                        <span className='edit_icon' title='Delete' onClick={() => { setDeleteId(itm.id); setShowDeleteModal("block") }}>
                                                            <i class="material-icons delete" title='Delete'> delete</i>
                                                        </span>
                                                    </> : <></>}
                                                    {
                                                        isAllow('viewAdmins') ? <>
                                                            <span className='edit_icon ml-0' title='View' onClick={() => Navigate.push(`userdetail/${itm.id}`)}>

                                                                <i className='fa fa-eye'></i>
                                                            </span>

                                                        </> : null
                                                    }
                                                    {/* Chat Box */}
                                                    {(user.id || user.id != "") && (localStorage.getItem('token')) && (itm?.roleId == environment.EmployerId ||
                                                        itm?.roleId == environment.VirtualId || itm?.roleId == environment.RecruiterId) && (itm?.isVerifiedDocument === "accepted") ?
                                                        <span title='Chat' className="edit_icon" onClick={e => hanldeOpenChat(itm)}>
                                                            <span >🗨️</span>
                                                        </span> : null}

                                                    {itm?.isVerifiedDocument === "accepted" ? <span title='View Log File' className="edit_icon">
                                                        <i onClick={e => { OpenModal(itm?.id) }} class="fa fa-file"></i>
                                                    </span> : null
                                                    }

                                                    {itm?.role?._id == environment.EmployerId && itm?.accountManager_created || itm?.directManager_created ?
                                                        <span className='edit_icon' title='View Staff' >

                                                            <i onClick={e => { setCheckemplist(true); GetEmployerStaff(itm?.id) }} class="fa fa-male text-primary" ></i>
                                                        </span>

                                                        : null}

                                                    {itm?.role?._id == environment.VirtualId ? <span className='star_icon my-1 ml-2' title='Feature' >

                                                        <i onClick={e => Adduserfeature(itm.id)} className={`fa fa-star text-${itm?.isfeatured ? "warning" : ""}`}></i>
                                                    </span> : ""}





                                                </div>

                                            </td>

                                        </tr>

                                    })
                                    }</>}
                            </tbody>
                        </table>


                    </div>

                </>}


                {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
                    <div className="text-danger text-center py-4 ">No Data</div>
                ) : null}
                {checkemplist ? null :
                    <> {
                        !loaging && total > filters.count ? <div className='paginationWrapper'>
                            <span>Show {filters.count} from {total}  Users</span>
                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={pageChange}
                            />
                        </div> : <></>
                    }</>}

                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}

                <button type="button" class="btn btn-primary d-none" id='OpenOldUserDetail' data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>

                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg ">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">  User Log File</h5>
                            </div>
                            <div class="modal-body">
                                <div className='table-responsive'>
                                    <table class="table ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Mobile No.</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Company Name</th>
                                                <th scope="col"></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userHistory?.map((itm, i) =>
                                                <>
                                                    {itm?.oldUserData.map((item) =>
                                                        <tr>
                                                            <td >{item?.updatedAt ? moment(item?.updatedAt).format('DD MMM, YYYY') : "---"}</td>
                                                            <td >{item?.fullName || "---"}</td>
                                                            <td>{item?.email || "---"}</td>
                                                            <td>{item?.mobileNo || "---"}</td>
                                                            <td>{item?.address || "---"}</td>
                                                            <td>{item?.companyName || "---"}</td>
                                                            <td>Old</td>
                                                        </tr>)
                                                    }
                                                    <td >{itm?.updatedDetail?.updatedAt ? moment(itm?.updatedDetail?.updatedAt).format('DD MMM, YYYY') : "---"}</td>
                                                    <td >{itm?.updatedDetail?.fullName || "---"}</td>
                                                    <td>{itm?.updatedDetail?.email || "---"}</td>
                                                    <td>{itm?.updatedDetail?.mobileNo || "---"}</td>
                                                    <td>{itm?.updatedDetail?.address || "---"}</td>
                                                    <td>{itm?.updatedDetail?.companyName || "---"}</td>
                                                    <td>New</td>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div></div>
        </Layout>
    );
};

export default Html;
