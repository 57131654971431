import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

export default function ContactDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [form, setForm] = useState({});

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`contactusDetails?id=${id}`).then((res) => {
        if (res.success) {
          let form = res.data;
          setForm({
            ...form,
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            message: form.message,
          });
        }
        loader(false);
      });
    }
  }, [id]);

  return (
    <>
      <Layout>
        <div className="heightVH">
          {" "}


          <div className="normalFlex">
          <div className="">
            <div className="d-flex gap-1 align-items-center">
            <i className="fa fa-angle-double-left fontBack  " onClick={() => history.push("/contact/list")}></i>
                      <h3 className="hedding">Contact Us</h3>
            </div>
      
                    </div>
                  
          </div>
          <div className="pprofile1 my-3">
            <div className="row">
              <div className="col-md-6 mb-3">
                      <label>First Name</label>
                      <p className="profiledetailscls">{form.firstName}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                      {" "}
                      <label>Last Name</label>
                      <p className="profiledetailscls">{form.lastName}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Email</label>
                      <p className="profiledetailscls">{form.email}</p>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Objectives</label>
                      <p className="profiledetailscls">{form.message}</p>
                    </div>
                
              </div>  
            </div>
        </div>
      </Layout>
    </>
  );
}
