import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Pagination from "react-js-pagination";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import environment from "../../environment";
import axios from "axios";
import moment from "moment";
import methodModel from "../../methods/methods";
import { useSelector } from "react-redux";
import CustomDateRangePicker from "./CustomDateRange";
const { RangePicker } = DatePicker;

export default function AdminReport() {
  const [ReportData, setReportData] = useState([]);
  const [filters, setfilters] = useState({
    start_date: "",
    end_date: "",
    page: 1,
    count: 20,
    sortBy: "start_date desc",
  });
  const [Total, setTotal] = useState(0);
  const [VAList, setVAList] = useState([]);
  const [VirtualList, setVirtualList] = useState([]);
  const [Employerlist, setEmployerlist] = useState([]);
  const [Recruiterlist, setRecruiterlist] = useState([]);
  const [loading, setloading] = useState(true);
  const searchState = useSelector((state) => state.search);
  const user = useSelector((state) => state.user);

  const GetReportsData = (p = {}) => {
    const newfilters = { ...filters, ...p };
    newfilters.start_date = methodModel.MomentDateConvertor(
      newfilters.start_date
    );
    newfilters.end_date = methodModel.MomentDateConvertor(newfilters.end_date);
    loader(true);
    ApiClient.get(`NetProfitReport/list`, newfilters).then((res) => {
      if (res.success) {
        setReportData(res.data);
        setTotal(res?.total);
      }
      setTimeout(() => {
        setloading(false);
      }, 500);
      setTimeout(() => {
        loader(false);
      }, 500);
    });
  };
  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data });
      GetReportsData({ search: searchState.data });
    }
  }, [searchState]);

  const searchHandle = (e) => {
    e.preventDefault();
    // setfilters({ ...filters, search: e })
    GetReportsData({ search: filters?.search });
  };

  const clear = () => {
    setfilters({ ...filters, search: "" });
    GetReportsData({ search: "" });
  };

  const searchChange = (data) => {
    setfilters({ ...filters, search: data });
    // GetReports({ search: data })

    if (!data) {
      GetReportsData();
    }
  };

  const GetVALIST = () => {
    const filters = { status: "accepted", sortBy: "contractName asc" };
    ApiClient.get(`contracts`, filters).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        const updateddata = [];
        await newdata.map((item, index) => {
          updateddata.push({
            id: item?._id,
            name: item?.contractName || item?.project_details?.name,
          });
        });
        updateddata.unshift({ id: '', name: 'All' })
        setVAList(updateddata);
      }
    });
  };

  const GetRecruiterlist = () => {
    ApiClient.get(
      `users/list?role=${environment.RecruiterId}&sortBy=fullName asc`
    ).then((res) => {
      if (res.success) {
        const newdata = res?.data
        newdata.unshift({ id: '', fullName: 'All' })
        setRecruiterlist(newdata);
      }
    });
  };

  const Getemployerlist = () => {
    ApiClient.get(
      `users/list?role=${environment.EmployerId}&sortBy=fullName asc`
    ).then((res) => {
      if (res.success) {
        const newdata = res?.data
        newdata.unshift({ id: '', fullName: 'All' })
        setEmployerlist(newdata);
      }
    });
  };
  useEffect(() => {
    GetVALIST();
    GetReportsData();
    Getemployerlist();
    GetRecruiterlist();
  }, []);

  const [SelectedDateRange, setSelectedDateRange] = useState({
    startDate: '',
    endDate: '',
    type: 'now',
    key: 'selection',
  });

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setfilters({
      ...filters,
      start_date: dates[0] || "",
      end_date: dates[1] || "",
    });
    GetReportsData({ start_date: dates[0] || "", end_date: dates[1] || "" });
    // You can perform additional filtering or other actions based on the selected date range
  };

  const GetVirtualList = () => {
    ApiClient.get(
      `users/list?role=${environment.VirtualId}&isDeleted=false&isVerifiedDocument=accepted&sortBy=fullName asc`
    ).then((res) => {
      if (res.success) {
        const newdata = res?.data;
        newdata.unshift({ id: '', fullName: 'All' })
        setVirtualList(res?.data);
      }
    });
  };
  useEffect(() => {
    GetVirtualList();
  }, []);

  const DownloadNetProfit = async (e) => {
    e.preventDefault();
    await axios({
      method: "get",
      url:
        environment.api +
        `exportNetProfitReport?start_date=${methodModel.MomentDateConvertor(
          filters?.start_date
        )}&end_date=${methodModel.MomentDateConvertor(
          filters?.end_date
        )}&contractId=${filters?.contractId ? filters?.contractId : ""
        }&search=${filters?.search ? filters?.search : ""}&employerId=${filters?.employerId ? filters?.employerId : ""
        }&virtualId=${filters?.virtualId ? filters?.virtualId : ""
        }&recruiterId=${filters?.recruiterId ? filters?.recruiterId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: {
        token: localStorage.getItem("token"),
      },
    }).then((res) => {
      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "NetProfitReport.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const DownloadPdf = async (e) => {
    e.preventDefault();
    loader(true);
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api
        }netprofit-report/pdf?start_date=${methodModel.MomentDateConvertor(
          filters?.start_date
        )}&end_date=${methodModel.MomentDateConvertor(
          filters?.end_date
        )}&contractId=${filters?.contractId ? filters?.contractId : ""}&search=${filters?.search ? filters?.search : ""
        }&employerId=${filters?.employerId ? filters?.employerId : ""
        }&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&recruiterId=${filters?.recruiterId ? filters?.recruiterId : ""
        }&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "NetProfitReport.pdf";
                a.click();
                loader(false);
              });
          } else {
            loader(false);
          }
        });
    }, 3000);
    loader(false);
  };

  const Sort = (key) => {
    let sorder = "asc";
    if (filters.key === key) {
      sorder = filters.sorder === "asc" ? "desc" : "asc";
    }
    setfilters({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setfilters({ ...filters, sortBy, key, sorder });
    GetReportsData({ sortBy, key, sorder });
  };
  return (
    <div>
      <Layout>
        <>
          <div className="heightVH">
            {" "}
            <div className="cardit">
              <div className="normalFlex mb-4  flex-direction-set">
                <div className="">
                  <h3 className="report_heading ">Net Profit Reports</h3>
                </div>

                <div className="  ">
                  <div className="">
                    <div className="d-flex align-items-center flex-direction-set">
                      <div className="d-flex justify-content-end  set_serchbx flex-column me-3">
                        {/* <label className='mt-2 ml-1'>Search </label> */}
                        <form
                          className="headerSearch h-searchOne"
                          onSubmit={(e) => searchHandle(e)}
                        >
                          <input
                            type="text"
                            placeholder="Search..."
                            name="search"
                            value={filters?.search}
                            onChange={(e) => searchChange(e.target.value)}
                            className="form-control"
                          ></input>
                          <i
                            className="fa fa-search"
                            onClick={(e) => searchHandle(e)}
                            aria-hidden="true"
                          ></i>
                          {filters?.search != "" ? (
                            <i
                              className="fa fa-times"
                              onClick={clear}
                              aria-hidden="true"
                            ></i>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                      <div className="report-btn">
                        <button
                          className="btn btn-primary mb-0"
                          onClick={(e) => DownloadPdf(e)}
                        >
                          Pdf Export
                        </button>
                        <button
                          onClick={(e) => DownloadNetProfit(e)}
                          className="btn btn-primary ml-2 mb-0"
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3 align-items-end">
                <div className="col-md-4 ">
                  <div className="fullWidth ">
                    <label>Date Range</label>
                    {/* <RangePicker
                      className="dateRangePicker"
                      placeholder={["Start Date", "End Date"]}
                      format="MM-DD-YYYY"
                      onChange={handleDateChange}
                      // onBlur={e=>setblur(true)}
                      value={SelectedDateRange}
                    /> */}
                    <CustomDateRangePicker
                      onChange={handleDateChange}
                      value={SelectedDateRange}
                    />
                  </div>
                </div>
              </div>
              <div className="custom-flex justify-content-between align-items-end  mb-3">
                <div className="">
                  <div className="d-flex  flex-wrap  fullWidth gap-2 mb-3">
                    <div className="fullWidth ">
                      <label>Contract</label>
                      <div class="dropdown fullWidth">
                        <button
                          class="btn btn-secondary dropdown-toggle fullWidth employer_filt  fullWidth mb-0 w-100"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-sliders mr-1" aria-hidden="true"></i>
                          <span className="">
                            {" "}
                            {!filters.contractId
                              ? "Select Contract"
                              : VAList.find(
                                (item) => item.id == filters.contractId
                              ).name}
                          </span>
                        </button>
                        <ul class="dropdown-menu">
                          {VAList.map((item) => (
                            <li>
                              <a
                                class="dropdown-item"
                                onClick={(e) => {
                                  setfilters((prev) => ({
                                    ...filters,
                                    contractId: item?.id,
                                  }));
                                  GetReportsData({ contractId: item?.id });
                                }}
                              >
                                {item?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="fullWidth">
                      <label>Virtual Assistant </label>
                      <div class="dropdown fullWidth">
                        <button
                          class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0 w-100"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-sliders mr-1" aria-hidden="true"></i>{" "}
                          {!filters.virtualId
                            ? "Select VA"
                            : VirtualList.find(
                              (item) => item.id == filters.virtualId
                            )?.fullName}
                        </button>
                        <ul class="dropdown-menu">
                          {VirtualList.map((item) => (
                            <li>
                              <a
                                class="dropdown-item"
                                onClick={(e) => {
                                  setfilters((prev) => ({
                                    ...filters,
                                    virtualId: item?.id,
                                  }));
                                  GetReportsData({ virtualId: item?.id });
                                }}
                              >
                                {item?.fullName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="fullWidth">
                      <label>Employer</label>
                      <div class="dropdown fullWidth">
                        <button
                          class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0 w-100"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-sliders mr-1" aria-hidden="true"></i>{" "}
                          {!filters.employerId
                            ? "Select Employer"
                            : Employerlist.find(
                              (item) => item.id == filters.employerId
                            )?.fullName}
                        </button>
                        <ul class="dropdown-menu ">
                          {Employerlist.map((item) => (
                            <li>
                              <a
                                class="dropdown-item"
                                onClick={(e) => {
                                  setfilters((prev) => ({
                                    ...filters,
                                    employerId: item?.id,
                                  }));
                                  GetReportsData({ employerId: item?.id });
                                }}
                              >
                                {item?.fullName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="fullWidth">
                      <label>Recruiter</label>
                      <div class="dropdown fullWidth">
                        <button
                          class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0 w-100"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-sliders mr-1" aria-hidden="true"></i>
                          {!filters.recruiterId
                            ? "Select Recruiter"
                            : Recruiterlist.find(
                              (item) => item.id == filters.recruiterId
                            )?.fullName}
                        </button>
                        <ul class="dropdown-menu">
                          {Recruiterlist.map((item) => (
                            <li>
                              <a
                                class="dropdown-item"
                                onClick={(e) => {
                                  setfilters((prev) => ({
                                    ...filters,
                                    recruiterId: item?.id,
                                  }));
                                  GetReportsData({ recruiterId: item?.id });
                                }}
                              >
                                {item?.fullName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="d-flex align-items-end">
                      {filters.contractId ||
                        filters.virtualId ||
                        filters.start_date ||
                        filters?.end_date ||
                        filters.employerId ||
                        filters.recruiterId ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setfilters({
                              virtualId: "",
                              end_date: "",
                              start_date: "",
                              contractId: "",
                              name: "",
                              employerId: "",
                              recruiterId: "",
                            });
                            setSelectedDateRange({
                              startDate: '',
                              endDate: '',
                              type: 'now',
                              key: 'selection',
                            });
                            GetReportsData({
                              virtualId: "",
                              end_date: "",
                              start_date: "",
                              contractId: "",
                              name: "",
                              employerId: "",
                              recruiterId: "",
                            });
                          }}
                        >
                          Clear
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="border_top mt-4">
                <div className="table-responsive btable btable2">
                  <table className="table  table-bordered m-0">
                    <thead className="table_head">
                      <tr className="heading_row">
                        <th scope="col" className="">
                          Contract
                        </th>
                        <th scope="col" className="">
                          VA
                        </th>
                        <th scope="col" className="">
                          VA Recruiter
                        </th>
                        <th scope="col" className="">
                          VA Referred By
                        </th>
                        <th scope="col" className="">
                          Employer
                        </th>
                        <th
                          scope="col"
                          className="cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => Sort("start_date")}
                        >
                          Start Date
                        </th>
                        <th scope="col" className="">
                          Employer Recruiter
                        </th>
                        <th scope="col" className="">
                          Hours
                        </th>
                        <th scope="col" className="">
                          VA Amount
                        </th>
                        <th scope="col" className="">
                          VA Commission
                        </th>
                        <th scope="col" className="">
                          Employer Charge Amount
                        </th>
                        <th scope="col" className="">
                          Recruiter Commission
                        </th>
                        <th scope="col" className="">
                          Net Profit
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ReportData.map((item, index) => (
                        <tr className="data_row">
                          <td>
                            <div
                              className={`pms  p-2 m-0 ${index % 2 == 0 ? "backit" : "backitagin mb-0"
                                } `}
                              style={{ cursor: "pointer" }}
                            >
                              {item?.contractDetail?.createdBy === "admin"
                                ? item?.projectsDetail?.name ||
                                item?.contractDetail?.contractName ||
                                item?.contractDetail?.title
                                : item?.contractDetail?.contractName || "---"}
                            </div>{" "}
                          </td>
                          <td className="text-capitalize">
                            {item?.virtualDetail?.fullName || "----"}
                          </td>
                          <td className="text-capitalize">
                            {item?.virtualRecruiterName || "----"}
                          </td>
                          <td className="text-capitalize">
                            {item?.virtualAssistantName || "----"}
                          </td>
                          <td className="text-capitalize">
                            {item?.employerDetail?.fullName || "----"}
                          </td>
                          <td className="text-capitalize">
                            {methodModel.convertDate(
                              item?.contractDetail?.startDate
                            ) || "----"}
                          </td>
                          <td className="text-capitalize">
                            {item?.employerRecruiterName || "----"}
                          </td>

                          <td>
                            {parseFloat(item?.totalDurationOfTime || 0).toFixed(
                              2
                            )}
                            hr
                          </td>
                          <td>
                            {parseFloat(item?.VA_amount || 0).toFixed(2)} USD
                          </td>
                          <td>
                            {item?.virtualAssistantName
                              ? parseFloat(
                                item?.VAReferedCommission || 0
                              ).toFixed(2)
                              : "0.00"}{" "}
                            USD
                          </td>
                          <td>
                            {parseFloat(
                              item?.total_emp_amount || 0
                            ).toFixed(2)}{" "}
                            USD
                          </td>
                          <td>
                            {parseFloat(item?.total_commission || 0).toFixed(2)}{" "}
                            USD
                          </td>
                          <td>
                            {parseFloat(item?.net_profit || 0).toFixed(2)} USD
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* {ReportData.length == 0 ? <div className='text-danger text-center mt-3'>No Reports here</div> : null} */}
                  {loading ? (
                    <h3 className="text-center mt-2">Loading...</h3>
                  ) : ReportData.length == 0 ? (
                    <div className="text-danger text-center">
                      No Reports here
                    </div>
                  ) : null}
                </div>
              </div>
              {Total > ReportData.length ? (
                <div className="mt-2">
                  <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.count}
                    totalItemsCount={Total}
                    pageRangeDisplayed={5}
                    onChange={(e) => {
                      setfilters({ ...filters, page: e });
                      GetReportsData({ page: e });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}
