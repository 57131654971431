import React, { useEffect, useRef, useState } from "react";
import "./chat.scss";
import EmojiPicker, { EmojiStyle, SkinTones, Theme, Categories, EmojiClickData, Emoji, SuggestionMode, SkinTonePickerLocation } from "emoji-picker-react";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../../methods/api/apiClient";
import { ConnectSocket, SocketURL } from '../Socket/ConnectSocket';
import loader from "../../../methods/loader";
import axios from "axios";
import environment from "../../../environment";
import ImageView from "react-single-image-viewer";
import "react-single-image-viewer/dist/index.css";
import methodModel from "../../../methods/methods";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Chat = ({ update }) => {
  const Navigate = useHistory();
  const searchparams = new URLSearchParams(window.location.search);
  const user = useSelector(state => state.user);
  //  Chat State for Handle the Message,Search and Chat Slection and Loading 
  const [ChatLoad, setChatLoad] = useState(false);
  const [Typing, setTyping] = useState(false);
  const [SearchData, setSearchData] = useState("");
  const ChatId = useRef("")
  const [Messagetext, setMessageText] = useState("");
  const [ChatWithUser, setChatWithUser] = useState({});
  const ChatWithId = useRef("");
  const [UserOnline, setUserOnline] = useState(false);
  const RoomId = useRef("")
  const OpenFileUpload = useRef();
  //  For Handling the Scroll Behaviour
  const bootemel = useRef();
  const scrollToBottom = () => {
    bootemel?.current?.scrollIntoView({ behavior: 'smooth' });

  };
  //  For Handling Search Submit  
  //  Handle the Emoji View on the Clicking on Emoji button and Again Click it will Dissappear 
  const [ShowEmoji, setShowEmoji] = useState(false);
  const [MessageSent, setMessageSent] = useState(new Date())


  // Step First 
  //  For Getting Person List  Whose Present in their chat list 
  const [Activeperson, setActivePerson] = useState([])
  const GetActivePuposals = (p = {}) => {
    const filter = {
      status: "active"
    }
    const newfilter = { ...filter, ...p }
    ApiClient.get(`users/list`, newfilter).then(res => {
      setActivePerson(res.data)
      loader(false)
      // Get the user details from local storage, pass into functions to open perticular chat and remove details from localstorage
      const openChat = JSON.parse(localStorage.getItem('openChat'))
      if (openChat?.id) {
        HandleChatSelect(openChat?.id, openChat?.email, true);
        setChatWithUser(openChat);
        localStorage.removeItem('openChat')
      } else {
        // To set the default chat
        ChatId.current = ''
      }
    })
  }

  //  Step 2nd 
  //  This is For Selecting with whom you want to chat 
  const HandleChatSelect = (id, email, onlineStatus) => {
    setChatLoad(true)
    ChatWithId.current = id;
    axios.post(SocketURL + "chat/user/join-group", { "chat_by": user.id, "chat_with": id }).then(newres => {
      const res = newres.data;
      if (res.success) {
        const data = res.data;
        RoomId.current = data.room_id
        GetAllMessages(data.room_id)
        setUserOnline(onlineStatus)
        ConnectSocket.emit(`join-room`, { "room_id": data.room_id, "user_id": user.id })
      }
    })
    ChatId.current = id
  }


  // Step 3rd
  //  Getting All the Messages Whose chat you select  
  const AllMessages = useRef([])
  const GetAllMessages = (roomid) => {
    setChatLoad(true);
    axios.get(`${SocketURL}chat/user/message/all?room_id=${roomid}&user_id=${user.id}&login_user_id=${ChatWithId.current}`).then(newres => {
      const res = newres.data
      if (res.success) {
        const data = res.data && res.data.data;
        data.map((item, index) => {
          if (item.isRead == false && item.sender != user.id) {
            ConnectSocket.emit(`read-message`, { "message_id": item._id })
          }
        })
        AllMessages.current = res.data && res.data.data;
      }
      setChatLoad(false)
    }).catch(err => {
      toast.error("Error Occurred")
    })
  }

  //  Step 4th
  //  This is Main Step to Send the Message
  const [EmptyMessageError, setEmptyMessageError] = useState(false);
  const MessageSubmit = (e) => {
    e.preventDefault();
    setShowEmoji(false);
    if (Messagetext == "") {
      setEmptyMessageError(true); setTimeout(() => {
        setEmptyMessageError(false)
      }, 2000);
      return false
    }
    const Messages = AllMessages.current;
    const SendMessagepayload = {
      "room_id": RoomId.current,
      "type": "TEXT",
      sender: user.id,
      "content": Messagetext,
    }

    ConnectSocket.emit(`send-message`, SendMessagepayload)
    setMessageText("")
    scrollToBottom()
    setMessageSent(new Date())
  }


  useEffect(() => {
    if (user.loggedIn) {
      GetActivePuposals({})
    }
  }, [update])

  //  Here is the Main Step By UseEffect We can track the Changes in the Socket If Any Changes Occurr it will Hit Automatically
  useEffect(() => {
    ConnectSocket.on(`receive-message`, (newdata) => {
      const data = newdata.data;
      console.warn(data)
      if (data?.room_id == RoomId.current) {
        if (data.sender != user.id) {
          ConnectSocket.emit(`read-message`, { "message_id": data._id })
        }

        const payload = { user_id: user.id, room_id: RoomId.current }
        ConnectSocket.emit(`unread-count`, payload);
        AllMessages.current = [...AllMessages.current, { _id: data._id, content: data.content, sender: data.sender, type: data.type, media: data.media, createdAt: data.createdAt, isRead: false, message_type: data?.message_type, inviteId: data?.inviteId }]

        scrollToBottom()
        setMessageSent({ ...newdata.data })
      }
    })


    ConnectSocket.on(`read-message`, (data) => {
      const newdata = data.data;
      const messageid = newdata.message_id;
      const Array = AllMessages.current;
      Array.filter((item, index) => {
        if (item._id == messageid) {
          Array[index]["isRead"] = true
        }
      })
      AllMessages.current = [...Array]
      setMessageSent(new Date())
    })

    //  For Typing Listening
    ConnectSocket.on(`typing`, (data) => {
      const newdata = data.data;
      if (newdata.user_id == ChatWithId.current) {
        setTyping(newdata.typing)
      }
    })

    //  Delete Message Listen
    ConnectSocket.on(`delete-message`, (data) => {
      const newdata = data.data;
      const messageid = newdata.message_id;
      const Array = AllMessages.current;
      if (newdata.room_id == RoomId.current) {
        Array.filter((item, index) => {
          if (item._id == messageid) {
            AllMessages.current[index]["delete_type"] = newdata.type
          }
        })
        AllMessages.current = Array
        setMessageSent({ ...newdata })
      }
    })

    ConnectSocket.on(`user-online`, (data) => {
      const newdata = data.data
      if (localStorage.getItem("token")) { GetActivePuposals({ search: SearchData }) }
      if (newdata.user_id == ChatWithId.current) {
        setUserOnline(true);

      }
    })
    ConnectSocket.on(`user-offline`, (data) => {
      const newdata = data.data
      if (localStorage.getItem("token")) {
        GetActivePuposals({ search: SearchData })
      }
      if (newdata.user_id == ChatWithId.current) {
        setUserOnline(false);
        if (localStorage.getItem("token")) {
          GetActivePuposals();
        }
      }
    })
  }, [ConnectSocket])


  const HandleImageChange = (e) => {
    loader(true);
    const files = e.target.files;
    const file = files.item(0)
    ApiClient.multiImageUpload("single/documents?modelName=document", {
      file: file,
    }).then((res) => {
      if (res.success) {
        const path = res?.data?.imagePath;
        const SendMessagepayload = {
          "room_id": RoomId.current,
          "type": "IMAGE",
          "content": "",
          "media": [path],
        }
        ConnectSocket.emit(`send-message`, SendMessagepayload)
        scrollToBottom()

      }
      loader(false);
    });
  }


  //  This Function we will made for deleting the Message 
  const HandleDeleteMessage = (e, type, id) => {
    const payload = { "type": type, "message_id": id }
    ConnectSocket.emit('delete-message', payload)

  }


  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    GetActivePuposals({ search: SearchData })
  }


  const HandleChatIdmatch = (item) => {
    const friendId = item.id
    const newchatid = friendId
    if (ChatId.current == newchatid) {
      return true
    } else {
      return false
    }
  }

  const FindUserOnline = (item) => {
    const Response = item.friend && item.friend.isOnline
    return Response;
  }


  return <>
    <div className="home-page__content messages-page">
      <div className="container-fluid h-100">
        <div className="row px-0 h-100">
          <div className="col-12 col-md-4 col-lg-5 col-xl-5 px-0 messages-page__list-scroll">
            <div className="messages-page__header mb-0 px-4 pt-3 pb-3">
              <span className="messages-page__title">Chats</span>

            </div>
            <div className="messages-page__search mb-0 px-3 pb-3">
              <form onSubmit={e => HandleSearchSubmit(e)}>
                <div className="custom-form__search-wrapper">
                  <input type="text" className="form-control custom-form" id="search" value={SearchData} onChange={e => { setSearchData(e.target.value); e.target.value == "" ? GetActivePuposals({ search: "" }) : console.log("") }} placeholder="Search..." autoComplete="off" />
                  <button type="submit" className="custom-form__search-submit">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--search" viewBox="0 0 46.6 46.6">
                      <path d="M46.1,43.2l-9-8.9a20.9,20.9,0,1,0-2.8,2.8l8.9,9a1.9,1.9,0,0,0,1.4.5,2,2,0,0,0,1.5-.5A2.3,2.3,0,0,0,46.1,43.2ZM4,21a17,17,0,1,1,33.9,0A17.1,17.1,0,0,1,33,32.9h-.1A17,17,0,0,1,4,21Z" fill="#f68b3c" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>

            <ul className="messages-page__list pb-5 px-1 px-md-3">
              {Activeperson?.map((item, index) => (<li className={`messaging-member messaging-member--
          // ${FindUserOnline(item) ? "online" : "offline"}
           ${HandleChatIdmatch(item) ? "messaging-member--active" : ""}`}
                id={item.id}
                onClick={e => {
                  HandleChatSelect(item.id, item.email, true);
                  setChatWithUser(item);
                }}>
                <div className="messaging-member__wrapper">
                  <div className="messaging-member__avatar">
                    <img src={methodModel.userImg(item.image)} alt="Jenny Smith" loading="lazy" />
                  </div>

                  <span className="messaging-member__name">{item.fullName} </span>
                </div>
              </li>))}
              {Activeperson?.length == 0 ? <div className="text-center text-danger"><p>No Data</p></div> : null}
            </ul>
          </div>


          {ChatId.current != "" ? !ChatLoad ? <>
            <div className="chat col-12 col-md-8 col-lg-7 col-xl-7 px-0 pl-md-1">
              <div className="chat__container">
                <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                  <div className={`chat__messaging messaging-member--${UserOnline ? "online" : "offline"} pb-2 pb-md-2 pl-2 pl-md-4 pr-2`}>
                    <div className="chat__previous d-flex d-md-none">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--previous" viewBox="0 0 45.5 30.4">
                        <path d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z" fill="#f68b3c" />
                      </svg>
                    </div>

                    <div className="chat__infos pl-2 pl-md-0">
                      <div className="chat-member__wrapper" data-online="true" >
                        <div className="chat-member__avatar" >
                          <img src={methodModel.userImg(ChatWithUser.image)} alt="Jenny Smith" loading="lazy" />
                          <div className="user-status user-status--large"></div>
                        </div>
                        <div className="chat-member__details">
                          <span className="chat-member__name">{ChatWithUser.fullName}</span>
                          <span className="chat-member__status">{UserOnline ? "Online" : ""}</span>
                        </div>
                      </div>
                    </div>
                    <div className="chat__actions mr-2 ">
                      <ul className="m-0">
                        <li className="chat__details d-flex d-xl-none">
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 42.2 11.1">
                            <g>
                              <circle cx="5.6" cy="5.6" r="5.6" fill="#d87232" />
                              <circle cx="21.1" cy="5.6" r="5.6" fill="#d87232" />
                              <circle cx="36.6" cy="5.6" r="5.6" fill="#d87232" />
                            </g>
                          </svg>
                        </li>
                      </ul>

                    </div>
                  </div>
                  <div className="chat__content pt-4 px-3">
                    <ul className="chat__list-messages">
                      <li>
                        {AllMessages.current?.map((item, index) => {
                          if (item.delete_type && item.delete_type == "delete_for_me" && item.sender == user.id) { }
                          else {
                            return item.delete_type && item.delete_type == "delete_for_everyone" ? null : <>
                              <div className={`mb-1 mt-1  ${item?.sender == user?.id ? 'me-text' : ''}`}>
                                <span className="text-sm clr-text">{moment(item?.createdAt).format('hh:mm A , DD MMM')}</span>

                              </div>
                              <div className={`chat__bubble chat__bubble--${item.sender == user.id ? "me" : "you"}`}>
                                <div class="dropdown deleteIcon">
                                  <button class={`btn btn-secondary dropdown-toggle d-${item.sender == user.id ? "" : "none"} `} type="button" id="chatToogle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class='material-icons'>more_vert</span>
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="chatToogle">
                                    {/* <a class="dropdown-item" onClick={e=>HandleDeleteMessage(e,"delete_for_me",item._id)}>Delete For Me</a> */}
                                    <a class="dropdown-item" onClick={e => HandleDeleteMessage(e, "delete_for_everyone", item._id)}>Delete</a>
                                    {/* <a class="dropdown-item" href="#">Something else here</a> */}
                                  </div>
                                </div>
                                {item.type == "IMAGE" ? item.media && item.media.map((item) => (<ImageView width={150} height={100} src={`${environment.api}images/document/${item}`} />)) : item.content}
                              </div>
                            </>
                          }
                        })}

                        {AllMessages.current && AllMessages.current?.length == 0 ? <div className="text-center justify-content-center justify-content-lg-start space-left2 mt-5"><p>No Messages yet</p></div> : null}

                      </li>
                      <div ref={bootemel} className="container mt-3"></div>
                    </ul>
                  </div>

                  {Typing == true ? <p className="ml-3 text-center typing">Typing <img className='typingGif' src="/assets/img/typing.gif" /></p> : null}
                  {/* Emojis are here  */}
                  <input type="file" accept="image/*" onChange={e => HandleImageChange(e)} ref={OpenFileUpload} className="d-none" />
                  <form onSubmit={e => MessageSubmit(e)}>
                    <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-1">
                      <div className="custom-form__send-wrapper">
                        <input type="text" className={`form-control custom-form  ${EmptyMessageError ? "error" : ""}`} onBlur={e => ConnectSocket.emit(`typing`, { typing: false })} value={Messagetext} onChange={e => { setMessageText(e.target.value); ConnectSocket.emit(`typing`, { typing: true }) }} id="myTextInput" placeholder="Type your message…" autoComplete="off" />
                        <div className="custom-form__send-img" onClick={e => OpenFileUpload.current.click()}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send-img" viewBox="0 0 45.7 45.7">
                            <path d="M6.6,45.7A6.7,6.7,0,0,1,0,39.1V6.6A6.7,6.7,0,0,1,6.6,0H39.1a6.7,6.7,0,0,1,6.6,6.6V39.1h0a6.7,6.7,0,0,1-6.6,6.6ZM39,4H6.6A2.6,2.6,0,0,0,4,6.6V39.1a2.6,2.6,0,0,0,2.6,2.6H39.1a2.6,2.6,0,0,0,2.6-2.6V6.6A2.7,2.7,0,0,0,39,4Zm4.7,35.1Zm-4.6-.4H6.6a2.1,2.1,0,0,1-1.8-1.1,2,2,0,0,1,.3-2.1l8.1-10.4a1.8,1.8,0,0,1,1.5-.8,2.4,2.4,0,0,1,1.6.7l4.2,5.1,6.6-8.5a1.8,1.8,0,0,1,1.6-.8,1.8,1.8,0,0,1,1.5.8L40.7,35.5a2,2,0,0,1,.1,2.1A1.8,1.8,0,0,1,39.1,38.7Zm-17.2-4H35.1l-6.5-8.6-6.5,8.4C22,34.6,22,34.7,21.9,34.7Zm-11.2,0H19l-4.2-5.1Z" fill="#f68b3c" />
                          </svg>
                        </div>
                        <div className="custom-form__send-emoji" onClick={e => setShowEmoji(!ShowEmoji)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send-emoji" viewBox="0 0 46.2 46.2">
                            <path d="M23.1,0A23.1,23.1,0,1,0,46.2,23.1,23.1,23.1,0,0,0,23.1,0Zm0,41.6A18.5,18.5,0,1,1,41.6,23.1,18.5,18.5,0,0,1,23.1,41.6Zm8.1-20.8a3.5,3.5,0,0,0,3.5-3.5,3.5,3.5,0,0,0-7,0,3.5,3.5,0,0,0,3.5,3.5ZM15,20.8a3.5,3.5,0,0,0,3.5-3.5A3.5,3.5,0,0,0,15,13.9a3.4,3.4,0,0,0-3.4,3.4A3.5,3.5,0,0,0,15,20.8Zm8.1,15a12.6,12.6,0,0,0,10.5-5.5,1.7,1.7,0,0,0-1.3-2.6H14a1.7,1.7,0,0,0-1.4,2.6A12.9,12.9,0,0,0,23.1,35.8Z" fill="#f68b3c" />
                          </svg>
                        </div>
                        {ShowEmoji ?
                          <ul className="dropdown-menu d-block emoji_drop">
                            <li>
                              <div>
                                <EmojiPicker
                                  onEmojiClick={(e) => { setMessageText((inputValue) => inputValue + (e.emoji)); document.getElementById("myTextInput").focus(); }}
                                  autoFocusSearch={false}
                                  emojiStyle={EmojiStyle.NATIVE}
                                />
                              </div>
                            </li>
                          </ul> : null}
                        <button type="submit" className="custom-form__send-submit">
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send" viewBox="0 0 45.6 45.6">
                            <g>
                              <path d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z" fill="#d87232" />
                              <path d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z" fill="#d87232" />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>

            {/* {ProfileView? */}
            {/* <div className="col-12 col-md-5 col-lg-4 col-xl-3 px-4 px-sm-5 px-lg-4 user-profile"> */}
            {/* <div className="user-profile__close d-flex d-xl-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 38.8 38.9">
                  <g>
                    <path d="M2,38.9a1.9,1.9,0,0,1-1.4-.5,2.1,2.1,0,0,1,0-2.9L35.4.6a1.9,1.9,0,0,1,2.8,0,1.9,1.9,0,0,1,0,2.8L3.4,38.4A1.9,1.9,0,0,1,2,38.9Z" fill="#d87232" />
                    <path d="M36.8,38.9a1.9,1.9,0,0,1-1.4-.5L.6,3.4A1.9,1.9,0,0,1,.6.6,1.9,1.9,0,0,1,3.4.6L38.2,35.5a2.1,2.1,0,0,1,0,2.9A1.9,1.9,0,0,1,36.8,38.9Z" fill="#d87232" />
                  </g>
                </svg>
              </div> */}
            {/* <div  className="user-profile__wrapper">
          <div  className="user-profile__avatar">
            <img src={methodModel.userImg(ChatWithUser.image)} alt="Jenny Smith" loading="lazy" />
          </div>
          <div  className="user-profile__details mt-1">
            <span  className="user-profile__name">{ChatWithUser.fullName}</span>
            <span  className="user-profile__location">{ChatWithUser.address}</span>
          </div>
          <div  className="user-profile__description">
            <p>{ChatWithUser.description}</p>
          </div>
{ChatWithUser.role&&ChatWithUser.role.id!="64e83a928599356bddc2fa00"?null:<>
          <div  className="user-profile__learning mt-4">
            <span  className="user-profile__label">Skills</span>
            <ul  className="user-profile__tags user-profile__tags--primary mt-2">
              {ChatWithUser.skills&&ChatWithUser.skills.map((item,index)=>( <li><a >{item.label}</a></li> ))}
            </ul>
          </div>
          <div  className="user-profile__hobbies">
            <span  className="user-profile__label">Other Skills</span>
            <ul  className="user-profile__tags user-profile__tags--secondary mt-2">
              {ChatWithUser.otherSkills&&ChatWithUser.otherSkills.map((item)=>( <li>{item}</li>)) }
            </ul>
          </div>
</>}
        </div> */}
            {/* </div> */}
          </> : <img className="col-md-7 px-0 " src="assets/img/fakeani.gif" /> : <div className="chat col-12 col-md-8 col-lg-7 col-xl-7 px-0 pl-md-1">
            <div className="chat__container">
              <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                <img src="/assets/img/chat.jpg" className="chat_img" />
              </div>
            </div>
          </div>}
          {/* :null} */}
        </div>
      </div>
    </div>
    {/*  Modal For Hiring in  */}

  </>
}

export default Chat
