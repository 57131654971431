import { useEffect, useState } from 'react';

export default function CardDelterandPrimaryModal({show,confirm,setShow,imgurl,}) {
    const [ShowModal,setShowModal]=useState(show);
    useEffect(()=>{
        setShowModal(show);
        },[show])
      return (
        <div> 
      
    <div className={`modal  d-${show}`}  tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div  className="modal-dialog">
        <div  className="modal-content px-md-5 py-md-3 p-2">
          <div  className="modal-body text-center"> 
          <img src={imgurl} className='delete_icon primarycard_del' />
          <h5 className='mt-5 pricard_header'>Are you sure want to Set it  Primary?</h5>

          <div className='text-center mt-4'>
          <button type="button" onClick={e=>setShow("none")}  className="btn btn-secondary mr-3" data-dismiss="modal">Cancel</button>
            <button type="button" onClick={e=>confirm()}  className="btn btn-primary">Save</button>
        
          </div>
          </div> 
           
        </div>
      </div>
    </div>
        </div>
      )
}
