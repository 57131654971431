/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-08-21 22:37:55
 * */
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import axios from "axios";
import environment from "../../environment";
import Layout from "../../components/global/layout";
import moment from "moment";
import methodModel from "../../methods/methods";
import CustomDateRangePicker from "./CustomDateRange";
const { RangePicker } = DatePicker;
export default function RecruiterReport() {
  const user = useSelector((state) => state.user);
  const [filters, setfilters] = useState({
    name: "",
    start_date: "",
    end_date: "",
    employerId: "",
    contractId: "",
    recruiterId: "",
    virtualId: "",
    sortBy: "start_date desc",
  });
  const Navigate = useHistory();
  const [VAList, setVAList] = useState([]);
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const searchState = useSelector((state) => state.search);

  const GetReports = (p = {}) => {
    const NewFilters = { ...filters, ...p };
    NewFilters.start_date = methodModel.MomentDateConvertor(
      NewFilters.start_date
    );
    NewFilters.end_date = methodModel.MomentDateConvertor(NewFilters.end_date);
    loader(true);
    ApiClient.get(`recruiterReport`, NewFilters).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      setTimeout(() => {
        setloading(false);
      }, 500);
      setTimeout(() => {
        loader(false);
      }, 400);
    });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data });
      GetReports({ search: searchState.data });
    }
  }, [searchState]);

  const GetVALIST = () => {
    ApiClient.get(
      `users/list?role=${environment.VirtualId}&isDeleted=false&isVerifiedDocument=accepted&sortBy=fullName asc`
    ).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        newdata.unshift({ id: '', fullName: 'All' })
        setVAList(newdata);
      }
    });
  };
  useEffect(() => {
    GetVALIST();
    GetReports();
  }, []);

  const [SelectedDateRange, setSelectedDateRange] = useState({
    startDate: '',
    endDate: '',
    type: 'now',
    key: 'selection',
  });

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setfilters({
      ...filters,
      start_date: moment(dates[0]).format("YYYY-MM-DD") || "",
      end_date: moment(dates[1]).format("YYYY-MM-DD") || "",
    });
    GetReports({
      start_date: moment(dates[0]).format("YYYY-MM-DD") || "",
      end_date: moment(dates[1]).format("YYYY-MM-DD") || "",
    });
    // You can perform additional filtering or other actions based on the selected date range
  };

  const [EmployerList, setEmployerList] = useState([]);
  const DownloadVirtualReport = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api
        }exportRecruiterReport?start_date=${methodModel.MomentDateConvertor(
          filters?.start_date
        )}&end_date=${methodModel.MomentDateConvertor(
          filters?.end_date
        )}&recruiterId=${filters?.recruiterId ? filters?.recruiterId : ""
        }&search=${filters?.search ? filters?.search : ""}&employerId=${filters?.employerId ? filters?.employerId : ""
        }&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Reports.xlsx`;
    link.click();
  };

  const GetEmployers = () => {
    ApiClient.get(
      `users/list?role=${environment.EmployerId}&isDeleted=false&sortBy=fullName asc`
    ).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        newdata.unshift({ id: '', fullName: 'All' })
        setEmployerList(newdata);
      }
    });
  };

  const DownloadPdf = async (e) => {
    e.preventDefault();
    loader(true);
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api
        }recruiter-report/pdf?start_date=${methodModel.MomentDateConvertor(
          filters?.start_date
        )}&end_date=${methodModel.MomentDateConvertor(
          filters?.end_date
        )}&recruiterId=${filters?.recruiterId ? filters?.recruiterId : ""
        }&search=${filters?.search ? filters?.search : ""}&employerId=${filters?.employerId ? filters?.employerId : ""
        }&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "RecruiterReports.pdf";
                a.click();
                loader(false);
              });
          } else {
            loader(false);
          }
        });
    }, 3000);
    loader(false);
  };

  const [RecruiterList, setRecruiterList] = useState([]);
  const GetRecruiters = () => {
    ApiClient.get(
      `users/list?role=${environment.RecruiterId}&isDeleted=false&sortBy=fullName asc`
    ).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        newdata.unshift({ id: '', fullName: 'All' })
        setRecruiterList(newdata);
      }
    });
  };
  useEffect(() => {
    GetEmployers();
    GetRecruiters();
  }, []);

  const Sort = (key) => {
    let sorder = "asc";
    if (filters.key === key) {
      sorder = filters.sorder === "asc" ? "desc" : "asc";
    }
    setfilters({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setfilters({ ...filters, sortBy, key, sorder });
    GetReports({ sortBy, key, sorder });
  };

  const searchHandle = (e) => {
    e.preventDefault();
    // setfilters({ ...filters, search: e })
    GetReports({ search: filters?.search });
  };

  const clear = () => {
    setfilters({ ...filters, search: "" });
    GetReports({ search: "" });
  };

  const searchChange = (data) => {
    setfilters({ ...filters, search: data });
    // GetReports({ search: data })

    if (!data) {
      GetReports();
    }
  };
  return (
    <>
      <Layout>
        <div className="heightVH">
          {" "}
          <div className="cardit">
            <div className="normalFlex mb-4  flex-direction-set">
              <div className="">
                <h3 className="report_heading"> Reports</h3>
              </div>
              <div className="d-flex align-items-center flex-direction-set">
                <div className="d-flex justify-content-end  set_serchbx flex-column me-3">

                  <form
                    className="headerSearch"
                    onSubmit={(e) => searchHandle(e)}
                  >
                    <input
                      type="text"
                      placeholder="Search..."
                      name="search"
                      value={filters?.search}
                      onChange={(e) => searchChange(e.target.value)}
                      className="form-control"
                    ></input>
                    <i
                      className="fa fa-search"
                      onClick={(e) => searchHandle(e)}
                      aria-hidden="true"
                    ></i>
                    {filters?.search != "" ? (
                      <i
                        className="fa fa-times"
                        onClick={clear}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
                <div className="report-btn">
                  <button
                    className="btn btn-primary "
                    onClick={(e) => DownloadPdf(e)}
                  >
                    Pdf Export
                  </button>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={(e) => DownloadVirtualReport(e)}
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="d-flex  flex-wrap  fullWidth gap-2">
                <div className="datePickeWidth fullWidth">
                  <label>Date Filters </label>
                  {/* <RangePicker
                    className="dateRangePicker"
                    placeholder={["Start Date", "End Date"]}
                    format="MM-DD-YYYY"
                    onChange={handleDateChange}
                    // onBlur={e=>setblur(true)}
                    value={SelectedDateRange}
                  /> */}
                  <CustomDateRangePicker
                    onChange={handleDateChange}
                    value={SelectedDateRange}
                  />
                </div>
                <div className="fullWidth">
                  <label>VA </label>
                  <div class="dropdown fullWidth ">
                    <button
                      class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-sliders mr-2" aria-hidden="true"></i>
                      {filters.virtualId == ""
                        ? "Select VA"
                        : VAList.find((item) => item._id == filters.virtualId)
                          ?.fullName}
                    </button>
                    <ul class="dropdown-menu">
                      {VAList.map((item) => (
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={(e) => {
                              setfilters((prev) => ({
                                ...filters,
                                virtualId: item?._id,
                              }));
                              GetReports({ virtualId: item?._id });
                            }}
                          >
                            {item?.fullName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="fullWidth">
                  <label>Employer </label>
                  <div class="dropdown fullWidth">
                    <button
                      class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-sliders mr-2" aria-hidden="true"></i>
                      {filters.employerId == ""
                        ? "Select Employer"
                        : EmployerList.find(
                          (item) => item._id == filters.employerId
                        )?.fullName}
                    </button>
                    <ul class="dropdown-menu">
                      {EmployerList.map((item) => (
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={(e) => {
                              setfilters((prev) => ({
                                ...filters,
                                employerId: item?._id,
                              }));
                              GetReports({ employerId: item?._id });
                            }}
                          >
                            {item?.fullName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className=" fullWidth d-flex gap-2">
                  <div className="fullWidth">
                    {" "}
                    <label>Recruiter </label>
                    <div class="dropdown fullWidth">
                      <button
                        class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-sliders mr-2" aria-hidden="true"></i>
                        {filters.recruiterId == ""
                          ? "Select Recruiter"
                          : RecruiterList.find(
                            (item) => item._id == filters.recruiterId
                          ).fullName}
                      </button>
                      <ul class="dropdown-menu">
                        {RecruiterList.map((item) => (
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={(e) => {
                                setfilters((prev) => ({
                                  ...filters,
                                  recruiterId: item?._id,
                                }));
                                GetReports({ recruiterId: item?._id });
                              }}
                            >
                              {item?.fullName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {filters.employerId != "" ||
                    filters.virtualId != "" ||
                    filters.recruiterId != "" ||
                    filters.start_date ||
                    filters.end_date ? (
                    <div className=" ">
                      <label className="opacityView d-block">clear</label>

                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setfilters({
                            employerId: "",
                            end_date: "",
                            start_date: "",
                            contractId: "",
                            name: "",
                            virtualId: "",
                            recruiterId: "",
                          });
                          setSelectedDateRange({
                            startDate: '',
                            endDate: '',
                            type: 'now',
                            key: 'selection',
                          });
                          GetReports({
                            employerId: "",
                            end_date: "",
                            start_date: "",
                            contractId: "",
                            name: "",
                            virtualId: "",
                            recruiterId: "",
                          });
                        }}
                      >
                        Clear
                      </button>

                    </div>
                  ) : null}
                </div>
              </div>


            </div>

            <div className="border_top mt-4">
              <div className="table-responsive btable">
                <table className="table  table-bordered m-0">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="">
                        Contract Name
                      </th>
                      <th className="">VA Recruiter Name</th>
                      <th scope="col" className="">
                        EMP Recruiter Name
                      </th>
                      <th scope="col" className="">
                        Employer Name
                      </th>
                      <th scope="col" className="">
                        Employer Amount
                      </th>
                      <th
                        scope="col"
                        className="cursor-pointer"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => Sort("start_date")}
                      >
                        Start Date
                      </th>
                      <th className="">Recruiter EMP Commission</th>
                      <th scope="col" className="">
                        VA Name
                      </th>
                      <th scope="col" className="">
                        VA Amount
                      </th>
                      <th className="">Recruiter VA Commission</th>
                      <th className="">VA Commission</th>
                      <th className="">Total Commission</th>
                      <th>Total Profit</th>
                      <th>Net Profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((item, index) => (
                      <tr className="data_row">
                        <td className="text-capitalize pb-0">
                          {" "}
                          <div
                            className={`pms  p-2 ${index % 2 == 0 ? "backit" : "backitagin"
                              } `}
                            style={{ cursor: "pointer" }}
                          >
                            {item?.contractDetail?.createdBy === "admin"
                              ? item?.projectsDetail?.name ||
                              item?.contractDetail?.contractName ||
                              item?.contractDetail?.title
                              : item?.contractDetail?.contractName || "---"}
                          </div>{" "}
                          {/* <div className={`pms  p-2 ${index % 2 == 0 ? "backit" : "backitagin"
                          } `}>  {item?.projectsDetail?.title}</div> */}
                        </td>
                        <td className="text-capitalize">
                          {item?.virtualRecruiterDetail?.[0]?.fullName || "--"}
                        </td>
                        <td className="text-capitalize">
                          {item?.employerRecruiterDetail?.[0]?.fullName || "--"}
                        </td>
                        <td className="text-capitalize">
                          {item?.employerDetail?.[0]?.fullName || "--"}
                        </td>
                        <td>
                          {parseFloat(item?.total_emp_amount).toFixed(2)} USD
                        </td>
                        <td>
                          {methodModel.convertDate(
                            item?.contractDetail?.startDate
                          )}
                        </td>
                        <td className="">
                          {parseFloat(item?.employerReferalCommission).toFixed(
                            2
                          )}{" "}
                          USD
                        </td>
                        <td className="text-capitalize">
                          {item?.virtualDetail?.[0]?.fullName || "--"}
                        </td>
                        <td>
                          {parseFloat(item?.total_VA_amount).toFixed(2)} USD
                        </td>
                        <td className="">
                          {parseFloat(item?.virtualReferalCommission).toFixed(
                            2
                          )}{" "}
                          USD
                        </td>
                        <td className="">
                          {parseFloat(item?.VAReferedCommission).toFixed(2)}{" "}
                          USD
                        </td>
                        <td>
                          {parseFloat(item?.totalCommission).toFixed(2)} USD
                        </td>
                        <td>{parseFloat(item?.grossProfit).toFixed(2)} USD</td>
                        <td>{parseFloat(item?.netprofit).toFixed(2)} USD</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {loading ? (
                  <h3 className="text-center mt-2">Loading...</h3>
                ) : Data.length == 0 ? (
                  <div className="text-danger text-center">No Reports here</div>
                ) : null}
              </div>




            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
