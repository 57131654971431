import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import canvasModel from "../../models/canvas.model";
import { useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
var CanvasJSChart = canvasModel.CanvasJSChart;
import ReactECharts from 'echarts-for-react';
import moment from "moment";


const Dashboard = () => {
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user.loggedIn) {
    }
  });
  const [filters, setFilter] = useState({
    type: "day",
    healthClinic: "",
    startDate: "",
    endDate: "",
    country: "",
  });

  const xAxis = {
    labelFontSize: 12,
    titleFontSize: 16,
    interval: 1,
    titleFontWeight: "bold",
    labelWrap: true,
  };

  const typFormate = (p = filters.type) => {
    let value = "DD MMM YY";
    if (p == "day") value = "DD MMM YY";
    if (p == "week") value = "";
    if (p == "month") value = "MMM YY";
    if (p == "year") value = "YYYY";

    return value;
  };
  const [DashboardData, setDashboardData] = useState({});
  //  THIS IS THE MAIN PROFILE DATA
  const GetDashboardData = () => {
      loader(true);
      ApiClient.get("homepage").then((res) => {
        if (res.success) {
          setDashboardData(res.data);
        }
        loader(false);
      });
  };
  useEffect(() => {
    GetDashboardData();
  }, []);
  const [userChartOptions, setuserChartOptions] = useState({ XData: [], YData: [] });
  const UserChartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: userChartOptions.XData,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Total Users',
        type: 'bar',
        barWidth: '60%',
        data: userChartOptions.YData
      }
    ]
  }
  const GetUsersCount = (p = {}) => {
    let payload = { type: "yearly", ...p }
    loader(true);
    ApiClient.get(`get_totalUsersCounts`, payload).then(res => {
      if (res.success) {
        const data = res.totalUsersCount.response;
        const XData = [];
        const YData = [];
        data.map((item) => {
          let yeardata = ""
          let counts = 0;
          if (payload.type == 'weekly') {
            counts = item.totalUsersCount
            yeardata = moment(`${item.day}/${item.month}/${item.year}`).format('MMM,DD YYYY')
          } else if (payload.type == 'monthly') {
            counts = item.totalUsersCounts
            yeardata = moment(`${item.month}/01/${item.year}`).format('MMM,YYYY')
          }
          else if (payload.type == 'daily') {
            counts = item?.totalUsersCount
            yeardata = moment(`${item.month}/${item.day}/${item.year}`).format('MMM,DD YYYY')
          }
          else {
            counts = item.totalUsersCounts
            yeardata = item.year
          }
          XData.push(yeardata);
          YData.push(counts)
        })
        setuserChartOptions({ XData, YData })
      }
      loader(false)
    })
  }

  const [ContractChartData, setContractChartData] = useState({ Legend: [], XData: [], YData: [] });
  const ContractLineoptions = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ContractChartData.Legend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: { 
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ContractChartData.XData
    },
    yAxis: {
      type: 'value'
    },
    series: ContractChartData.YData
  };


  function extractUniqueYears(arrays, type) {
    // Create an empty array to store unique years
    let uniqueYears = [];
    // Iterate through each array
    arrays?.map((array) => {
      // Iterate through each object in the array
      array?.map((obj) => {
        // Extract the 'year' key from the object
        const year = obj.year;
        const month = obj.month;
        const date = obj.day
        let includoData = type == 'monthly' ? moment(month + "/01/" + year).format('MMM,YYYY') : type == 'weekly' || type == 'daily' ? moment(month + "/" + date + "/" + year).format('DD MMM,YYYY') : year;
        // Check if the year is not already in the uniqueYears array
        if (!uniqueYears.includes(includoData)) {
          // Push the unique year into the array
          switch (type) {
            case "yearly":
              uniqueYears.push(year);
              break;
            case "monthly":
              uniqueYears.push(moment(month + "/01/" + year).format('MMM,YYYY'));
              break;
            case "weekly":
              uniqueYears.push(moment(month + "/" + date + "/" + year).format('DD MMM,YYYY'));
              break;
            case "daily":
              uniqueYears.push(moment(month + "/" + date + "/" + year).format('DD MMM,YYYY'));
              break;
            default:
              uniqueYears.push(year);
              break;
          }
        }
      });
    });

    return uniqueYears;
  }

  const GetContractsData = (p = {}) => {
    const filters = { type: "yearly", ...p }
    loader(true);
    ApiClient.get(`get_totalContractsCount`, filters).then(res => {
      if (res.success) {
        const data = res;
        const AcceptContractsData = res?.totalAcceptedContractCount.response;
        const CompletedContractsData = res?.totalCompletedContractCount?.responses;
        const TotalContractCount = res?.totalContractCount?.response;
        const TotalEndContract = res?.totalEndContractCount?.response
        const TotalPendingContract = res?.totalPendingContractCount?.response;
        const TotalRejectedContract = res?.totalTerminatedContractCount?.response;
        const Legends = ['Total Contracts', 'Pending', 'Completed', 'Accepted', 'Ended', 'Terminated'];
        const YData = [{
          name: 'Total Contracts',
          type: 'line',
          stack: 'Total1',
          data: TotalContractCount?.map((item) => item?.totalUsersCounts || item?.totalUsersCount||0)
        },
        {
          name: 'Pending',
          type: 'line',
          stack: 'Total2',
          data: TotalPendingContract?.map((item) => item?.totalUsersCounts || item?.totalUsersCount||0)
        },
        {
          name: 'Accepted',
          type: 'line',
          stack: 'Total3',
          data: AcceptContractsData?.map((item) => item?.totalUsersCounts || item?.totalUsersCount||0)
        },
        {
          name: 'Completed',
          type: 'line',
          stack: 'Total4',
          data: CompletedContractsData?.map((item) => item?.totalUsersCounts || item?.totalUsersCount||0)
        },
        {
          name: 'Ended',
          type: 'line',
          stack: 'Total5',
          data: TotalEndContract?.map((item) => item?.totalUsersCounts || item?.totalUsersCount)
        },
        {
          name: 'Terminated',
          type: 'line',
          stack: 'Total6',
          data: TotalRejectedContract?.map((item) => item?.totalUsersCount || item?.totalUsersCounts)
        },

        ]
        const XDATA = extractUniqueYears([AcceptContractsData, CompletedContractsData, TotalContractCount, TotalEndContract, TotalPendingContract, TotalRejectedContract], filters.type);
        console.error({ YData: YData, Legend: Legends, XData: XDATA },"THis is the Early COntract Data ")
        setContractChartData({ YData: YData, Legend: Legends, XData: XDATA })
      }
      loader(false)
    })
  }

  const [NetProfitChartData, setNetProfitChartData] = useState({ XDATA: [], YDATA: [] });
  const NetProfitOptions = {
    xAxis: {
      type: 'category',
      data: NetProfitChartData.XDATA
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Net Profit']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: NetProfitChartData.YDATA,
        type: 'line',
        smooth: true
      }
    ]
  };
  const GetNetProfitData = (p = {}) => {
    const filters = { type: "yearly", ...p };
    loader(true);
    ApiClient.get(`get_totalNetProfitCounts`, filters).then(res => {
      if (res.success) {
        const data = res?.totalNetProfit?.response
        const XDATA = data.map((item) => {
          let data = ""
          switch (filters.type) {
            case "yearly":
              data = item.year
              break;
            case "monthly":
              data = moment(item.month + "/01/" + item.year).format("MMM,YYYY")
              break;
            case "daily":
              data = moment(item.month + "/" + item.day + "/" + item.year).format("DD MMM,YYYY")
              break;
            default:
              data = moment(item.month + "/" + item.day + "/" + item.year).format("DD MMM,YYYY")
              break;
          }
          return data
        });
        const YDATA = data.map((item) => filters.type == 'daily' || filters.type == 'weekly' ? item?.totalUsersCount : item?.totalNetProfit);
        setNetProfitChartData({ XDATA: XDATA, YDATA: YDATA });
      }
      loader(false)
    })
  }
  useEffect(() => {
    GetUsersCount();
    GetContractsData();
    GetNetProfitData()
  }, []);
  return (
    <Layout>
      <div className="row d-flex align-items-center">
        {/* <div className="col-md-9 head_cls">
          <h2 className="mb-1 mainHeading_cls">
            {" "}
            <span className="dsh_hedding">Hi</span>
            {user && user.fullName}
          </h2>
          <p className="paraCls">
            Here’s what’s going on with your team Designspace
          </p>
        </div> */}

        <div className="cards_sales graph_section">
          <div className="row">
          <div className="col-md-10 head_cls">
          <h2 className="mb-1 mainHeading_cls">
            {" "}
            <span className="dsh_hedding">Hi</span>
            {user && user.fullName}
          </h2>
          <p className="paraCls pb-3">
            Here’s what’s going on with your team Designspace
          </p>
        </div> 
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Payments</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/totalp.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {parseFloat(DashboardData.totalPayment||0).toFixed(2)} USD
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Contracts</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/contracts.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {DashboardData?.totalContract||0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">No. of VA</p>
                    </div>
                    <div>
                      <img src="/assets/img/send.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {DashboardData?.totalVirtual||0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">No. of Employer </p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/employer.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{DashboardData?.totalEmployer||0}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">No. of Recruiter </p>
                    </div>
                    <div>
                      <img src="/assets/img/recq.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {DashboardData?.totalRecruiter||0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total payment done</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/payments.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{parseFloat(DashboardData?.totalAdminPayment||0).toFixed(2)} USD</h3>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Net profit</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/profits.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{parseFloat(DashboardData?.net_profit||0).toFixed(2)} USD</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* graph Design */}

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 ">
              <div className="mt-5">
              <div className="contractsFlex">
              <div >
                    <h3 className="Amount_sales">Total Users</h3>
                  </div>

                  <div
                    >
                    <select className="form-control text-center" onChange={e => GetUsersCount({ type: e.target.value })}>
                      <option value="yearly">Yearly</option>
                      <option value="monthly">Monthly</option>
                      <option value="weekly">Weekly</option>
                      <option value="daily">Daily</option>
                    </select>
                  </div>
              </div>
             
                <div className="graph_range">
                  <div className="col-12">
                    <ReactECharts option={UserChartOptions} style={{ width: "100%", height: "500px" }} />
                  </div>
                </div>
              <div className="contractsFlex mb-4">
              <h3 className="Amount_sales">Contracts</h3>
              <div
                 > 
                  <select className="form-control text-center" onChange={e => GetContractsData({ type: e.target.value })}>
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                    <option value="daily">Daily</option>
                  </select>
                </div>
              </div>
                {/* <div
                  className="col-md-3"
                  style={{ float: "right" }}>
                  <select className="form-control" onChange={e => GetContractsData({ type: e.target.value })}>
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                    <option value="daily">Daily</option>
                  </select>
                </div> */}
                <div className="revenue_graph col-md-12">
                  <ReactECharts option={ContractLineoptions} style={{ width: "100%", height: "500px" }} />
                </div>


              </div>
            </div>

            {/* 2nd cards */}
            <div className="col-12 col-sm-12 col-md-12  cls">
              <div className="graph_section graph_sectionProfit">
              <div className="contractsFlex mb-4">
              <div>
                  <h3 className="Amount_sales">Net Profit</h3>
                </div>
                <div
                  >
                  <select className="form-control text-center" onChange={e => GetNetProfitData({ type: e.target.value })}>
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                    <option value="daily">Daily</option>
                  </select>
                </div>
                </div>
               
            
                <div className="graph_range w-100">
                  <div className=" w-100">
                    <ReactECharts option={NetProfitOptions} style={{ width: "100%", height: "500px" }} />
                  </div>

                </div>


              </div>
            </div>
            {/* 3rd card */}

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
