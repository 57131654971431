import React, { useEffect, useState } from "react";
import Html from "./html";
import { useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";



const Testonimal = () => {
 
    const history = useHistory()
    const [data, setData] = useState([])
    const searchState = useSelector((state) => state.search);
    const [filters, setFilters] = useState({ search: "" })
    const [loading, setloading] = useState(true)


    const ListingData = (p = {}) => {
        let filter = { ...filters, ...p }
        loader(true)
        ApiClient.get("testimonials", filter).then((res) => {
            setData(res.data)
            setFilters({ ...filters, search: searchState.data })
            loader(false)
            setloading(false)
        })
    }


    useEffect(() => {
        ListingData()
    }, [])


    const Deletedata = (e, id) => {
        e.preventDefault()
        loader(true)
        ApiClient.delete(`testimonial?id=${id}`).then((res) => {
            if (res.success) {
                ListingData()
                toast.success(res.message)
            }
            loader(false)
        })
    }

    const view = (id) => {
        history.push("/testonimal/detail/" + id)
    }

    return <>
        <Html
            history={history}
            ListingData={ListingData}
            data={data}
            Deletedata={Deletedata}
            view={view}
            loading={loading}
        />
    </>
}
export default Testonimal;