import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../methods/api/apiClient';
import { ToastsStore } from 'react-toasts';
import Layout from '../../components/global/layout';
import loader from '../../methods/loader';
import userTableModel from '../../models/table.model';
import { userType } from '../../models/type.model';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import environment from '../../environment';
import { toast } from 'react-toastify';
import Pagination from "react-pagination-js";
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import Item from 'antd/es/list/Item';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import moment from 'moment';

const RecruiterListing = (p) => {
    const user = useSelector(state => state.user)
    // const {role} =useParams()
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 5, search: '', })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const [tab, setTab] = useState(methodModel.getPrams('role') || "recruiter")
    const history = useHistory()
    const Navigate = useHistory()
    const roleIDS = methodModel.getPrams('id')
    const [loading, setloading] = useState(true)



    useEffect(() => {
        // Receive the role from the params if come and open the tab according to the role.After tab open the role remove from the parms
        const role = methodModel.getPrams('role')
        if (role) {
            setTimeout(() => {
                Navigate.push('/refferalls')

            }, 200);
        }
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            GetAllData({ search: searchState.data }, null)
        }
    }, [searchState])

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        let url = 'recruiter/users'
        ApiClient.get(url, filter).then(res => {
            if (res.success) {
                const data = res.data;
                setData(data)
                setTotal(res.total)
            }
            setLoader(false)
        })
    }

    const getDataEmployerData = (p = {}) => {
        // setLoader(true)
        let filter = { ...filters, ...p }
        let url = 'referal-list/admin'
        ApiClient.get(url).then(res => {
            if (res.success) {
                const data = res.data;
                setData(data)
                setTotal(res.total)
            }
            // setLoader(false)
        })
    }

    const getDataVirtualData = (p = {}) => {
        // setLoader(true)
        // let url = 'referal-list/admin'
        ApiClient.get(url).then(res => {
            if (res.success) {
                const data = res.data;
                setData(data)
                setTotal(res.total)
            }
            // setLoader(false)
        })
    }
    const GetAllData = (p = {}, typo, id,) => {
        let type = typo || tab
        setLoader(true)
        let DataId = '';
        let url = "recruiter/users"
        if (type == "recruiter") {
            DataId = environment.RecruiterId
            setTab("recruiter")
            url = "recruiter/users"
        } else if (type == "employer") {
            setTab("employer")
            DataId = environment.EmployerId
            url = "referal-list/admin"
        } else if (type == "virtual") {
            setTab("virtual")
            DataId = environment.VirtualId

            url = "virtualCommissionReport"
        }
        let filter = { ...filters, ...p, addedByRole: id || DataId }
        ApiClient.get(url, filter).then(res => {
            if (res.success) {
                const data = res.data;
                // if(type == 'recruiter'){
                // }
                setData(data)
                setTotal(res.total)
            }
            setLoader(false)
            setloading(false)

        })
    }


    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        GetAllData({ page: e })
    }


    const view = (id) => {
        history.push("/Reqrooter/refferalls/" + id)
    }

    const [ShowDeleteModal, setShowDeleteModal] = useState("none")
    const [DeleteId, setDeleteId] = useState("");
    const HandleDeleteFunction = () => {
        loader(true)
        ApiClient.delete(`delete?model=users&id=${DeleteId}`).then(res => {
            if (res.success) {
                toast.success("Recruiter deleted successfully")
                setShowDeleteModal("none")
                GetAllData({}, "recruiter");
            }
            loader(false);
        })
    }

    const Apporverefer = (email, data = {}) => {
        if (data?.referredUser?.isVerifiedDocument != 'accepted') {
            toast.error('User Profile is not verified yet')
            return
        }
        ApiClient.put("referal-credit", { email: email }).then((res) => {
            if (res?.success) {
                toast.success(res?.message)
                GetAllData({}, "employer")
            }
        })
    }

    return <>
        <Layout>
            <div className='heightVH'>            <CommonDeleteModal confirm={HandleDeleteFunction} setShow={setShowDeleteModal} show={ShowDeleteModal} />
                <div className='normalFlex mb-3 align-items-center'>
                    <h3 className='hedding'>Referrals</h3>
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs border userstabs">
                            <li className="nav-item d-flex align-items-center">
                                <a className={`nav-link ${tab == 'recruiter' ? "usertabcolor" : ""}  `} aria-current="page" onClick={e => {
                                    GetAllData({}, "recruiter", environment.RecruiterId); setTab('recruiter')
                                }} >Recruiters </a>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <a className={`nav-link ${tab == 'employer' ? "usertabcolor" : ""}`} aria-current="page" onClick={e => {
                                    setTab('employer')
                                    GetAllData({}, "employer", environment?.EmployerId);
                                }} >Employers </a>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <a className={`nav-link ${tab == 'virtual' ? "usertabcolor" : ""}  `} aria-current="page" onClick={e => {
                                    setTab('virtual')
                                    GetAllData({}, "virtual", environment?.VirtualId);
                                }} >Virtual Assistant</a>
                            </li>


                        </ul>
                    </div>
                </div>

                <div className="table-responsive table_section">
                    <table class="table">
                        <thead className='table_head'>
                            <tr className='heading_row'>
                                <th scope="col" className='table_data'>Name</th>
                                <th scope="col" className='table_data'>Email</th>
                                {tab === "recruiter" ? null :
                                    <>
                                        {tab === "virtual" ? <><th scope="col" className='table_data'>Referred VA</th>
                                            <th scope="col" className='table_data'>Email</th></> : <><th scope="col" className='table_data'>Referred Employer</th>
                                            <th scope="col" className='table_data'>Email</th></>}
                                    </>}
                                {tab == "recruiter" ? <><th scope="col" className='table_data'>Invited</th>
                                    <th scope='col' className='table_data'>Accepted</th></> : null}
                                {tab === "employer" ? <th scope="col" className='table_data'>Approved Date</th> : null}
                                <th scope="col" className='table_data'>Referred  Date</th>
                                {tab === "virtual" ? <th scope="col" className='table_data'>Total Commission</th> : null}
                                <th scope="col" className='table_data'>Actions</th>

                            </tr>
                        </thead>
                        <tbody>
                            {!loaging && data && data.map((itm, i) => {
                                return <tr className='data_row'>
                                    {tab === "virtual" ? <>
                                        <td className='table_dats' >

                                            <div className='user_detail'>
                                                <img src={methodModel.userImg(itm?.referalVirtualDetails?.image)} className="user_imgs" />
                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm?.referalVirtualDetails?.fullName}
                                                    </h4>

                                                </div>
                                            </div></td>
                                        <td className='table_dats' style={{ textTransform: "lowercase" }}> <span className='adjust'>{itm?.referalVirtualDetails?.email}</span> </td>
                                    </> :
                                        <> <td className='table_dats' >

                                            <div className='user_detail'>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {tab === "recruiter" ? <>{itm?.fullName}</> : <>{itm.addedByDetail?.fullName}</>}
                                                    </h4>

                                                </div>
                                            </div></td>
                                            <td className='table_dats' style={{ textTransform: "lowercase" }}>{tab == "recruiter" ? <> <span className='adjust'>{itm?.email}</span> </> : <> <span className='adjust'>{itm.addedByDetail?.email}</span></>}</td></>}
                                    {tab === "recruiter" ? null :
                                        <>
                                            {tab === "virtual" ?

                                                <><td className='table_dats user' >  <span className='adjust'>{itm?.virtualDetail?.fullName}</span></td>
                                                    <td className='table_dats' style={{ textTransform: "lowercase" }}>  <span className='adjust'>{itm?.virtualDetail?.email}</span></td></>

                                                : <>   <td className='table_dats user' ><span className='adjust'>{itm.fullName}</span></td>
                                                    <td className='table_dats' style={{ textTransform: "lowercase" }}><span className='adjust'>{itm?.email}</span></td></>}
                                        </>}

                                    {tab == "recruiter" ? <><td className='table_dats'><span className='adjust'>{itm.referalCount ? itm.referalCount : 0} </span></td>
                                        <td className='table_dats'><span className='adjust'>{itm.profileCreated ? itm.profileCreated : 0}</span></td></> : null}
                                    {tab === "employer" ? <td><span className='adjust'>{datepipeModel.date(itm?.approvalDate) || "--"}</span></td> : null}

                                    <td className='table_dats'> <span className='adjust'>{datepipeModel.date(itm?.createdAt)}</span></td>
                                    {tab === "virtual" ? <td scope="col" className='table_data'> <span className='adjust'>{itm?.totalCommission}{" "}$</span></td> : null}
                                    {tab === "virtual" ?
                                        <td>
                                            <span className='edit_icon pt-2' title='View' onClick={() => Navigate.push(`/Reqrooter/refferalls/${itm?.referalVirtualDetails?._id || itm?.referalVirtualDetails?.id}/virtual-assistant`)}>
                                                <i className='fa fa-eye'></i>
                                            </span>
                                        </td>
                                        : <td className='table_dats'>
                                            {tab == "employer" ?
                                                <div className="action_icons table_cons mt-1 d-flex align-items-center">
                                                    <input type="checkbox" className="mx-2 biggercheck" checked={itm?.approvedReferalCredit} title={itm?.approvedReferalCredit ? 'Approved' : 'Approve'} onClick={e => Apporverefer(itm?.email, itm)} />
                                                    <span className='edit_icon' title='View' onClick={() => Navigate.push(`/Reqrooter/refferalls/${itm.addedBy}/emp`)}>
                                                        <i className='fa fa-eye'></i>
                                                    </span>
                                                </div>
                                                :
                                                <div className="action_icons table_cons mt-1">
                                                    <span className='edit_icon' title='View' onClick={() => Navigate.push(`/Reqrooter/refferalls/${itm.id || itm?._id}/rc`)}>
                                                        <i className='fa fa-eye'></i>
                                                    </span>
                                                    <span className='edit_icon' title='Delete' onClick={(e) => { setDeleteId(itm._id); setShowDeleteModal("block") }}>
                                                        <i className='fa fa-trash text-danger'></i>
                                                    </span>
                                                </div>}
                                        </td>}

                                </tr>

                            })
                            }
                        </tbody>
                    </table>


                </div>






                {/* {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>} */}
                {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
                    <div className="text-danger text-center">No Data</div>
                ) : null}

                {
                    !loaging && total > filters.count ? <div className='paginationWrapper'>
                        <span>Show {filters.count} from {total}  Users</span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }

                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}</div>
        </Layout >
    </>;
};

export default RecruiterListing;
