import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import environment from '../../../environment';

const BlogDetail = (p) => {
    const history = useHistory()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`blog`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    };

    const back = () => {
        history.goBack()
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
    }, [id, userId])

    return (
        <Layout>
       <div className='heightVH'>
        

<div className='normalFlex  mb-3 align-items-center'>
<h3 className="hedding d-flex gap-1 align-items-center" >
            <a   to="/blog" onClick={back}>
              <i className="fa fa-angle-double-left fontBack"  title='Back' aria-hidden="true"></i>
            </a>
            Blog Detail
          </h3>
       
                <div>
                    <button onClick={e=>history.push(`/AddEditBlogs/${id}`)} className='btn btn-primary mb-0 d-flex align-items-center gap-1'><i  class="material-icons edit  text-white" title="Edit">edit</i>Edit</button>
                
                    {/* <a to="/blog" onClick={back}>  <i className="fa fa-angle-double-left mb-3 backheadarrow " title='Back' aria-hidden="true"></i></a> */}
                </div>
           
</div>

         
            <div className="row">
                <div className="sideclass col-md-12 pprofile1">
             

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Title</label>
                            <div className='profiledetailscls'>{data && data.title}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Category</label>
                            <div className='profiledetailscls'> {data && data.category.name}</div>
                      
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Description</label>
                            {/* <div className='profiledetailscls'>{data && data.description}</div> */}
                            <div className='profiledetailscls' dangerouslySetInnerHTML={{__html: data && data.description}}></div>  
                        </div> 

                                             
                                                             
                        <div className="col-md-12 mb-3">
                <label className="profileheddingcls">Image</label>
                <div>
                  <div className="imagethumbWrapper">
                    <img
                      src={methodModel.userImg(data && data?.image)}
                      className="uploadimage"
                    />
                  </div>

            
                </div>
              </div>
           {data&&data.video&&data?.video!=""?
           <div className="col-md-12 mb-3">
                <label className="profileheddingcls">Video</label>
                <div>
                  <div className="imagethumbWrapper">
                  <video width={300} height={150} src={`${environment.api+"images/videos/"+data?.video}`} controls/>
                  </div>

            
                </div>
              </div>:null}
                    </div>
                </div>
            </div></div>
        </Layout>

    );
};

export default BlogDetail;
