import React, { useState } from "react";
import Layout from "../../../components/global/layout";
import ImageUpload from "../../../components/common/ImageUpload";
import { Editor } from "@tinymce/tinymce-react";
import environment from "../../../environment";

const Html = ({ history, AddEditdata, Handleaddchange, Handleimage, form, images, id, setForm }) => {

    return <>

        <Layout>

          <div className="heightVH">
          <div className="d-flex gap-1 align-items-center mb-3"> 
        <i onClick={() => history.push("/testonimal")} className="fa fa-angle-double-left fontBack " title='Back' aria-hidden="true"></i>

                   <h3 className="hedding">
                   {id ? "Edit" : "Add"} Testimonial
                    </h3>
                      </div>
              <form onSubmit={e => AddEditdata(e)}>
                <div className="pprofile1">
             
                    <div className="form-row">
                      
                        <div className="col-md-6 mb-3">
                            <label>
                                Title
                                {/* <span className="star">*</span> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={form.title}
                                onChange={e => Handleaddchange(e)}
                                // required
                            />
                        </div>
                        
                        <div className="col-md-6 mb-3">
                            <label>
                                Designation
                                {/* <span className="star">*</span> */}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="designation"
                                value={form.designation}
                                onChange={e => Handleaddchange(e)}
                                // required
                            />
                        </div>
                        
                        <div className="col-md-12 mb-3">
                            <label>
                                Description<span className="star">*</span>
                            </label>
                            <Editor
                                type="text"
                                apiKey={environment.tinyKey}
                                className="form-control"
                                name="description"
                                value={form.description}
                                onEditorChange={(newValue) => { setForm({ ...form, description: newValue, }); }}
                            />
                        </div>
                       
                        <div className="col-md-12  mb-3">
                            <label className='lablefontcls'>Image</label>
                        </div>
                        <ImageUpload model="users" className="img_upload" result={e => Handleimage(e, 'image')} value={images.image || form.image} multiple={false} />
                    </div>
                  
                    <div className="text-right">
                        {/* <button type="button" onClick={() => history.push("/testonimal")} className="btn btn-secondary mr-2" >Back</button> */}
                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>
                    </div>
              
                </div>
            </form></div>
        </Layout>
    </>
}
export default Html;