import React, { useState, useMemo, useEffect } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import { useHistory } from 'react-router';

const Addbankdetail = () => {
    const history = useHistory()
    const [submitted, setsubmitted] = useState(false)
    let param = new URLSearchParams(window.location.search);
    const id = param.get("id");
    const token = param.get("token");
    const contract_Id = param.get("contractId")
    const start_Date = param.get("start_Date")
    const end_Date = param.get("end_Date")
    const totalDurationOfTime_hour1 = param.get("totalDurationOfTime_hour1")
    const totalDurationOfTime_min1 = param.get("totalDurationOfTime_min1")
    const totalBillingAmount = param.get("totalBillingAmount")
    const name = param.get("name")

    const [beneficiaryId, setbeneficiaryId] = useState("")
    const [data, setdata] = useState({})
    const [form, setform] = useState({
        accessToken: token, currency: data?.sellCurrency, name: name, classification: "Own Account", paymentReason: "",
        beneficiaryBankDetails: {
            bankName: " ", bankBranchName: "", branchCode: "", account: "", swift: "",
            bankAddress: { addressLine: "", citySuburb: "", stateProvince: "", postCode: "", country: "" }
        },
        beneficiaryAddress: { country: "", addressLine: "", citySuburb: "", stateProvince: "", postCode: "" }
    })
    const [modalform, setmodalform] = useState({
        quoteId: id, accessToken: token, fundingSource: "Own account", contractId: contract_Id, payment_startTime: start_Date, payment_endTime: end_Date, total_billingHours: totalDurationOfTime_hour1, total_billingMinutes: totalDurationOfTime_min1, total_billingAmount: totalBillingAmount,

        beneficiaries: [{ beneficiaryId: beneficiaryId, amount: data?.sellAmount, payReason: "", payReference: "" }]
    })
    const options = useMemo(() => countryList().getData(), [])

    const HandleSubmit = (e) => {
        e.preventDefault()
        setsubmitted(true)
        if (submitted && form?.beneficiaryBankDetails?.branchCode?.length < 6) {
            return
        }
        let payload = {
            ...form,
            currency: data?.sellCurrency
        }
        loader(true)
        ApiClient.post(`create-beneficiary`, payload).then((res) => {
            if (res.success) {
                loader(false)
                toast.success("Bank Details added successfully")
                setbeneficiaryId(res?.data?.beneficiaryId)
                let beneficiarys = [{
                    beneficiaryId: res?.data?.beneficiaryId,
                    amount: data?.sellAmount,
                    payReason: "",
                    payReference: ""
                }]
                setmodalform({ ...modalform, beneficiaries: beneficiarys })
                document.getElementById("createdeal").click()
            }
        })
    }

    const HandlaChangeBankDetail = (e) => {
        const value = e.target.value;
        const data = form;
        data["beneficiaryBankDetails"][e.target.name] = value;
        setform({ ...data });
    };

    const HandlaChangeBankaddress = (e) => {
        const value = e.target.value;
        const data = form;
        data["beneficiaryBankDetails"]["bankAddress"][e.target.name] = value;
        setform({ ...data });
    };

    const HandlaChangeBeneficiaryaddress = (e) => {
        const value = e.target.value;
        const data = form;
        data["beneficiaryAddress"][e.target.name] = value;
        setform({ ...data });
    };

    const HandleBankDetailSelect = (e) => {
        setform(prevForm => ({
            ...prevForm,
            beneficiaryBankDetails: {
                ...prevForm.beneficiaryBankDetails,
                bankAddress: {
                    ...prevForm.beneficiaryBankDetails.bankAddress,
                    country: e.value
                }
            }
        }));
    };

    const HandleAddressSelect = (e) => {
        setform(prevForm => ({
            ...prevForm,
            beneficiaryAddress: {
                ...prevForm.beneficiaryAddress,
                country: e.value

            }
        }));
    };

    const CreateDeal = (e) => {
        e.preventDefault()
        let payload = {
            ...modalform,
        }
        // return
        loader(true)
        ApiClient.post(`create-deal`, payload).then((res) => {
            if (res.success) {
                loader(false)
                toast.success(res.message)
                document.getElementById("closedeal").click()
                history.push("/payment/va")
            }
        })
    }

    const handleBeneficiaryChange = (index, e) => {
        const { name, value } = e.target;
        const newBeneficiaries = modalform.beneficiaries.map((beneficiary, i) => (
            i === index ? { ...beneficiary, [name]: value } : beneficiary
        ));
        setmodalform({
            ...modalform,
            beneficiaries: newBeneficiaries
        });
    };

    //     const handleBeneficiaryChange = (index, e) => {
    //         const { name, value } = e.target;
    //         const newBeneficiaries = modalform.beneficiaries.map((beneficiary, i) => (
    //             i === index ? { ...beneficiary, [name]: value } : beneficiary
    //         ));
    //         setmodalform({
    //             ...modalform,
    //             beneficiaries: newBeneficiaries
    //         });
    //     };

    //     const addBeneficiary = () => {
    //         setmodalform({
    //             ...modalform,
    //             beneficiaries: [
    //                 ...modalform.beneficiaries,
    //                 { beneficiaryId: '', amount: '', payReason: '', payReference: '' }
    //             ]
    //         });
    //     };

    //     const removeBeneficiary = (index) => {
    //         setmodalform({
    //             ...modalform,
    //             beneficiaries: modalform.beneficiaries.filter((_, i) => i !== index)
    //         });
    //     };




    const Getdata = () => {
        ApiClient.get(`quote-detail?id=${id}&accessToken=${token}`).then((res) => {
            if (res.success) {
                setdata(res.data)
            }
        })
    }


    useEffect(() => {
        Getdata()
    }, [])


    return (
        <>
            <Layout>
                <form
                    onSubmit={e => HandleSubmit(e)}
                >
                    <div className="pprofile1">
                        <h3 className="ViewUser">Add Bank Details</h3>
                        <div className="form-row">
                            <div className="col-md-12"><h5>Basic</h5></div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    Currency
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="currency"
                                    value={data?.sellCurrency || ""}
                                    // onChange={e => setform({ ...form, currency: e.target.value })}
                                    // required
                                    disabled
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    VA Name
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={name}
                                    // onChange={e => setform({ ...form, name: e.target.value })}
                                    disabled
                                />
                            </div>

                            <div className="col-md-6 mb-5">
                                <label>
                                    Payment Reason
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="paymentReason"
                                    value={form.paymentReason}
                                    onChange={e => setform({ ...form, paymentReason: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="col-md-12 mb-2"><h5>Bank Details</h5></div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    Bank Name
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bankName"
                                    value={form?.beneficiaryBankDetails?.bankName}
                                    onChange={e => HandlaChangeBankDetail(e)}
                                    required
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label>
                                    Bank Branch Name
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bankBranchName"
                                    value={form?.beneficiaryBankDetails?.bankBranchName}
                                    onChange={e => HandlaChangeBankDetail(e)}
                                    required
                                />

                            </div>

                            <div className="col-md-6 mb-3">
                                <label>
                                    Branch Code
                                    {/* <span className="star">*</span> */}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="branchCode"
                                    value={form?.beneficiaryBankDetails?.branchCode}
                                    onChange={e => HandlaChangeBankDetail(e)}
                                // required
                                />
                                <div className="text-danger">{submitted && form?.beneficiaryBankDetails?.branchCode?.length < 6 ? "Branch Code must be 6 characters in length" : ""}</div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    Account
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="account"
                                    value={form?.beneficiaryBankDetails?.account}
                                    onChange={e => HandlaChangeBankDetail(e)}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    Swift Code
                                    {/* <span className="star">*</span> */}
                                </label>
                                <input
                                    type="text"
                                    maxLength={12}
                                    className="form-control"
                                    name="swift"
                                    value={form?.beneficiaryBankDetails?.swift}
                                    onChange={e => HandlaChangeBankDetail(e)}

                                />
                            </div>

                            <div className="col-md-12 mt-4 mb-2"><h5>Bank Address</h5></div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    Address Line
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="addressLine"
                                    value={form?.beneficiaryBankDetails?.bankAddress?.addressLine}
                                    onChange={e => HandlaChangeBankaddress(e)}
                                    required
                                />
                            </div>  <div className="col-md-6 mb-3">
                                <label>
                                    City
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="citySuburb"
                                    value={form?.beneficiaryBankDetails?.bankAddress?.citySuburb}
                                    onChange={e => HandlaChangeBankaddress(e)}
                                    required
                                />
                            </div>  <div className="col-md-6 mb-3">
                                <label>
                                    State
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="stateProvince"
                                    value={form?.beneficiaryBankDetails?.bankAddress?.stateProvince}
                                    onChange={e => HandlaChangeBankaddress(e)}
                                    required
                                />
                            </div>  <div className="col-md-6 mb-3">
                                <label>
                                    Post Code
                                    {/* <span className="star">*</span> */}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="postCode"
                                    value={form?.beneficiaryBankDetails?.bankAddress?.postCode}
                                    onChange={e => HandlaChangeBankaddress(e)}
                                // required
                                />
                            </div>  <div className="col-md-6 mb-3 timeZone">
                                <label>
                                    Country
                                    <span className="star">*</span>
                                </label>
                                <Select
                                    required
                                    options={options}

                                    name="country"
                                    value={options.find(option => option.value === form?.beneficiaryBankDetails?.bankAddress?.country)}
                                    onChange={e => HandleBankDetailSelect(e)}
                                />

                            </div>
                            <div className="col-md-12 mt-4 mb-2"><h5>Beneficiary Address</h5></div>
                            <div className="col-md-6 mb-3">
                                <label>
                                    Address Line
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="addressLine"
                                    value={form?.beneficiaryAddress?.addressLine}
                                    onChange={e => HandlaChangeBeneficiaryaddress(e)}
                                    required
                                />
                            </div>  <div className="col-md-6 mb-3">
                                <label>
                                    City
                                    <span className="star">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="citySuburb"
                                    value={form?.beneficiaryAddress?.citySuburb}
                                    onChange={e => HandlaChangeBeneficiaryaddress(e)}
                                    required
                                />
                            </div>  <div className="col-md-6 mb-3">
                                <label>
                                    State
                                    {/* <span className="star">*</span> */}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="stateProvince"
                                    value={form?.beneficiaryAddress?.stateProvince}
                                    onChange={e => HandlaChangeBeneficiaryaddress(e)}
                                // required
                                />
                            </div>  <div className="col-md-6 mb-3">
                                <label>
                                    Post Code
                                    {/* <span className="star">*</span> */}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="postCode"
                                    value={form?.beneficiaryAddress?.postCode}
                                    onChange={e => HandlaChangeBeneficiaryaddress(e)}
                                // required
                                />
                            </div>  <div className="col-md-6 mb-3 timeZone">
                                <label>
                                    Country
                                    <span className="star">*</span>
                                </label>
                                <Select
                                    required
                                    options={options}
                                    name="country"
                                    value={options.find(option => option.value === form?.beneficiaryAddress?.country)}
                                    onChange={e => HandleAddressSelect(e)}
                                />
                            </div>

                        </div>


                        <div className="text-right">
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => history.goBack()} >Back</button>
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                        </div>

                    </div>
                </form>

                <button type="button" class="btn btn-primary d-none" id="createdeal" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>

                <div class="modal fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Create Deal</h1>
                                {/* <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <form onSubmit={e => CreateDeal(e)}>
                                    <div>
                                        {modalform.beneficiaries.map((beneficiary, index) => (
                                            <div key={index}>

                                                <div className="col-md-12 mb-3">
                                                    <label>
                                                        Amount
                                                        <span className="star">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="amount"
                                                        value={data.sellAmount}
                                                        // onChange={(e) => handleBeneficiaryChange(index, e)}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label>
                                                        Pay Reason
                                                        <span className="star">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="payReason"
                                                        value={beneficiary.payReason}
                                                        onChange={(e) => handleBeneficiaryChange(index, e)}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label>
                                                        Pay Reference
                                                        <span className="star">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="payReference"
                                                        value={beneficiary.payReference}
                                                        onChange={(e) => handleBeneficiaryChange(index, e)}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" id="closedeal" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Done</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

        </>
    );
};
export default Addbankdetail;


