import { useEffect, useState } from "react";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";
import { useSelector } from "react-redux";
import environment from "../../../environment";
// import methodModel from "../../methods/methods";
import methodModel from "../../../methods/methods";
import { Editor } from "@tinymce/tinymce-react";
import formModel from '../../../models/form.model';

export default function AddEditBlog() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [form, setform] = useState({
    id: "",
    title: "",
    description: "",
    category: "id",
    image: "",
    video: ""
  });
  const [submitted, setsubmitted] = useState(false);
  const [image, setImage] = useState({ image: "" });


  const handleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    setsubmitted(true);
    let method = "post";
    const payload = form;
    let url = "blog";
    if (id) {
      method = "put";
      payload.id = id;
    } else {
      delete payload.id;
    }
    ApiClient.allApi(url, payload, method).then((result) => {
      if (result.success) {
        toast.success(result.message);
        Navigate.push("/blogs");
      }
      loader(false);
    });
  };



  // 


  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value })
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
      if (res.data.fullpath) {
        let image = res.data.fullpath
        setform({ ...form, image: image, baseImg: '', })
      } else {
        setform({ ...form, baseImg: '', })
      }
      loader(false)
    })
  }

  // const imageResult = (e, key) => {
  //   image[key] = e.value;
  //   setImage(image);
  // };


  const [categoriesdata, setCategoriesdata] = useState([])
  const GetcategoriesTypes = () => {
    loader(true);
    ApiClient.get(`categories?type=Blog`).then(res => {
      if (res.success) {
        setCategoriesdata(res.data);
      }
      loader(false)
    })
  }

  useEffect(() => {
    GetcategoriesTypes()
    if (id) {
      ApiClient.get(`blog?id=${id}`).then((result) => {
        if (result.success) {
          const newdata = result.data;
          setImage(newdata.image ? newdata.image : "")
          setform({
            title: newdata.title,
            description: newdata.description,
            category: newdata.category.id,
            image: newdata.image != "" ? newdata.image : "",
            video: newdata.video ? newdata.video : ""
          });
        }
      });
    }
  }, []);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  const getError = (key) => {
    return formModel.getError('profileForm', key)
  }



  const VideoUpload = (e) => {
    let files = e.target.files
    let file = files.item(0)
    if (file) { loader(true) }
    ApiClient.postFormData('uploadvideos', { file: file }).then(res => {
      if (res.success) {
        let newimage = res.data
        const image = newimage?.imagePath[0]
        // alert(image)
        setform({ ...form, video: image })
      } else {
      }
      loader(false)
    })
  }
  return (
    <Layout>
      <div className="heightVH">

        <h3 className="hedding d-flex gap-1 align-items-center mb-3" >
          <a onClick={(e) => Navigate.goBack()}>
            <i className="fa fa-angle-double-left fontBack" title='Back' aria-hidden="true"></i>
          </a>
          {id ? "Edit" : "Add"} Blog
        </h3>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="pprofile1">

            <div className="form-row">
              <div className="col-md-12 text-right ">
                {/* <i class="fa fa-trash text-danger" aria-hidden="true"></i> */}
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  title<span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.title}
                  onChange={(e) => setform({ ...form, title: e.target.value })}
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <label>
                  category<span className="star">*</span>
                </label>
                <select className='form-control' required onChange={e => setform({ ...form, category: e.target.value })} value={form.category} >
                  <option value="">Select Master category</option>
                  {categoriesdata.map((item, index) => (
                    <option className='text-capitalize' value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>



              {/* edior */}
              <div className="col-md-12 mt-3">
                <div className="form-group">
                  <label for="exampleFormControlTextarea1">
                    Description
                  </label>
                  <Editor
                    apiKey={environment.tinyKey}
                    value={form.description}
                    onEditorChange={(newValue, editor) => {
                      setform({
                        ...form,
                        description: newValue,
                      });
                    }}
                    init={{
                      height: "200px",
                      selector: "textarea",
                      plugins: "table code colorpicker textcolor",
                      toolbar:
                        "table code bold italic alignleft aligncenter alignright alignjustify | outdent indent ",
                      toolbar_mode: "floating",
                    }}
                  />

                </div>
              </div>

              {/* end */}


              <div className="col-md-6  mb-3">
                <label className="lablefontcls">Image</label>
                <br></br>
                {/* <ImageUpload
                model="users"
                result={(e) => uploadImage(e, "image")}
                value={image ? form.image : ""}
                multiple={false}
              /> */}

                {/* <div className="col-md-6  mb-3">
                            <label className='lablefontcls'>Image</label><br></br>
                            <ImageUpload model="users" result={e => imageResult(e, 'image')} value={image.image || form.image} multiple={false} />
                        </div> */}


                <div>
                  <div className='maininutcls'>
                    <label className="profileImageLabel">
                      <img src={methodModel.userImg(form && form.image)} className="profileImage" />
                    </label>
                    <div className='profile_btn'>
                      <div>
                        <div>
                          {form.image ? <i class="fa fa-trash" aria-hidden="true" onClick={e => setform({ ...form, image: "" })}></i> : <></>}
                        </div>
                        <label className="btn btn-primary edit ml-3">
                          <input
                            id="bannerImage"
                            type="file"
                            className="d-none"
                            accept="image/*"
                            value={form.baseImg ? form.baseImg : ''}
                            onChange={(e) => { uploadImage(e); }}
                          />{form.image ? 'Change' : 'Upload'} Image</label>
                      </div>


                      {/* {submitted && getError('image')?.invalid ? <div className="invalid-feedback d-block">Image is required</div> : <></>} */}
                    </div>



                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="mb-2"> video</label>
                {form.video == "" ? <div className="profile_btn_portfolio d- ">
                  <label className={`add_portfolio edit ml-3`}>
                    <input id="bannerImage" accept='.mp4' type="file" onChange={e => VideoUpload(e)} className="d-none" />
                    <span className="add_portfolio">
                      <i className="material-icons add_port">add</i>
                    </span>
                  </label>
                </div> : <div><video width={300} height={150} src={`${environment.api + "images/videos/" + form.video}`} controls /><i className='fa fa-trash text-danger video_tarsh' style={{ marginTop: "-100px" }} onClick={e => { setform({ ...form, video: "" }) }}></i></div>}

              </div>

            </div>

            <div className="text-right">
              {/* <button
              type="button"
              className="btn btn-secondary discard mr-2"
              onClick={(e) => Navigate.goBack()}
            >
              Back
            </button> */}
              {!id || (id && isAllow("editSkillType")) ? (
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </form></div>
    </Layout>
  );
}
