import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useHistory, useParams } from "react-router-dom";
import environment from "../../../environment";
import { toast } from "react-toastify";

const AddEditUser = () => {
    const { role, id } = useParams()
    const [images, setImages] = useState({ image: '', logo: '' });
    const defaultvalue = userType
    const [form, setform] = useState({ role: "", status: "active", dialCode: "+1", mobileNo: "", hourlyRate: "",timeZone: "" })
    const [set, setState] = useState()
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
    const [submitted, setSubmitted] = useState(false)
    const history = useHistory()
    const [roles, setRoles] = useState([])
    const [emailLoader, setEmailLoader] = useState(false)
    const [emailErr, setEmailErr] = useState('')
    const [ChangeStatus, setChangeStatus] = useState('')
    const [detail, setDetail] = useState()
    const user = useSelector((state) => state.user);
    const formValidation = [

        { key: 'email', email: true },

    ]

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }
    const [roleid, setroleid] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form);
        if (!form?.email) {
            return;
        }
        if (invalid) return;
        if (form.mobileNo == "" || form?.mobileNo.length < 10) return false

        if (form.role == "") return false
        let method = 'post'
        let url = 'add/user'
        const Names = form?.fullName?.trim();
        const NamesList = Names.split(" ");
        const firstName = NamesList[0] || ''
        const NameLength = (NamesList?.length - 1) || 1
        const lastName = NamesList[NameLength] || ''
        let value = {
            ...form,
            firstName,
            lastName,
            ...images
        }
        const data = roles?.find((item) => item?._id == value.role);
        if (data?.name?.toLowerCase().trim() == 'admin') {
            value['isVerifiedDocument'] = 'accepted'
        }
        if (value.id) {
            method = 'put'
            url = 'edit/profile'
        } else {
            delete value.id
            delete value?.hourlyRate
        }
        if (roleid != environment?.VirtualId) {
            delete value?.hourlyRate
        }
        // if (form.dialCode != "") {
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                toast.success(res.message)
                let url = '/users'
                if (role) url = "/users/" + role
                history.push(url)
            }
            loader(false)
        })
        // }
    }

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }

    const addressResult = (e) => {
        setform({ ...form, address: e.value })
    }

    const back = () => {
        history.goBack()
    }


    const getRoles = () => {
        ApiClient.get('roles', { status: 'active' }).then(res => {
            if (res.success) {
                let newarray = []
                // res.data && res.data.map((item, index) => {
                //     if (item.id != environment.adminRoleId) {
                //         newarray.push(item)
                //     }
                // })
                let fltr = res.data?.filter((itm) => itm?.id != '65585b4ce6735f7cc5665523' && itm?.id != "65585b3ae6735f7cc5665522")
                setRoles(fltr)
            }
        })
    }


    const emailCheck = (email) => {
        let isValid = methodModel.emailvalidation(email)
        if (isValid) {
            setEmailLoader(true)
            ApiClient.get('check/Email', { email: email }).then(res => {
                if (!res.success) {
                    if (detail?.email != email) {
                        setEmailErr(res.error.message)
                    }
                } else {
                    setEmailErr('')
                }
                setEmailLoader(false)
            })
        }
    }

    useEffect(() => {
        setSubmitted(false)
        // setState()
        if (id) {
            ApiClient.get(`setting`).then(Response => {
                loader(true)
                ApiClient.get("user/details", { id }).then(res => {
                    if (res.success) {
                        let value = res.data
                        setDetail(value)
                        let payload = defaultvalue
                        let oarr = Object.keys(defaultvalue)
                        oarr.map(itm => {
                            payload[itm] = value[itm] || ''
                        })
                        payload.dialCode = value.dialCode
                        payload.role = value.role.id
                        payload.status = value.status
                        payload.timeZone = value.timeZone?.label ? value.timeZone : ""
                        if (res.data?.role?.id == environment.VirtualId) {
                            payload["commissionPercentage"] = value.commissionPercentage || Response?.data?.virtualCommission
                        } else if (res?.data?.role?.id == environment.EmployerId) {
                            payload["commissionPercentage"] = value.commissionPercentage || Response?.data?.employerCommission
                        } else {
                            payload["commissionPercentage"] = value.commissionPercentage || Response?.data?.recruiterCommission
                        }
                        setform({ ...payload, })
                        images.image = payload?.image
                        images.logo = payload?.logo
                        setImages(images)
                        setroleid(value.role.id)
                    }
                    loader(false)
                })
            })
        }
        getRoles()
    }, [id])

    return <>
        <Html
            form={form}
            detail={detail}
            emailCheck={emailCheck}
            emailLoader={emailLoader}
            emailErr={emailErr}
            ChangeStatu={ChangeStatus}
            back={back}
            setEyes={setEyes}
            eyes={eyes}
            role={role}
            setform={setform}
            roles={roles}
            submitted={submitted}
            images={images}
            addressResult={addressResult}
            handleSubmit={handleSubmit}
            imageResult={imageResult}
            getError={getError}
        />
    </>
}

export default AddEditUser