import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import Main from "./main";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./config";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import environment from "./environment";


export const history = createBrowserHistory();
const stripePromise = loadStripe(environment?.stripe_publish_key);
/************ store configration *********/
const { persistor, store } = configureStore(history);
ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <Main />
    </Elements>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
