import { useEffect, useState } from 'react';
import statusModel from '../../../models/status.model';
import loader from '../../../methods/loader';
import ApiClient from '../../../methods/api/apiClient';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../../../components/global/layout';
import SelectDropdown from '../../../components/common/SelectDropdown';
import { useSelector } from 'react-redux';
import environment from '../../../environment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function AddEditSubSkills() {
    const { id } = useParams();
    const user = useSelector(state => state.user);
    const navigate = useHistory(); // Use useNavigate instead of useHistory
    const [form, setForm] = useState({ id: "", name: "", status: "active" });
    const [submitted, setSubmitted] = useState(false);
    const [formValues, setFormValues] = useState([{ hardskill: "" }]);
    const [removeIds, setRemoveIds] = useState([]);
    console.log(removeIds, "remove")

    useEffect(() => {
        if (id) {
            loader(true);
            ApiClient.get(`skill/type?id=${id}`).then(result => {
                if (result.success) {
                    loader(false);
                    const newData = result.data;
                    setForm({
                        name: newData.name,
                        status: newData.status,
                        id: newData.id,
                    });
                    setFormValues(newData.hardSkillDetail.map(itm => ({ hardskill: itm.name, id: itm.id })));
                }
            });
        } else {
            setForm({ id: "", name: "", status: "active" });
            setFormValues([{ hardskill: "" }]);
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);

        let formattedHardSkills;

        if (id) {
            formattedHardSkills = formValues.map(value => ({
                id: value.id,
                name: value.hardskill
            }));
        } else {
            formattedHardSkills = formValues.map(value => value.hardskill);
        }
        const payload = {
            name: form.name,
            status: form.status,
            hardSkill: formattedHardSkills,
            removehardSkill: removeIds
        };
        let method = "post";
        let url = "skill/type";
        if (id) {
            method = "put";
            payload.id = id;
        }
        loader(true);
        ApiClient.allApi(url, payload, method)
            .then(result => {
                loader(false);
                if (result.success) {
                    toast.success(result.message);
                    navigate.goBack();
                }
            })
            .catch(error => {
                loader(false);
                toast.error("Something went wrong.");
            });
    };

    const handleChange = (i, e) => {
        const { name, value } = e.target;
        const newFormValues = [...formValues];
        newFormValues[i][name] = value;
        setFormValues(newFormValues);
    };

    const addFormFields = () => {
        setFormValues([...formValues, { hardskill: '' }]);
    };

    const removeFormFields = (i) => {
        const newFormValues = [...formValues];
        const removedId = newFormValues[i].id; // Store the ID of the removed hard skill
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);

        if (removedId) {
            setRemoveIds(prevRemoveIds => [...prevRemoveIds, removedId]);
        }
    };

    const isAllow = (key = '') => {
        const permissions = user.role?.permissions;
        let value = permissions?.[key];
        if (user?.role?.id === environment?.adminRoleId) value = true;
        return value;
    };

    return (
        <Layout>
            <div className='heightVH'>
                <form onSubmit={handleSubmit}>
                    <div className="d-flex gap-1 align-items-center mb-3">
                        <i onClick={() => navigate.goBack()} className="fa fa-angle-double-left fontBack text-dark" title="Back"></i>
                        <h3 className="hedding">{id ? 'Edit' : 'Add'} Professions</h3>
                    </div>
                    <div className="pprofile1">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Profession Name<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={form.name}
                                    onChange={e => setForm({ ...form, name: e.target.value })}
                                    required
                                />
                            </div>
                            <div className='d-flex flex-column w-100 px-3'>
                                <label>Hard Skill<span className="star">*</span></label>
                                <div className='row'>
                                    {formValues.map((element, index) => (
                                        <div className="col-md-3 mb-3" key={index}>
                                            <div className='d-flex harrd_skills'>
                                                <input
                                                    required
                                                    type="text"
                                                    name="hardskill"
                                                    className='form-control'
                                                    value={element.hardskill}
                                                    onChange={e => handleChange(index, e)}
                                                />
                                                {formValues.length === 1 ? null :
                                                    <span className="edit_icon" onClick={() => removeFormFields(index)}>
                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {id ? null : <button className="btn btn-primary" type="button" onClick={addFormFields}>Add Hard Skill</button>}
                        <div className="text-right">
                            {(!id || (id && isAllow('editSkillType'))) && <button type="submit" className="btn btn-primary">Save</button>}
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
}
