import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";

const Html = ({
  tab,
  edit,
  ShowDeleteModal,
  ShowActiveModal,
  setShowActiveModal,
  setShowDeleteModal,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  setform,
  filters,
  form,
  loaging,
  CatType,
  categoriesdata,
  data,
  isAllow,
  loading,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };
  const [StatusData, setStatusData] = useState({});
  const ConfirmFunc = () => {
    statusChange(StatusData);
  };
  return (
    <Layout>
      <div className="heightVH">
        {" "}
        <CommonActiveModal
          show={ShowActiveModal}
          setShow={setShowActiveModal}
          confirm={ConfirmFunc}
          status={StatusData.status}
        />
        <CommonDeleteModal
          show={ShowDeleteModal}
          setShow={setShowDeleteModal}
          confirm={Delete}
        />
        <div className="normalFlex align-items-center">
          <h3 className="hedding">Speciality</h3>

          <div className="d-flex gap-2">
           

            <div className="dropdown addDropdown">
              <button
                className="btn btn-primary dropdown-toggle removeBg"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {filters.status
                  ? filters.status == "deactive"
                    ? "Inactive"
                    : filters.status
                  : "All Status"}
              </button>
              <div
                className="dropdown-menu shadow bg_hover"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={
                    filters.status == ""
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("")}
                >
                  All Status
                </a>
                <a
                  className={
                    filters.status == "active"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("active")}
                >
                  Active
                </a>
                <a
                  className={
                    filters.status == "Inactive"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("deactive")}
                >
                  Inactive
                </a>
              </div>

             
            </div>

            <div className="">
              <select
                className="form-control ccttype"
                required
                value={filters?.category}
                onChange={(e) => CatType(e.target.value)}
              >
                <option value="">Select Category</option>
                {categoriesdata &&
                  categoriesdata.map((item, index) => (
                    <option className="text-capitalize" value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            {isAllow("addSkillType") ? (
              <Link
                className="btn btn-primary  common_btn"
                to="/AddEditspeciality"
              >
                <i className="fa fa-plus me-2"></i>
                Add Speciality
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
        <>
          <div className="table-responsive">
            <div className="table_section">
              <table class="table">
                <thead className="table_head">
                  <tr className="heading_row">
                    <th scope="col" className="table_data">
                      name
                    </th>

                    <th scope="col" className="table_data">
                      Category
                    </th>
                    <th scope="col" className="table_data">
                      Status
                    </th>
                    <th scope="col" className="table_data">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((itm, index) => (
                      <tr className="data_row">
                        <td
                          className="table_dats"
                          onClick={(e) => edit(itm.id)}
                        >
                          {itm.name}
                        </td>

                        <td className="table_dats">
                          {itm?.categoryDetail.name}
                        </td>
                        <td className="table_dats">
                          {" "}
                          <div className={` ${itm.status}`}>
                            <span className="contract">
                              {itm.status == "deactive" ? (
                                <Switch
                                  onChange={(e) => {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  }}
                                  checked={false}
                                />
                              ) : (
                                <Switch
                                  onChange={(e) => {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  }}
                                  checked={true}
                                />
                              )}
                            </span>
                          </div>
                        </td>

                        <td className="table_dats">
                          {/* <div className="action_icons">
                            {isAllow("editfaq") ? (
                              <>
                                <a
                                  className="edit_icon"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <i class="material-icons edit" title="Edit">
                                    edit
                                  </i>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}

                            {isAllow("deletefaq") ? (
                              <span
                                className="edit_icon"
                                onClick={() => {
                                  setDeleteId(itm.id);
                                  setShowDeleteModal("block");
                                }}
                              >
                                <i class="material-icons delete" title="Delete">
                                  {" "}
                                  delete
                                </i>
                              </span>
                            ) : (
                              <></>
                            )}

                            <span
                              className="edit_icon"
                              title="View"
                              onClick={() =>
                                Navigate.push(`specialityDetails/${itm.id}`)
                              }
                            >
                              <i className="fa fa-eye"></i>
                            </span>
                          </div> */}
                          <span class=" d-flex gap-2 mt-1">
                            {" "}
                            <span
                              class="edit_icon iconsWidthAdjust h-auto"
                              title="View"
                              onClick={() =>
                                Navigate.push(`specialityDetails/${itm.id}`)
                              }
                            >
                              <i class="fa fa-eye"></i>
                            </span>
                            <span class="edit_icon  h-auto">
                              {isAllow("editfaq") ? (
                                <>
                                  <i onClick={(e) => edit(itm.id)} class="material-icons edit" title="Edit">
                                    edit
                                  </i>

                                </>
                              ) : (
                                <></>
                              )}
                            </span>
                            <span
                              class="edit_icon iconsWidthAdjust h-auto"
                              title="Delete"
                            >
                              {isAllow("deletefaq") ? (
                                <i onClick={() => {
                                  setDeleteId(itm.id);
                                  setShowDeleteModal("block");
                                }} class="material-icons delete" title="Delete">
                                  {" "}
                                  delete
                                </i>
                              ) : (
                                <></>
                              )}

                            </span>
                          </span>
                        </td>

                        {/* end */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
        {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
          <div className="text-danger text-center">No Data</div>
        ) : null}
        {!loaging && total > filters.count ? (
          <div className="paginationWrapper">
            <span>
              Show {filters.count} from {total} Categories
            </span>
            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}
        {loaging ? (
          <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default Html;
