import { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';

export default function ComissionSetting() {
    const [form, setform] = useState({ id: "", platformFee: "", RecruiterComission: "", retainer_hours: "", processingFee: "", virtualCommission: "",employerCommission:"" });
    const GetComissionData = () => {
        loader(true);
        ApiClient.get(`setting`).then(res => {
            if (res.success) {
                const data = res.data;
                setform({ id: data.id, processingFee: data.processingFee, RecruiterComission: data.recruiterCommission, platformFee: data.platformFee, retainer_hours: data.retainer_hours, virtualCommission: res?.data?.virtualCommission,employerCommission:res?.data?.employerCommission })

            }
            loader(false)
        })
    }

    useEffect(() => {
        GetComissionData()
    }, [])


    const HandleSubmit = (e) => {
        e.preventDefault();
        loader(true)
        const payload = { "id": form.id, "recruiterCommission": form.RecruiterComission, "virtualCommission": form?.virtualCommission,"employerCommission":form?.employerCommission, "platformFee": form.platformFee, "retainer_hours": form.retainer_hours, "processingFee": form.processingFee }
        ApiClient.put(`setting`, payload).then(res => {
            if (res.success) {
                GetComissionData()
                toast.success(res.message)
            }
            loader(false)

        })
    }
    return (
        <div>
            <Layout>
           <div className='heightVH'>     <h3 className='hedding mb-3'>Settings</h3>
                <div className="pprofile1">
                    <form onSubmit={e => HandleSubmit(e)}>
                        <div className='row mt-2'>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Recruiter Commission(%)</label>
                                <input className='form-control' type='number' min={0} value={form.RecruiterComission} onChange={e => setform({ ...form, RecruiterComission: e.target.value })} required />

                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Virtual Commission(%)</label>
                                <input className='form-control' type='number' min={0} value={form.virtualCommission} onChange={e => setform({ ...form, virtualCommission: e.target.value })} required />

                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Employer Commission(USD)</label>
                                <input className='form-control' type='number' min={0} value={form.employerCommission} onChange={e => setform({ ...form, employerCommission: e.target.value })} required />

                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Platform Fee(%)</label>
                                <input type='number' min={0} className='form-control' value={form.platformFee} onChange={e => setform({ ...form, platformFee: e.target.value })} required />
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Payment Processing Fee (USD)</label>
                                <input type='number' min={0} step='0.01' className='form-control' value={form.processingFee} onChange={e => setform({ ...form, processingFee: e.target.value })} required />
                            </div>
                            <div className='col-md-6'>
                                <label className='form-label'> Week</label>
                                <input type='number' min={0} className='form-control' value={form.retainer_hours} onChange={e => setform({ ...form, retainer_hours: e.target.value })} required />
                            </div>
                            <div className='col-md-12 mt-3'>
                                <button type='submit' style={{ float: "right", marginRight: "10px" }} className='btn btn-primary'>Save</button>
                            </div>

                        </div>
                    </form>
                </div></div>
            </Layout>
        </div>
    )
}
