import React, { memo, useEffect, useState } from "react";
import Layout from "./../../components/global/layout/index";
import { useHistory } from "react-router";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import moment from "moment";
import Pagination from "react-pagination-js";
import { toast } from "react-toastify";
import { DatePicker } from "antd";
import { MdDelete } from "react-icons/md";

import DatePickers from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TimezoneSelect from "react-timezone-select";
import dayjs from "dayjs";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
const { RangePicker } = DatePicker;
import { setHours, setMinutes } from 'date-fns';
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";

const BillingDetals = () => {
    const GlobalTime = [
        { day: "Mon", startTime: "9:00 AM", lunchBreak: "30 Min", endTime: "5:00 PM" },
        { day: "Tue", startTime: "9:00 AM", lunchBreak: "30 Min", endTime: "5:00 PM" },
        { day: "Wed", startTime: "9:00 AM", lunchBreak: "30 Min", endTime: "5:00 PM" },
        { day: "Thu", startTime: "9:00 AM", lunchBreak: "30 Min", endTime: "5:00 PM" },
        { day: "Fri", startTime: "9:00 AM", lunchBreak: "30 Min", endTime: "5:00 PM" },
        { day: "Sat", startTime: "", lunchBreak: "", endTime: "" },
        { day: "Sun", startTime: "", lunchBreak: "", endTime: "" },
    ]
    const [timeTable, setTimeTable] = useState(GlobalTime);
    const history = useHistory();
    const [total, setTotal] = useState(0);
    const user = useSelector((state) => state.user);
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 20, search: "" });
    const [SelectedDateRange, setSelectedDateRange] = useState(null);
    let param = new URLSearchParams(window.location.search);
    const [Submitted, setSubmitted] = useState(false);
    const id = param.get("id");
    const projectId = param.get("projectId");
    const [form, setForm] = useState({})

    const [date, setDate] = useState(null)
    const [editdata, setEditdata] = useState(false)

    const [startDate, setStartDate] = useState(
        setHours(setMinutes(new Date(), 0), 9)
    );

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const [ProjectsData, setProjectsData] = useState([]);
    const GetAllProjects = (p = {}) => {
        loader(true);
        let filter = { ...filters, ...p, contractId: id };
        ApiClient.get(`billing-list/virtual`, filter).then((res) => {
            if (res.success) {

                // setTimeTable(res?.data[0]?.contractDetail?.timeTable || GlobalTime)
                setProjectsData(res.data);
                setTotal(res.total);
            }
            loader(false);
        });
    };


    const GetContractDetail = () => {
        ApiClient.get(`contract`, { id: id }).then(res => {
            if (res.success) {
                setTimeTable(res?.data?.timeTable || GlobalTime)
            }
        })
    }
    const [TimeZone, setTimeZone] = useState("");

    useEffect(() => {
        GetContractDetail()
        GetAllProjects();
    }, []);

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data });
            GetAllProjects({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        GetAllProjects({ page: e });
    };


    const handleDateChange = (date, dateStrings) => {
        setSelectedDateRange(date);
        let dates = dateStrings;
        setFilter({
            ...filters,
            start: dates[0] || "",
            end: dates[1] || "",
        });
        GetAllProjects({ start: dates[0] || "", end: dates[1] || "" });
        // You can perform additional filtering or other actions based on the selected date range
    };

    const handleDateChange12 = (date, dateStrings) => {
        console.log({ date, dateStrings })
        let dates = dateStrings;
        setDate(dates)
    };


    const Getcalculculateminute = (res) => {
        let hours = parseInt(res?.totalDurationOfTime_hour);
        let minutes = parseInt(res?.totalDurationOfTime_min);
        let remainder = minutes / 60;
        let quotient = minutes % 60;
        hours += parseInt(remainder);
        minutes = quotient;
        return `${hours || "00"} : ${minutes < 10 ? "0" + minutes : minutes || "00"
            }`;
    };

    const OpenModal = () => {
        setDailyTimes([]);
        setForm({ memo: "", endTime: "", startTime: "" })
        setDate("")
        setSubmitted(false)
        setTimeZone("")
        setEditdata(false)
        SetTimeZone()
        document.getElementById("OpenBillingModal").click()
    }

    const TImeComvertorTime = (timestring) => {
        return datepipeModel.TimeConvertToTimezone(timestring, '8888')
    }

    const AddBilling = (e) => {
        e.preventDefault()

        setSubmitted(true)
        const startdate = form?.startTime
        const isoDate = startdate
        const Enddate = form?.endTime
        console.log(form, "This is the Form Data ")
        const endparsedDate = Enddate
        if (!startdate || !Enddate) {
            return
        }
        const data = dailyTimes.map((item) => {

            return ({ systemTimeZone: TimeZone?.value || TimeZone, memo: form?.memo, projectId: projectId, contractId: id, timeZone: TimeZone?.value || TimeZone, endTime: TImeComvertorTime((item?.endTime).toString()), startTime: TImeComvertorTime((item?.startTime).toString()) })
        })

        const payload = {
            systemTimeZone: TimeZone?.value || TimeZone, memo: form?.memo, endTime: TImeComvertorTime((endparsedDate).toString()), projectId: projectId, contractId: id, timeZone: TimeZone?.value || TimeZone, startTime: TImeComvertorTime((isoDate).toString())
        }

        loader(true)
        ApiClient.post(`billing/add-admin`, { "records": data }).then((res) => {
            if (res.success) {
                GetAllProjects()
                toast.success(res.message)
                document.getElementById("closebillingmodal").click()
            }
            loader(false)
        })
    }


    const getMinEndTime = () => {
        return form.startTime ? moment(new Date(form.startTime)).format("YYYY-DD-MM hh:mm").slice(0, 16) : '';
    };

    const StarterSchedule = [
        { id: 0, name: "12:00 AM" },
        { id: 1, name: "12:15 AM" },
        { id: 2, name: "12:30 AM" },
        { id: 3, name: "12:45 AM" },
        { id: 4, name: "1:00 AM" },
        { id: 5, name: "1:15 AM" },
        { id: 6, name: "1:30 AM" },
        { id: 7, name: "1:45 AM" },
        { id: 8, name: "2:00 AM" },
        { id: 9, name: "2:15 AM" },
        { id: 10, name: "2:30 AM" },
        { id: 11, name: "2:45 AM" },
        { id: 12, name: "3:00 AM" },
        { id: 13, name: "3:15 AM" },
        { id: 14, name: "3:30 AM" },
        { id: 15, name: "3:45 AM" },
        { id: 16, name: "4:00 AM" },
        { id: 17, name: "4:15 AM" },
        { id: 18, name: "4:30 AM" },
        { id: 19, name: "4:45 AM" },
        { id: 20, name: "5:00 AM" },
        { id: 21, name: "5:15 AM" },
        { id: 22, name: "5:30 AM" },
        { id: 23, name: "5:45 AM" },
        { id: 24, name: "6:00 AM" },
        { id: 25, name: "6:15 AM" },
        { id: 26, name: "6:30 AM" },
        { id: 27, name: "6:45 AM" },
        { id: 28, name: "7:00 AM" },
        { id: 29, name: "7:15 AM" },
        { id: 30, name: "7:30 AM" },
        { id: 31, name: "7:45 AM" },
        { id: 32, name: "8:00 AM" },
        { id: 33, name: "8:15 AM" },
        { id: 34, name: "8:30 AM" },
        { id: 35, name: "8:45 AM" },
        { id: 36, name: "9:00 AM" },
        { id: 37, name: "9:15 AM" },
        { id: 38, name: "9:30 AM" },
        { id: 39, name: "9:45 AM" },
        { id: 40, name: "10:00 AM" },
        { id: 41, name: "10:15 AM" },
        { id: 42, name: "10:30 AM" },
        { id: 43, name: "10:45 AM" },
        { id: 44, name: "11:00 AM" },
        { id: 45, name: "11:15 AM" },
        { id: 46, name: "11:30 AM" },
        { id: 47, name: "11:45 AM" },
        { id: 48, name: "12:00 PM" },
        { id: 49, name: "12:15 PM" },
        { id: 50, name: "12:30 PM" },
        { id: 51, name: "12:45 PM" },
        { id: 52, name: "1:00 PM" },
        { id: 53, name: "1:15 PM" },
        { id: 54, name: "1:30 PM" },
        { id: 55, name: "1:45 PM" },
        { id: 56, name: "2:00 PM" },
        { id: 57, name: "2:15 PM" },
        { id: 58, name: "2:30 PM" },
        { id: 59, name: "2:45 PM" },
        { id: 60, name: "3:00 PM" },
        { id: 61, name: "3:15 PM" },
        { id: 62, name: "3:30 PM" },
        { id: 63, name: "3:45 PM" },
        { id: 64, name: "4:00 PM" },
        { id: 65, name: "4:15 PM" },
        { id: 66, name: "4:30 PM" },
        { id: 67, name: "4:45 PM" },
        { id: 68, name: "5:00 PM" },
        { id: 69, name: "5:15 PM" },
        { id: 70, name: "5:30 PM" },
        { id: 71, name: "5:45 PM" },
        { id: 72, name: "6:00 PM" },
        { id: 73, name: "6:15 PM" },
        { id: 74, name: "6:30 PM" },
        { id: 75, name: "6:45 PM" },
        { id: 76, name: "7:00 PM" },
        { id: 77, name: "7:15 PM" },
        { id: 78, name: "7:30 PM" },
        { id: 79, name: "7:45 PM" },
        { id: 80, name: "8:00 PM" },
        { id: 81, name: "8:15 PM" },
        { id: 82, name: "8:30 PM" },
        { id: 83, name: "8:45 PM" },
        { id: 84, name: "9:00 PM" },
        { id: 85, name: "9:15 PM" },
        { id: 86, name: "9:30 PM" },
        { id: 87, name: "9:45 PM" },
        { id: 88, name: "10:00 PM" },
        { id: 89, name: "10:15 PM" },
        { id: 90, name: "10:30 PM" },
        { id: 91, name: "10:45 PM" },
        { id: 92, name: "11:00 PM" },
        { id: 93, name: "11:15 PM" },
        { id: 94, name: "11:30 PM" },
        { id: 95, name: "11:45 PM" },
    ];


    const ArrayTimeReturner = (name) => {
        let data = StarterSchedule?.find((item) => item?.name == name)?.id
        return data
    }

    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId)
    }


    const deleteItem = (id) => {
        loader(true)
        ApiClient.delete(`removed/billing?id=${id}`).then(res => {
            if (res.success) {
                toast.success(res.message)
                setShowDeleteModal("none");
                GetAllProjects()
            }
            loader(false)
        })
    }


    const SetTimeZone = () => {
        ApiClient.get(`contract?id=${id}`).then((res) => {
            if (res.success) {
                console.log(res.data.timeZone.value, "data")
                setTimeZone(res.data.contractTo.timeZone.value)
            }
        })
    }

    const [ShowDeleteModal, setShowDeleteModal] = useState("none");


    const UpdateBilling = (e) => {
        e.preventDefault()
        setSubmitted(true);
        const startdate = form?.startTime
        const isoDate = startdate
        const Enddate = form?.endTime

        const endparsedDate = Enddate
        if (!startdate || !Enddate) {
            return
        }
        if (!form?.memo || !form?.startTime || !form?.endTime || !TimeZone) {
            return false
        }
        const payload = {
            data: [
                {
                    id: form?.id, memo: form?.memo, endTime: TImeComvertorTime((endparsedDate).toString()), projectId: projectId, contractId: id, timeZone: TimeZone?.value || TimeZone, startTime: TImeComvertorTime((isoDate).toString())
                }]
        }
        ApiClient.put(`updateBilling`, payload).then((res) => {
            if (res?.success) {
                GetAllProjects()
                toast.success(res.message)
                setEditdata(false)
                document.getElementById("closebillingmodal").click()
            }
        })
    }
    const OpenEditModal = (itm) => {
        // console.error({ startTime: new Date(itm?.startTime), date: itm?.startTime, UTcDate: new Date(itm?.startTime).toUTCString()})
        // const startTime = new Date(itm?.startTime); // Treated as UTC automatically
        // const utcDate = new Date(Date.UTC(
        //     startTime.getUTCFullYear(),
        //     startTime.getUTCMonth(),
        //     startTime.getUTCDate(),
        //     startTime.getUTCHours()-5,
        //     startTime.getUTCMinutes()-30,
        //     startTime.getUTCSeconds()
        // ));
        // const endTime = new Date(itm?.endTime); // Treated as UTC automatically
        // const utcDateEnd = new Date(Date.UTC(
        //     endTime.getUTCFullYear(),
        //     endTime.getUTCMonth(),
        //     endTime.getUTCDate(),
        //     endTime.getUTCHours() - 5,
        //     endTime.getUTCMinutes() - 30,
        //     endTime.getUTCSeconds()
        // ));

        const startDate = datepipeModel.ReverseTimetoObj(itm?.startTime.toString())
        const endDate = datepipeModel.ReverseTimetoObj(itm?.endTime.toString())

        setSubmitted(false);
        document.getElementById("OpenBillingModal").click()
        setForm({
            id: itm?._id,
            memo: itm?.memo,
            startTime: startDate,
            endTime: itm?.endTime ? endDate : "",
            timeZone: itm?.timeZone || TimeZone,
        })
        setTimeZone(itm?.timeZone || "")
    }

    const disabledEndDate = (current) => {
        if (!form?.startTime) return false;
        return current && current < new Date(form?.startTime).startOf('day');
    };

    const Sort = (key) => {
        let sorder = 'asc';
        if (filters.key === key) {
            sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
        }
        setFilter({ key, sorder });

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        GetAllProjects({ sortBy, key, sorder });
    };

    const [dailyTimes, setDailyTimes] = useState([]);

    const getDefaultTimes = (weekday) => {
        const dayConfig = timeTable?.find(item => item.day === weekday);
        return dayConfig ? { startTime: dayConfig.startTime, endTime: dayConfig.endTime, shouldDelete: false } : { startTime: '', endTime: '', shouldDelete: true };
    };
    const generateDateRange = (start, end) => {
        const dates = [];
        let currentDate = moment(start);
        const endDate = moment(end);

        while (currentDate <= endDate) {
            const weekday = currentDate.format("ddd");
            const { startTime, endTime, shouldDelete } = getDefaultTimes(weekday);
            dates.push({
                date: currentDate.format("YYYY-MM-DD"),
                startTime: startTime ? moment(`${currentDate.format("YYYY-MM-DD")} ${startTime}`, "YYYY-MM-DD h:mm A").toDate() : null,
                endTime: endTime ? moment(`${currentDate.format("YYYY-MM-DD")} ${endTime}`, "YYYY-MM-DD h:mm A").toDate() : null,
                shouldDelete: startTime ? false : true
            });
            currentDate = currentDate.clone().add(1, "day");
        }
        return dates;
    };

    const handleDateChangeInput = (start, end) => {
        const dateRange = generateDateRange(start, end);
        setDailyTimes(dateRange.map(date => ({
            date: date,
            startTime: date?.startTime,
            shouldDelete: date?.shouldDelete,
            endTime: date?.endTime,
        })));
    };

    const handleTimeChange = (date, timeType, time) => {
        setDailyTimes(dailyTimes.map(day =>
            day.date === date ? { ...day, [timeType]: time } : day
        ));
    };

    const handleStartDateChange = (date) => {
        // If there is no end time, just update the start time
        if (!form.endTime) {
            setForm((prevForm) => ({
                ...prevForm,
                startTime: date,
            }));
        } else {
            // Update end time to match start date but preserve the original end time's time
            const updatedEndDate = new Date(form.endTime);
            updatedEndDate.setFullYear(date.getFullYear());
            updatedEndDate.setMonth(date.getMonth());
            updatedEndDate.setDate(date.getDate());

            // If end time is earlier than start time, update end time to match start time
            if (updatedEndDate < date) {
                setForm((prevForm) => ({
                    ...prevForm,
                    startTime: date,
                    endTime: new Date(date), // Update end time to be the same as start time
                }));
            } else {
                setForm((prevForm) => ({
                    ...prevForm,
                    startTime: date,
                    endTime: updatedEndDate, // Keep the time but change the date to match the start date
                }));
            }
        }
    };

    const handleEndDateChange = (date) => {
        // If there is no start time, just update the end time
        if (!form.startTime) {
            setForm((prevForm) => ({
                ...prevForm,
                endTime: date,
            }));
        } else {
            // Update start time to match end date but preserve the original start time's time
            const updatedStartDate = new Date(form.startTime);
            updatedStartDate.setFullYear(date.getFullYear());
            updatedStartDate.setMonth(date.getMonth());
            updatedStartDate.setDate(date.getDate());

            // If end time is earlier than start time, update end time to match start time
            if (date < updatedStartDate) {
                setForm((prevForm) => ({
                    ...prevForm,
                    endTime: updatedStartDate, // Set end time to match the start time
                    startTime: updatedStartDate, // Optionally, also update the start time
                }));
            } else {
                setForm((prevForm) => ({
                    ...prevForm,
                    endTime: date,
                    startTime: updatedStartDate, // Keep the time but change the date to match the end date
                }));
            }
        }
    };

    const GetHourMinutesDates = (start_date, end_Date) => {
        var today = new Date(start_date);
        var Christmas = new Date(end_Date);
        var diffMs = (Christmas - today); // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        if (diffHrs < 10) {
            diffHrs = "0" + diffHrs
        }
        if (diffMins < 10) {
            diffMins = "0" + diffMins
        }
        return diffHrs + ":" + diffMins + " hrs"
    }

    return (
        <>
            <Layout>
                <CommonDeleteModal show={ShowDeleteModal} setShow={setShowDeleteModal} confirm={Delete} />
                <div className="heightVH">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex gap-1 align-items-center">
                            <i
                                onClick={(e) => history.goBack()}
                                class="fa fa-angle-double-left fontBack text-dark"
                                title="Back"
                            ></i>{" "}
                            <h3 className="hedding d-flex align-items-center">Billing Details {ProjectsData[0]?.contractDetail?.contractName || ProjectsData[0]?.contractDetail?.title ? <span className="contracts-highlight">({ProjectsData[0]?.contractDetail?.contractName || ProjectsData[0]?.contractDetail?.title})</span> : null}</h3>
                        </span>
                        <button className="btn btn-primary  " onClick={e => OpenModal()}><i className="fa fa-plus me-2"></i>Add Time Clock</button>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="datePickeWidth fullWidth">
                                    <label>Date range</label>
                                    <RangePicker
                                        className="dateRangePicker"
                                        placeholder={["Start Date", "End Date"]}
                                        format="MM-DD-YYYY"
                                        onChange={handleDateChange}
                                        value={SelectedDateRange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <div className="table_section mt-4">
                            <table class="table mb-0 ">
                                <thead className="table_head">
                                    <tr className="heading_row">
                                        <th scope="col" className=" table_data">
                                            Memo{" "}
                                        </th>
                                        <th scope="col" onClick={e => Sort('startTime')} className="pointer table_data">
                                            Start Date{" "}
                                        </th>
                                        <th scope="col" className=" table_data">
                                            End Date{" "}
                                        </th>
                                        <th scope="col" className=" table_data">
                                            Start Time{" "}
                                        </th>
                                        <th scope="col" className="table_data">
                                            End Time{" "}
                                        </th>
                                        <th scope="col" className="table_data"> Action</th>
                                        {/* <th scope="col" className="table_data">
                                        Total Hours
                                    </th> */}
                                        {/* <th scope="col" className="table_data">
                                        Actions
                                    </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ProjectsData.map((itm, index) => (
                                        <tr className="data_row">
                                            <td className="table_dats">
                                                {itm?.memo}
                                            </td>
                                            <td className="table_dats">
                                                {moment.utc(itm?.startTime, 'YYYY-MM-DDThh:mm:SSSZ').format("MM/DD/YYYY")}
                                            </td>
                                            <td className="table_dats">
                                                {itm?.endTime ? moment.utc(itm?.endTime, 'YYYY-MM-DDThh:mm:SSSZ').format("MM/DD/YYYY") : "--"}
                                            </td>
                                            <td className="table_dats">
                                                {methodModel.ConvertTime(itm?.startTime)}
                                            </td>
                                            <td className="table_dats">
                                                {itm?.endTime ? methodModel.ConvertTime(itm?.endTime) : "---"}
                                            </td>
                                            <td className='table_dats'>
                                                <div className="action_icons gap-3">
                                                    {<>
                                                        <a className="edit_icon iconsWidthAdjust" onClick={e => { OpenEditModal(itm); setEditdata(true) }}>
                                                            <i class="material-icons edit" title="Edit">edit</i>
                                                        </a>
                                                    </>}
                                                    {<span className='edit_icon iconsWidthAdjust' onClick={() => { setDeleteId(itm._id); setShowDeleteModal("block") }}>
                                                        <i class="material-icons delete" title='Delete'> delete</i>
                                                    </span>}
                                                </div>
                                            </td>
                                            {/* <td className="table_dats">
                                            {Getcalculculateminute(itm)}{" "}
                                            hrs
                                        </td> */}

                                            {/* <td className="table_dats">
                                            <span className=" d-flex gap-2 mt-1">
                                                {" "}
                                                <span
                                                    className="edit_icon iconsWidthAdjust mx-2 h-auto"
                                                    title="View"
                                                    onClick={() => View(itm.id)}
                                                >
                                                    <i className="fa fa-eye text-primary"></i>
                                                </span>
                                                <span
                                                    className="edit_icon iconsWidthAdjust h-auto"
                                                    title="Billing Detail"
                                                    onClick={() => View(itm.id)}
                                                >
                                                    <i className="fa fa-info"></i>
                                                </span>
                                                <span className="edit_icon  h-auto" onClick={(e) => edit(itm.id)}>
                                                    <i class="material-icons edit" title="Edit">
                                                        edit
                                                    </i>
                                                </span>
                                                <span
                                                    className="edit_icon iconsWidthAdjust h-auto"
                                                    title="Delete"
                                                    onClick={() => {
                                                        setDeleteId(itm.id);
                                                        setShowDeleteModal("block");
                                                    }}
                                                >
                                                    <i class="material-icons delete" title="Delete">
                                                        {" "}
                                                        delete
                                                    </i>
                                                </span>
                                            </span>
                                        </td> */}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colspan="6" className="no-data-sect">
                                            {ProjectsData?.length === 0 ? <div className="py-3 text-center ">No Data</div> : <></>}
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>

                    {total > filters.count ? (
                        <div className="paginationWrapper">
                            <span>
                                Show {filters.count} from {total} Contract
                            </span>
                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={pageChange}
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    <button type="button" id="OpenBillingModal" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Launch demo modal
                    </button>

                    <div class="modal fade clocktimes" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg ">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{editdata ? "Edit" : "Add"} Time Clock </h5>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={e => editdata ? UpdateBilling(e) : AddBilling(e)}>
                                        <div className="px-2 pb-2">
                                            <div className="row">
                                                {/* Start Time and End Time Selectors */}
                                                <div className="col-md-6 mb-4">
                                                    <label>Start Time<span className="star">*</span></label>
                                                    <DatePickers
                                                        className="form-control"
                                                        selected={form.startTime}
                                                        onChange={(date) => editdata ? handleStartDateChange(date) : setForm({ ...form, startTime: date })}
                                                        showTimeSelect={editdata}
                                                        dateFormat={editdata ? "MMMM d, yyyy h:mm aa" : `MMMM d, yyyy`}
                                                        placeholderText="Start Time"
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <label>End Time<span className="star">*</span></label>
                                                    <DatePickers
                                                        className="form-control"
                                                        selected={form.endTime}
                                                        onChange={(date) => {
                                                            if (editdata) {
                                                                handleEndDateChange(date)
                                                            } else {
                                                                setForm({ ...form, endTime: date });
                                                                handleDateChangeInput(form.startTime, date);
                                                            }
                                                        }}
                                                        showTimeSelect={editdata}
                                                        dateFormat={editdata ? "MMMM d, yyyy h:mm aa" : `MMMM d, yyyy`}
                                                        placeholderText="End Time"
                                                        minDate={form.startTime}
                                                    />
                                                </div>

                                                {!editdata && dailyTimes.map(({ date, startTime, endTime, shouldDelete }, index) => (
                                                    <div key={date} className="col-md-12 mb-4">
                                                        <label>{moment(date?.date || date).format('MMMM D,YYYY')}</label>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <DatePickers
                                                                    required
                                                                    selected={startTime}
                                                                    onChange={(time, datei) => {

                                                                        const updatedDateTime = moment(date?.date || date).set({
                                                                            hour: moment(time).hour(),
                                                                            minute: moment(time).minute()
                                                                        }).toDate();
                                                                        console.log(updatedDateTime, "THIS IS THE DATA ")
                                                                        handleTimeChange(date, "startTime", updatedDateTime)
                                                                    }}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={30}
                                                                    timeCaption="Start Time"
                                                                    dateFormat="h:mm aa"
                                                                    className="form-control mr-2"
                                                                    placeholderText="Start Time"
                                                                />
                                                            </div>
                                                            <div className="col-md-4">



                                                                <DatePickers
                                                                    selected={endTime}
                                                                    onChange={(time) => {

                                                                        const updatedDateTime = moment(date?.date || date).set({
                                                                            hour: moment(time).hour(),
                                                                            minute: moment(time).minute()
                                                                        }).toDate();
                                                                        handleTimeChange(date, "endTime", updatedDateTime)
                                                                    }}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    required
                                                                    timeIntervals={30}
                                                                    timeCaption="End Time"
                                                                    dateFormat="h:mm aa"
                                                                    className="form-control ml-2"
                                                                    placeholderText="End Time"
                                                                />
                                                            </div>

                                                            <div className="col-md-4 d-flex align-items-center m-auto">


                                                                <input type="" value={GetHourMinutesDates(startTime, endTime)} class="form-control" id="exampleInputEmail1" placeholder="Total Hours" aria-describedby="emailHelp" />
                                                                {shouldDelete && <MdDelete onClick={e => {
                                                                    const previousData = [...dailyTimes];
                                                                    setDailyTimes(previousData.filter((_, i) => i !== index));
                                                                }} className="delete-icon ml-3" />}
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                                {/* Timezone Selector */}
                                                <div className="col-md-6 mb-4">
                                                    <label>Timezone<span className="star">*</span></label>
                                                    <TimezoneSelect
                                                        value={TimeZone}
                                                        onChange={(e) => setTimeZone(e)}
                                                    />
                                                </div>

                                                {/* Dynamic Time Fields for Each Date */}


                                                {/* Memo Field */}
                                                <div className="col-md-12">
                                                    <label>Memo<span className="star">*</span></label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control memo_radius"
                                                        name="memo"
                                                        value={form.memo}
                                                        onChange={(e) => setForm({ ...form, memo: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button id="closebillingmodal" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div></div>
            </Layout>
        </>
    );
};
export default BillingDetals;

