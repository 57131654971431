import React, { useEffect, useState } from "react";
import Html from "./Html";
import ApiClient from "../../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import loader from "../../../methods/loader";


const AddEditTestonomal = () => {
    const { id } = useParams()
    const history = useHistory()
    const [form, setForm] = useState({
        title: "",
        description: "",
        designation: "",
        image: ""
    })
    const [images, setImages] = useState({ image: "" })

    const AddEditdata = (e) => {
        e.preventDefault()
        let method = "post"
        let url = "testimonial"
        let payload = { ...form, id: id, ...images }
        if (id) {
            method = "put"
            url = "testimonial"
        } else {
            delete payload.id
        }
        ApiClient.allApi(url, payload, method).then((res) => {
            if (res.success) {
                history.push("/testonimal")
                toast.success(res.message)
            }
        })
    }

    const Handleaddchange = (e) => {
        const value = e.target.value;
        setForm({ ...form, [e.target.name]: value })
    }

    const Handleimage = (e, image) => {
        images[image] = e.value
        setImages(images)

    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(`testimonial?id=${id}`).then((res) => {
                const form = res.data
                setForm({ ...form, title: form.title, description: form.description, designation: form.designation })
                setImages({ ...images, image: form.image })
                loader(false)
            })
        }
    }, [])

    return <>
        <Html
            history={history}
            Handleaddchange={Handleaddchange}
            AddEditdata={AddEditdata}
            Handleimage={Handleimage}
            form={form}
            images={images}
            id={id}
            setForm={setForm}
        />

    </>
}

export default AddEditTestonomal;