import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import environment from "../../environment";
import loader from "../../methods/loader";
import Pagination from "react-pagination-js";
import { useSelector } from "react-redux";
import moment from "moment";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import methodModel from "../../methods/methods";

const Transactions = () => {
    const [data, setdata] = useState([])
    const [singledata, setsingledata] = useState({})
    const [filter, setFilter] = useState({ page: 1, count: 20, search: '' })
    const [total, settotal] = useState(0)
    const searchState = useSelector((state) => state.search);
    const user = useSelector(state => state.user)
    const [loading, setloading] = useState(true)


    // For searchbar
    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filter, search: searchState.data });
            GetTransactionData({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    // For Handle pagination
    const HandlepageChange = (number) => {
        setFilter({ ...filter, page: number });
        GetTransactionData({ page: number });
    };

    // For Get all Transcastions data
    const GetTransactionData = (p = {}) => {
        loader(true)
        let newfilter = { ...filter, ...p, payment_to: "employerToadmin" }
        ApiClient.get(`transactions`, newfilter).then((res) => {
            if (res.success) {
                loader(false)
                setdata(res?.data)
                settotal(res.total)
            }
            setloading(false)
        })
    }

    useEffect(() => {
        GetTransactionData();
    }, []);

    // For Approve Payment
    const UpdatePaymentstatus = (id) => {
        loader(true);
        let payload = {
            id: id,
            paymentStatus: "approved",
        };
        ApiClient.put(`approved-disapproved/transactions`, payload).then((res) => {
            if (res.success) {
                toast.success(res?.message);
                GetTransactionData();
                loader(false);
            }
        });
    };

    // For Open Transactions Detail modal
    const Openmodal = () => {
        document.getElementById("Paymentmodal").click();
    };
    const [ShowDeleteModal, setShowDeleteModal] = useState("none");
    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId);
    };

    const deleteItem = (id) => {
        loader(true);
        ApiClient.delete(`transaction?id=${id}`).then((res) => {
            if (res.success) {
                toast.success(res.message);
                setShowDeleteModal("none");
                GetTransactionData();
            }
            loader(false);
        });
    };

    return (
        <>
            <Layout>
                <CommonDeleteModal
                    show={ShowDeleteModal}
                    setShow={setShowDeleteModal}
                    confirm={Delete}
                />
                <div className="heightVH">    <h3 className=" hedding ">Transactions</h3>
                    <div className="table-responsive table_section mt-3">
                        <table class="table table contracts_table ">
                            <thead>
                                <tr>
                                    <th scope="col">Contract Name</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">VA Name</th>
                                    <th scope="col">Employer Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Credit</th>
                                    <th scope="col" className="text-end">Payment Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => (
                                    <tr>
                                        <td scope="col">{item?.contract_details?.createdBy === "admin" ? item?.projectsDetails?.name || item?.contract_details?.title : item?.contract_details?.title || item?.contract_details?.contractName}</td>
                                        <td scope="col">{item?.contract_details?.startDate ? methodModel.convertDate(item?.contract_details?.startDate) : "---"}</td>
                                        <td scope="col">{item?.virtual_details?.fullName || "---"}</td>
                                        <td scope="col">{item?.addedBy_details?.fullName || "---"}</td>
                                        <td scope="col">{item?.addedBy_details?.email || "---"}</td>
                                        <td scope="col">{item?.credit || "---"} USD</td>
                                        <td scope="col">
                                            <span className="d-flex align-items-center justify-content-end">
                                                {item?.contract_details?.createdBy === "admin" ? null :
                                                    <>
                                                        {item?.paymentStatus === "approved" ? <><input type="checkbox" className="mx-2 biggercheck" title="Approved" checked /> </> :
                                                            <input className="customboxc biggercheck mx-2" title="Approve" onChange={e => UpdatePaymentstatus(item?._id)} type="checkbox" />
                                                        }
                                                    </>
                                                    // <i className="fa fa-check text-success mx-2" title="Approve" onClick={e => UpdatePaymentstatus(item?._id)}></i>
                                                }
                                                <i className="fa fa-eye mx-1 dddd" title="View" onClick={e => { Openmodal(); setsingledata(item) }}></i>
                                                <i title="Delete"
                                                    onClick={() => {
                                                        setDeleteId(item._id);
                                                        setShowDeleteModal("block");
                                                    }} class="material-icons pointer pl-1 delete" >
                                                    {" "}
                                                    delete
                                                </i>
                                            </span>


                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
                        <div className="text-danger text-center">No Data</div>
                    ) : null}
                    {total > filter.count ? <div style={{ float: "right" }} className='paginationWrapper'>
                        <Pagination
                            currentPage={filter.page}
                            totalSize={total}
                            sizePerPage={filter.count}
                            changeCurrentPage={HandlepageChange}
                        />
                    </div> : <></>}</div>
            </Layout>

            {/* Transactions detail modal */}
            <button
                type="button"
                class="btn btn-primary d-none"
                id="Paymentmodal"
                data-toggle="modal"
                data-target="#exampleModal"
            >
                Launch demo modal
            </button>

            <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                data-backdrop="static"
                data-keyboard="false"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Transaction Details
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div className="container-fluid">  <div className="row">
                                <div className="col-md-6">
                                    <label className="weightlabel">Name*</label>
                                </div>
                                <div className="col-md-6 mb-3 ">
                                    <p className="text-capitalize weightdata mb-0"> {singledata?.addedBy_details?.fullName}</p>
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <label className="weightlabel">Company Name*</label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <p className="text-capitalize weightdata mb-0">  {singledata?.addedBy_details?.companyName || "---"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <label className="weightlabel">Date*</label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <p className="weightdata mb-0">  {singledata?.contract_details?.startDate ? methodModel.convertDate(singledata?.contract_details?.startDate) : "---"} </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="weightlabel">Weekly Limit*</label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <p className="weightdata mb-0">  {singledata?.contract_details?.weeklyLimit} hrs</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 "><label className="weightlabel">Credit*</label></div>

                                    <div className="col-md-6 mb-3">
                                        <p className="weightdata mb-0">  {singledata?.credit || "---"} USD </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 "><label className="weightlabel">Payment Status*</label></div>

                                    <div className="col-md-6 mb-3 ">
                                        <p className={` mb-0 weightdata text-capitalize ${singledata?.paymentStatus === "approved" ? "text-success" : "text-danger"}`}>
                                            {singledata?.paymentStatus ? singledata?.paymentStatus : "Pending"}
                                        </p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="weightlabel">Document*</label>
                                    </div>
                                    <div className="col-md-6 ">
                                        {singledata?.document ? (

                                            <img
                                                className="documentUpload_image "
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                                onClick={(e) =>
                                                    window.open(
                                                        `${environment.api}images/transactions/${singledata?.document}`
                                                    )
                                                }
                                            />

                                        ) : (
                                            <p className="text-danger weightdata mb-0">No Document</p>
                                        )}
                                    </div>


                                </div></div>
                        </div>

                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary m-0"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Transactions;
