import React, { useEffect, useState } from "react";
import Layout from "./../../components/global/layout/index";
import { useHistory } from "react-router";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "react-pagination-js";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import { toast } from "react-toastify";
import { HiOutlineArrowDown } from 'react-icons/hi';
import { HiArrowSmUp } from 'react-icons/hi';
import { DatePicker } from "antd";
import environment from "../../environment";
import methodModel from "../../methods/methods";
const { RangePicker } = DatePicker;

const EmployerContract = () => {
    const history = useHistory();
    const [total, setTotal] = useState(0);
    const user = useSelector((state) => state.user);
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 20, search: "" });
    const [ShowDeleteModal, setShowDeleteModal] = useState("none");
    const [SelectedDateRange, setSelectedDateRange] = useState(null);
    const [EmpList, setEmpList] = useState([]);
    const [VirtualList, setVirtualList] = useState([]);
    const [loading, setloading] = useState(true)
    const [ProjectsData, setProjectsData] = useState([]);
    const GetAllProjects = (p = {}) => {
        loader(true);
        let filter = { ...filters, ...p };
        filter.startDate=methodModel.MomentDateConvertor(filter?.startDate)
        filter.endDate = methodModel.MomentDateConvertor(filter?.endDate)

        ApiClient.get(`non-admin-contracts`, filter).then((res) => {
            if (res.success) {
                setProjectsData(res.data);
                setTotal(res.total);
            }
            loader(false);
            setloading(false)
        });
    };
    useEffect(() => {
        GetAllProjects();
    }, []);

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data });
            GetAllProjects({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        GetAllProjects({ page: e });
    };

    const edit = (id) => {
        history.push("/edit/empcontract/" + id);
    };

    const View = (id) => {
        history.push("/detail/empcontract/" + id);
    };

    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId);
    };

    const deleteItem = (id) => {
        loader(true);
        ApiClient.delete(`contract?id=${id}`).then((res) => {
            if (res.success) {
                toast.success(res.message);
                setShowDeleteModal("none");
                GetAllProjects();
            }
            loader(false);
        });
    };

    const sorting = (key) => {
        let sorder = 'asc';
        if (filters.key === key) {
            sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
        }
        setFilter({ key, sorder });

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        GetAllProjects({ sortBy, key, sorder });
    };

    const renderSortIcon = (columnKey) => {
        if (filters.key !== columnKey)
            return <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
        else {
            if (filters.sorder === 'asc') {
                <HiArrowSmUp className="shrink-0 inline text-sm" />;
            } else {
                <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
            }
        }
        return filters.sorder === 'asc' ? (
            <HiArrowSmUp className="shrink-0 inline text-sm" />
        ) : (
            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
        );
    };

    const handleDateChange = (date, dateStrings) => {
        setSelectedDateRange(date);
        let dates = dateStrings;
        setFilter({
            ...filters,
            startDate: dates[0] || "",
            endDate: dates[1] || "",
        });
        GetAllProjects({ startDate: dates[0] || "", endDate: dates[1] || "" });
        // You can perform additional filtering or other actions based on the selected date range
    };

    const GetEmpList = () => {
        ApiClient.get(
            `users/list?role=${environment.EmployerId}&isDeleted=false&isVerifiedDocument=accepted&sortBy=fullName asc`
        ).then(async (res) => {
            if (res.success) {
                const newdata = res.data;
                setEmpList(newdata);
            }
        });
    };

    const GetVirtualList = () => {
        ApiClient.get(
            `users/list?role=${environment.VirtualId}&isDeleted=false&isVerifiedDocument=accepted&sortBy=fullName asc`
        ).then((res) => {
            if (res.success) {
                setVirtualList(res?.data);
            }
        });
    };

    useEffect(() => {
        GetEmpList();
        GetVirtualList();
    }, []);


    return (
        <>
            <Layout>
                <CommonDeleteModal
                    show={ShowDeleteModal}
                    setShow={setShowDeleteModal}
                    confirm={Delete}
                />
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="hedding">Automated Additions</h3>
                </div>
                <div className='d-flex justify-content-between mt-3'>

                    <div className='row'>

                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                            <div className="datePickeWidth fullWidth">
                                <label>Date range</label>
                                <RangePicker
                                    className="dateRangePicker"
                                    placeholder={["Start Date", "End Date"]}
                                    format="MM-DD-YYYY"
                                    onChange={handleDateChange}
                                    value={SelectedDateRange}
                                />
                            </div>

                            <div className=' mWidthFull fullWidth'>
                                <label>Employer  </label>
                                <div class="dropdown bx_virtuals">
                                    <button
                                        class="btn employer_filt w-100 btn-secondary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i class="fa fa-sliders mr-1" aria-hidden="true"></i>{" "}
                                        {!filters.employerId
                                            ? "Select Employer"
                                            : EmpList.find((item) => item.id == filters.employerId)
                                                .fullName}
                                    </button>
                                    <ul class="dropdown-menu">
                                        {EmpList.map((item) => (
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    onClick={(e) => {
                                                        setFilter((prev) => ({
                                                            ...filters,
                                                            employerId: item?.id,
                                                        }));
                                                        GetAllProjects({ employerId: item?.id });
                                                    }}
                                                >
                                                    {item?.fullName}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='d-flex gap-2 align-items-center mWidthFull fullWidth'>
                                <div className="mWidthFull fullWidth">
                                    <label>Virtual Assistant  </label>
                                    <div class="dropdown fullWidth bx_virtuals">
                                        <button class="btn employer_filt w-100 btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa fa-sliders mr-1" aria-hidden="true"></i> {!filters.virtualId ? "Select VA" : VirtualList.find(item => item.id == filters.virtualId)?.fullName}

                                        </button>
                                        <ul class="dropdown-menu fullWidth">
                                            {VirtualList.map((item) => (
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        onClick={(e) => {
                                                            setFilter((prev) => ({
                                                                ...filters,
                                                                virtualId: item?.id,
                                                            }));
                                                            GetAllProjects({ virtualId: item?.id });
                                                        }}
                                                    >
                                                        {item?.fullName}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="clearMargin">
                                    <label className="clearOpacity"> clear </label>
                                    {filters.virtualId || filters.startDate || filters.endDate || filters.employerId ? <button className='btn btn-primary d-block mb-0' onClick={() => { setFilter({ virtualId: "", endDate: "", startDate: "", contractId: "", name: "", employerId: "", virtualId: "" }); setSelectedDateRange(null); GetAllProjects({ virtualId: "", endDate: "", startDate: "", contractId: "", name: "", employerId: "", virtualId: "" }) }}>Clear</button> : null}
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                <div className="table-responsive testmonialTable">
                    <div className="table_section">
                        <table class="table">
                            <thead className="table_head">
                                <tr className="heading_row">
                                    <th scope="col" onClick={(e) => sorting('title')} className="pointer table_data">
                                        {" "}
                                        Contract Title{" "}<span className="mr-2">
                                            {renderSortIcon('title')}
                                        </span>
                                    </th>
                                    <th onClick={(e) => sorting('createdAt')} scope="col" className=" pointer table_data">
                                        Start Date{" "}<span className="mr-2">
                                            {renderSortIcon('createdAt')}
                                        </span>
                                    </th>
                                    <th scope="col" onClick={(e) => sorting('virtualName')} className="pointer table_data">
                                        Virtual Name{" "}<span className="mr-2">
                                            {renderSortIcon('virtualName')}
                                        </span>
                                    </th>
                                    <th scope="col" className="table_data">
                                        Virtual Hourly Rate
                                    </th>
                                    <th scope="col" onClick={(e) => sorting('employerName')} className="pointer table_data">
                                        Employer Name{" "}<span className="mr-2">
                                            {renderSortIcon('employerName')}
                                        </span>
                                    </th>
                                    <th scope="col" className="table_data">
                                        Employer Hourly Fee
                                    </th>
                                    <th scope="col" className="table_data">
                                        Weekly Limit
                                    </th>
                                    <th scope="col" className="table_data">
                                        Status
                                    </th>
                                    <th scope="col" className="table_data">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ProjectsData.map((itm, index) => (
                                    <tr className="data_row">
                                        <td className="table_dats">
                                            {itm?.title || itm?.contractName || "---"}
                                        </td>
                                        <td className="table_dats">
                                            {methodModel.convertDate(itm?.startDate)}
                                        </td>
                                        <td className="table_dats">
                                            {itm?.contractTo_details?.fullName}
                                        </td>
                                        <td className="table_dats">
                                            {itm?.hourlyRate < 10
                                                ? `0${parseFloat(itm?.hourlyRate).toFixed(2)}`
                                                : parseFloat(itm?.hourlyRate).toFixed(2)}{" "}
                                            USD
                                        </td>
                                        <td className="table_dats">
                                            {itm?.contractFrom_details?.fullName}
                                        </td>
                                        <td className="table_dats">
                                            {itm?.employerHourlyRate < 10
                                                ? `0${parseFloat(itm?.employerHourlyRate).toFixed(2)}`
                                                : parseFloat(itm?.employerHourlyRate).toFixed(2)}{" "}
                                            USD
                                        </td>
                                        <td className="table_dats">{itm?.weeklyLimit} hrs</td>
                                        <td className="table_dats">
                                            <span className={`${itm?.isContractPayment ? "accepted" : "pending"}`}>{itm?.isContractPayment ? "Payment Done" : "Payment Awaited"}</span>
                                        </td>
                                        <td className="table_dats">
                                            <span className=" d-flex gap-2">
                                                {" "}
                                                <span
                                                    className="edit_icon iconsWidthAdjust"
                                                    title="View"
                                                    onClick={() => View(itm.id)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </span>
                                                <span className="edit_icon " onClick={(e) => edit(itm.id)}>
                                                    <i class="material-icons edit" title="Edit">
                                                        edit
                                                    </i>
                                                </span>
                                                <span
                                                    className="edit_icon iconsWidthAdjust"
                                                    title="Delete"
                                                    onClick={() => {
                                                        setDeleteId(itm.id);
                                                        setShowDeleteModal("block");
                                                    }}
                                                >
                                                    <i class="material-icons delete" title="Delete">
                                                        {" "}
                                                        delete
                                                    </i>
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* {total == 0 ? <div className="py-3 text-center">No Data</div> : <></>} */}
                {loading ? <h3 className="text-center mt-2">Loading...</h3> : ProjectsData.length == 0 ? (
                    <div className="text-danger text-center">No Data</div>
                ) : null}
                {total > filters.count ? (
                    <div className="paginationWrapper">
                        <span>
                            Show {filters.count} from {total} Contract
                        </span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </Layout>
        </>
    );
};
export default EmployerContract;
