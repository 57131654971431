 

const monthArray = [
    { value: 'Jan', id: 0 },
    { value: 'Feb', id: 1 },
    { value: 'Mar', id: 2 },
    { value: 'Apr', id: 3 },
    { value: 'May', id: 4 },
    { value: 'Jun', id: 5 },
    { value: 'Jul', id: 6 },
    { value: 'Aug', id: 7 },
    { value: 'Sep', id: 8 },
    { value: 'Oct', id: 9 },
    { value: 'Nov', id: 10 },
    { value: 'Dec', id: 11 }
]


const monthfind = (id) => {
    let value = 0
    let ext = monthArray.find(itm => itm.id === id)
    if (ext) value = ext.value
    return value
}

const getWeekDate = (date = new Date(), week = 1) => {
    let d = new Date(date)
    let month = d.getMonth()
    month = String(month + 1)

    let value = 1
    if (week == 1) value = 1
    else if (week > 1) value = 7 * (week - 1)
    if (value > 30) value = 30

    return new Date(`${d.getFullYear()}-${month}-${value}`)
}

const date = (p) => {
    if(!p) return '--'
    let d = new Date(p)
    return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`
}

const stringToDate=(p)=>{
    let d=''
    if(p){
        d=new Date(p)
    }
    return d
}

const daysArray = [
    { value: 'Monday', id: 1 },
    { value: 'Tuesday', id: 2 },
    { value: 'Wednesday', id: 3 },
    { value: 'Thursday', id: 4 },
    { value: 'Friday', id: 5 },
    { value: 'Saturday', id: 6 },
    { value: 'Sunday', id: 0 },
]

const getDayName = (id) => {
    let value = daysArray.find((itm) => itm.id === id).value
    return value;
}

const day = (p) => {
    if (!p) return ''
    let day = new Date(p).getDay()
    day = getDayName(day)
    return day
}

const time = (p) => {
    if (!p) return ''
    let v = p.split('T')
    if (v.length === 2) {
        return new Date(p).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    } else {
        return new Date(`2020-07-19 ${p}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
}

const datetime = (p) => {
    return `${date(p)}, ${time(p)}`
}

const timeString = (p) => {
    let date = new Date(p)
    let hr = String(date.getHours())
    let minutes = String(date.getMinutes())
    if (hr.length === 1) hr = `0${hr}`
    if (minutes.length === 1) minutes = `0${minutes}`
    return `${hr}:${minutes}:00`
}

const datetostring = (p) => {
    if (!p) return ''
    let date = new Date(p)
    let month = String(date.getMonth() + 1)
    let d = String(date.getDate())
    if (month.length === 1) month = `0${month}`
    if (d.length === 1) d = `0${d}`
    return `${date.getFullYear()}-${month}-${d}`
}

const datetoIso = (p) => {
    if (!p) return ''
    let date = datetostring(p)

    let value = `${date}T09:00:00.000Z`
    return value
}

const datetoIsotime = (p) => {
    let date = datetostring(p)
    let time = timeString(p)
    let value = `${date}T${time}.000Z`
    return value
}

const isototime = (p) => {
    if (!p) return ''
    let value = p.split('T')[1].split('.000Z')
    if (value.length === 2) {
    } else {
        value = p.split('T')[1].split('+')
    }
    return value[0]
}

const isotime = (p) => {
    return time(isototime(p))
}

const isotodate = (p) => {
    let d = new Date(`${datetostring(p)} ${isototime(p)}`)
    return d
}

const DaysNo = (s, e) => {
    // JavaScript program to illustrate 
    // calculation of no. of days between two date 

    // To set two dates to two variables
    var date1 = new Date(s);
    var date2 = new Date(e);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days + 1
}

const TimeConvertToTimezone=(userDate,userTimezone)=>{
    const dateString = userDate

    console.error(dateString)
    // Extract the components from the string
    const [dayOfWeek, month, day, year, time, gmtOffset] = dateString.split(' ');
    const [hours, minutes, seconds] = time.split(':');
    // const dateString = 'Thu Aug 22 2024 15:00:00 GMT+0530 (India Standard Time)';
    // Map month names to numbers
    const monthMap = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
        Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };

    const monthNumber = monthMap[month];

    // Format the ISO date string
    const isoString = `${year}-${monthNumber}-${day}T${hours}:${minutes}:${seconds}Z`;
    console.log(isoString); // Outputs: 2024-08-22T14:00:00Z
    // const userInput = new Date(userDate).getMilliseconds();

    // // Convert the input to Pacific Time explicitly
    // const pacificTime = moment(userInput).tz(userTimezone);

    // // Convert Pacific Time to UTC
    // const utcTime = pacificTime.format();

    return isoString
}

const ReverseTimetoObj = (dateString) => {
    const localDate = new Date(dateString);
    // Extract the offset in minutes from the local time zone
    const localOffset = localDate.getTimezoneOffset(); // in minutes
    // Create a new Date object in UTC
    const utcDate = new Date(localDate.getTime() + localOffset * 60000);
    return utcDate;
}

const datepipeModel = {
    DaysNo, 
    TimeConvertToTimezone,
    ReverseTimetoObj,
    date,
    stringToDate,
    day,
    datetostring,
    datetoIso,
    isototime,
    isotodate,
    isotime,
    datetoIsotime,
    time,
    timeString,
    datetime,
    monthfind,
    getWeekDate
}

export default datepipeModel;
