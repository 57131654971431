import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
// import './style.scss';
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import loader from "../../methods/loader";
import moment from "moment";
// import { dataSymbolTask } from 'echarts/types/src/visual/symbol.js';

const ContractDetail = (p) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [data, setData] = useState();
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`contract`, { id: did }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  return (
    <Layout>
      <div className="heightVH">
        {" "}
        <div className="normalFlex mb-3 align-items-center">
          {/* <button to="/blog" onClick={back} className='btn btn-primary d-flex gap-1 align-items-center'>
                  <i className="fa fa-angle-double-left text-white " title='Back' aria-hidden="true"></i><span>Back</span>
                  </button> */}
          <div class="d-flex gap-1 align-items-center ">
            {" "}
            <i
              onClick={back}
              class="fa fa-angle-double-left fontBack text-dark "
              title="Back"
            ></i>{" "}
            <h3 class="hedding">
              Manual Additions Detail
            </h3>
          </div>


          <div>
            <button
              onClick={(e) => history.push(`/edit/contract/${id}`)}
              className="btn btn-primary d-flex gap-1 align-items-center"
            >
              <i class="material-icons edit text-white " title="Edit">
                edit{" "}
              </i>
              <span>Edit</span>
            </button>
          </div>
        </div>
        <div className="sideclass  pprofile1">
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Contract Name</label>
              <div className="profiledetailscls">
                {(data && data?.projectId?.name) || data?.contractName || "---"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Weekly Limit</label>
              <div className="profiledetailscls">
                {(data && data?.weeklyLimit) || "0"} hrs
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Virtual Name</label>
              <div className="profiledetailscls">
                {(data && data?.contractTo.fullName) || "---"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Virtual Hourly Rate</label>
              <div className="profiledetailscls">
                {(data && data?.hourlyRate) || "0"} USD
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Employer Name</label>
              <div className="profiledetailscls">
                {(data && data?.contractFrom?.fullName) || "---"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Employer Hourly Fee</label>
              <div className="profiledetailscls">
                {(data && data?.employerHourlyRate) || "0"} USD
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Total Amount</label>
              <div className="profiledetailscls">
                {data?.totalAmount ? `${data?.totalAmount} USD` : "--"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Employer Recruiter</label>
              <div className="profiledetailscls text-capitalize">
                {data?.employerRecruiterDetails?.fullName || data?.employerRecruiterDetails?.firstName || "--"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Virtual Assistant Recruiter</label>
              <div className="profiledetailscls text-capitalize">
                {data?.virtualRecruiterDetail?.fullName || data?.virtualRecruiterDetail?.firstName || "--"}
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Start Date</label>
              <div className="profiledetailscls">
                {(data && moment(data?.startDate).format("MM/DD/YYYY")) ||
                  "---"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Time Zone</label>
              <div className="profiledetailscls">
                {(data && data?.timeZone?.value) || "---"}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="profileheddingcls">Description</label>
              <div className="profiledetailscls">
                {(data && data?.description) || "---"}
              </div>
            </div>
            <div className="col-md-12 my-3">
              <h5 className="">Time Table</h5>
              <div className="table-responsive cont_table mb-3">
                {" "}
                <table className="table ">
                  <thead>
                    <tr>
                      <th>Day</th>
                      {data?.timeTable?.map((entry) => (
                        <th key={entry.day}>{entry.day}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <span className="pt-2 d-block">Start Time</span>{" "}
                      </td>
                      {data?.timeTable?.map((entry) => (
                        <td key={entry.day}>
                          <input
                            disabled
                            value={entry.startTime || "Off"}
                            className="form-control widform"
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span className="pt-2 d-block">Lunch Break</span>
                      </td>
                      {data?.timeTable?.map((entry) => (
                        <td key={entry?.day}>
                          <input
                            disabled
                            value={entry.lunchBreak || "Off"}
                            className="form-control widform"
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>
                        <span className="pt-2 d-block">End Time </span>{" "}
                      </td>
                      {data?.timeTable?.map((entry) => (
                        <td key={entry.day}>
                          <input
                            disabled
                            value={entry.endTime || "Off"}
                            className="form-control widform"
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContractDetail;
