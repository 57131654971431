import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from 'react-router';
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import { Select } from 'react-select';
import Currency from './../CurrencyPages/index';


const Vapayment = () => {
    const [form, setform] = useState({})
    const history = useHistory()
    let param = new URLSearchParams(window.location.search);
    const contract_Id = param.get("contractId")
    const start_Date = param.get("start_Date")
    const end_Date = param.get("end_Date")
    const totalDurationOfTime_hour1 = param.get("totalDurationOfTime_hour1")
    const totalDurationOfTime_min1 = param.get("totalDurationOfTime_min1")
    const totalBillingAmount = param.get("totalBillingAmount")
    const name = param.get("name")
    const HandleSubmit = (e) => {
        e.preventDefault()
        if (form?.buyCurrency && form?.sellCurrency && totalBillingAmount < 5) {
            return toast.error("Can't exchange money because your money is low")
        }
        let payload = {
            ...form,
            buyAmount: totalBillingAmount
        }
        // return
        loader(true)
        ApiClient.post(`create-paymentquote`, payload).then((res) => {
            if (res.success) {
                loader(false)
                toast.success("Payment Quote added successfully")
                history.push(`/bank/detail?id=${res?.data?.quoteId?.id}&token=${res?.data?.accessToken}&contractId=${contract_Id}&start_Date=${start_Date}&end_Date=${end_Date}&name=${name}&totalDurationOfTime_hour1=${totalDurationOfTime_hour1}&totalDurationOfTime_min1=${totalDurationOfTime_min1}&totalBillingAmount=${totalBillingAmount}`)

            }
        })
    }

    return <>
        <Layout>

            <div className="heightVH">
                

            <div className="d-flex gap-1 align-items-center mb-3"> 
        <i onClick={e => history.goBack()} className="fa fa-angle-double-left fontBack " title='Back' aria-hidden="true"></i>

                   <h3 className="hedding">
                   Add Payment Quot
                    </h3>
                      </div>
                
                 <form
                onSubmit={e => HandleSubmit(e)}
            >
                <div className="pprofile1">
                 
                    <div className="form-row">

                        <div className="col-md-6 mb-3">
                            <label>
                                Currency (Buy)
                                <span className="star">*</span>
                            </label>
                            {/* <input
                                type="text"
                                className="form-control"
                                name="buyCurrency"
                                value={form?.buyCurrency?.toUpperCase()}
                                onChange={e => setform({ ...form, buyCurrency: e.target.value })}
                                required
                            /> */}
                            <select required name="buyCurrency" value={form?.buyCurrency} onChange={e => setform({ ...form, buyCurrency: e.target.value })} className="form-select form-control" id="currency" >
                                <option value="">Select Currency</option>
                                <option value="AFN">AFN</option>
                                <option value="ALL">ALL</option>
                                <option value="DZD">DZD</option>
                                <option value="AOA">AOA</option>
                                <option value="ARS">ARS</option>
                                <option value="AMD">AMD</option>
                                <option value="AWG">AWG</option>
                                <option value="AUD">AUD</option>
                                <option value="AZN">AZN</option>
                                <option value="BSD">BSD</option>
                                <option value="BHD">BHD</option>
                                <option value="BDT">BDT</option>
                                <option value="BBD">BBD</option>
                                <option value="BYR">BYR</option>
                                <option value="BEF">BEF</option>
                                <option value="BZD">BZD</option>
                                <option value="BMD">BMD</option>
                                <option value="BTN">BTN</option>
                                <option value="BTC">BTC</option>
                                <option value="BOB">BOB</option>
                                <option value="BAM">BAM</option>
                                <option value="BWP">BWP</option>
                                <option value="BRL">BRL</option>
                                <option value="GBP">GBP</option>
                                <option value="BND">BND</option>
                                <option value="BGN">BGN</option>
                                <option value="BIF">BIF</option>
                                <option value="KHR">KHR</option>
                                <option value="CAD">CAD</option>
                                <option value="CVE">CVE</option>
                                <option value="KYD">KYD</option>
                                <option value="XOF">XOF</option>
                                <option value="XAF">XAF</option>
                                <option value="XPF">XPF</option>
                                <option value="CLP">CLP</option>
                                <option value="CNY">CNY</option>
                                <option value="COP">COP</option>
                                <option value="KMF">KMF</option>
                                <option value="CDF">CDF</option>
                                <option value="CRC">CRC</option>
                                <option value="HRK">HRK</option>
                                <option value="CUC">CUC</option>
                                <option value="CZK">CZK</option>
                                <option value="DKK">DKK</option>
                                <option value="DJF">DJF</option>
                                <option value="DOP">DOP</option>
                                <option value="XCD">XCD</option>
                                <option value="EGP">EGP</option>
                                <option value="ERN">ERN</option>
                                <option value="EEK">EEK</option>
                                <option value="ETB">ETB</option>
                                <option value="EUR">EUR</option>
                                <option value="FKP">FKP</option>
                                <option value="FJD">FJD</option>
                                <option value="GMD">GMD</option>
                                <option value="GEL">GEL</option>
                                <option value="DEM">DEM</option>
                                <option value="GHS">GHS</option>
                                <option value="GIP">GIP</option>
                                <option value="GRD">GRD</option>
                                <option value="GTQ">GTQ</option>
                                <option value="GNF">GNF</option>
                                <option value="GYD">GYD</option>
                                <option value="HTG">HTG</option>
                                <option value="HNL">HNL</option>
                                <option value="HKD">HKD</option>
                                <option value="HUF">HUF</option>
                                <option value="ISK">ISK</option>
                                <option value="INR">INR</option>
                                <option value="IDR">IDR</option>
                                <option value="IRR">IRR</option>
                                <option value="IQD">IQD</option>
                                <option value="ILS">ILS</option>
                                <option value="ITL">ITL</option>
                                <option value="JMD">JMD</option>
                                <option value="JPY">JPY</option>
                                <option value="JOD">JOD</option>
                                <option value="KZT">KZT</option>
                                <option value="KES">KES</option>
                                <option value="KWD">KWD</option>
                                <option value="KGS">KGS</option>
                                <option value="LAK">LAK</option>
                                <option value="LVL">LVL</option>
                                <option value="LBP">LBP</option>
                                <option value="LSL">LSL</option>
                                <option value="LRD">LRD</option>
                                <option value="LYD">LYD</option>
                                <option value="LTL">LTL</option>
                                <option value="MOP">MOP</option>
                                <option value="MKD">MKD</option>
                                <option value="MGA">MGA</option>
                                <option value="MWK">MWK</option>
                                <option value="MYR">MYR</option>
                                <option value="MVR">MVR</option>
                                <option value="MRO">MRO</option>
                                <option value="MUR">MUR</option>
                                <option value="MXN">MXN</option>
                                <option value="MDL">MDL</option>
                                <option value="MNT">MNT</option>
                                <option value="MAD">MAD</option>
                                <option value="MZM">MZM</option>
                                <option value="MMK">MMK</option>
                                <option value="NAD">NAD</option>
                                <option value="NPR">NPR</option>
                                <option value="ANG">ANG</option>
                                <option value="TWD">TWD</option>
                                <option value="NZD">NZD</option>
                                <option value="NIO">NIO</option>
                                <option value="NGN">NGN</option>
                                <option value="KPW">KPW</option>
                                <option value="NOK">NOK</option>
                                <option value="OMR">OMR</option>
                                <option value="PKR">PKR</option>
                                <option value="PAB">PAB</option>
                                <option value="PGK">PGK</option>
                                <option value="PYG">PYG</option>
                                <option value="PEN">PEN</option>
                                <option value="PHP">PHP</option>
                                <option value="PLN">PLN</option>
                                <option value="QAR">QAR</option>
                                <option value="RON">RON</option>
                                <option value="RUB">RUB</option>
                                <option value="RWF">RWF</option>
                                <option value="SVC">SVC</option>
                                <option value="WST">WST</option>
                                <option value="SAR">SAR</option>
                                <option value="RSD">RSD</option>
                                <option value="SCR">SCR</option>
                                <option value="SLL">SLL</option>
                                <option value="SGD">SGD</option>
                                <option value="SKK">SKK</option>
                                <option value="SBD">SBD</option>
                                <option value="SOS">SOS</option>
                                <option value="ZAR">ZAR</option>
                                <option value="KRW">KRW</option>
                                <option value="XDR">XDR</option>
                                <option value="LKR">LKR</option>
                                <option value="SHP">SHP</option>
                                <option value="SDG">SDG</option>
                                <option value="SRD">SRD</option>
                                <option value="SZL">SZL</option>
                                <option value="SEK">SEK</option>
                                <option value="CHF">CHF</option>
                                <option value="SYP">SYP</option>
                                <option value="STD">STD</option>
                                <option value="TJS">TJS</option>
                                <option value="TZS">TZS</option>
                                <option value="THB">THB</option>
                                <option value="TOP">TOP</option>
                                <option value="TTD">TTD</option>
                                <option value="TND">TND</option>
                                <option value="TRY">TRY</option>
                                <option value="TMT">TMT</option>
                                <option value="UGX">UGX</option>
                                <option value="UAH">UAH</option>
                                <option value="AED">AED</option>
                                <option value="UYU">UYU</option>
                                <option value="USD">USD</option>
                                <option value="UZS">UZS</option>
                                <option value="VUV">VUV</option>
                                <option value="VEF">VEF</option>
                                <option value="VND">VND</option>
                                <option value="YER">YER</option>
                                <option value="ZMK">ZMK</option>
                            </select>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>
                                Buy Amount
                                <span className="star">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="buyAmount"
                                value={parseFloat(totalBillingAmount).toFixed(2)}
                                // onChange={e => setform({ ...form, buyAmount: e.target.value })}
                                disabled
                            />
                        </div>


                        <div className="col-md-6 mb-3">
                            <label>
                                Currency (Sell)
                                <span className="star">*</span>
                            </label>
                            {/* <input
                                type="text"
                                className="form-control"
                                name="sellCurrency"
                                value={form?.sellCurrency?.toUpperCase()}
                                onChange={e => setform({ ...form, sellCurrency: e.target.value })}
                                required
                            /> */}
                            <select required name="sellCurrency" value={form?.sellCurrency} onChange={e => setform({ ...form, sellCurrency: e.target.value })} className="form-select form-control" id="currency" >
                                <option value="">Select Currency</option>
                                <option value="AFN">AFN</option>
                                <option value="ALL">ALL</option>
                                <option value="DZD">DZD</option>
                                <option value="AOA">AOA</option>
                                <option value="ARS">ARS</option>
                                <option value="AMD">AMD</option>
                                <option value="AWG">AWG</option>
                                <option value="AUD">AUD</option>
                                <option value="AZN">AZN</option>
                                <option value="BSD">BSD</option>
                                <option value="BHD">BHD</option>
                                <option value="BDT">BDT</option>
                                <option value="BBD">BBD</option>
                                <option value="BYR">BYR</option>
                                <option value="BEF">BEF</option>
                                <option value="BZD">BZD</option>
                                <option value="BMD">BMD</option>
                                <option value="BTN">BTN</option>
                                <option value="BTC">BTC</option>
                                <option value="BOB">BOB</option>
                                <option value="BAM">BAM</option>
                                <option value="BWP">BWP</option>
                                <option value="BRL">BRL</option>
                                <option value="GBP">GBP</option>
                                <option value="BND">BND</option>
                                <option value="BGN">BGN</option>
                                <option value="BIF">BIF</option>
                                <option value="KHR">KHR</option>
                                <option value="CAD">CAD</option>
                                <option value="CVE">CVE</option>
                                <option value="KYD">KYD</option>
                                <option value="XOF">XOF</option>
                                <option value="XAF">XAF</option>
                                <option value="XPF">XPF</option>
                                <option value="CLP">CLP</option>
                                <option value="CNY">CNY</option>
                                <option value="COP">COP</option>
                                <option value="KMF">KMF</option>
                                <option value="CDF">CDF</option>
                                <option value="CRC">CRC</option>
                                <option value="HRK">HRK</option>
                                <option value="CUC">CUC</option>
                                <option value="CZK">CZK</option>
                                <option value="DKK">DKK</option>
                                <option value="DJF">DJF</option>
                                <option value="DOP">DOP</option>
                                <option value="XCD">XCD</option>
                                <option value="EGP">EGP</option>
                                <option value="ERN">ERN</option>
                                <option value="EEK">EEK</option>
                                <option value="ETB">ETB</option>
                                <option value="EUR">EUR</option>
                                <option value="FKP">FKP</option>
                                <option value="FJD">FJD</option>
                                <option value="GMD">GMD</option>
                                <option value="GEL">GEL</option>
                                <option value="DEM">DEM</option>
                                <option value="GHS">GHS</option>
                                <option value="GIP">GIP</option>
                                <option value="GRD">GRD</option>
                                <option value="GTQ">GTQ</option>
                                <option value="GNF">GNF</option>
                                <option value="GYD">GYD</option>
                                <option value="HTG">HTG</option>
                                <option value="HNL">HNL</option>
                                <option value="HKD">HKD</option>
                                <option value="HUF">HUF</option>
                                <option value="ISK">ISK</option>
                                <option value="INR">INR</option>
                                <option value="IDR">IDR</option>
                                <option value="IRR">IRR</option>
                                <option value="IQD">IQD</option>
                                <option value="ILS">ILS</option>
                                <option value="ITL">ITL</option>
                                <option value="JMD">JMD</option>
                                <option value="JPY">JPY</option>
                                <option value="JOD">JOD</option>
                                <option value="KZT">KZT</option>
                                <option value="KES">KES</option>
                                <option value="KWD">KWD</option>
                                <option value="KGS">KGS</option>
                                <option value="LAK">LAK</option>
                                <option value="LVL">LVL</option>
                                <option value="LBP">LBP</option>
                                <option value="LSL">LSL</option>
                                <option value="LRD">LRD</option>
                                <option value="LYD">LYD</option>
                                <option value="LTL">LTL</option>
                                <option value="MOP">MOP</option>
                                <option value="MKD">MKD</option>
                                <option value="MGA">MGA</option>
                                <option value="MWK">MWK</option>
                                <option value="MYR">MYR</option>
                                <option value="MVR">MVR</option>
                                <option value="MRO">MRO</option>
                                <option value="MUR">MUR</option>
                                <option value="MXN">MXN</option>
                                <option value="MDL">MDL</option>
                                <option value="MNT">MNT</option>
                                <option value="MAD">MAD</option>
                                <option value="MZM">MZM</option>
                                <option value="MMK">MMK</option>
                                <option value="NAD">NAD</option>
                                <option value="NPR">NPR</option>
                                <option value="ANG">ANG</option>
                                <option value="TWD">TWD</option>
                                <option value="NZD">NZD</option>
                                <option value="NIO">NIO</option>
                                <option value="NGN">NGN</option>
                                <option value="KPW">KPW</option>
                                <option value="NOK">NOK</option>
                                <option value="OMR">OMR</option>
                                <option value="PKR">PKR</option>
                                <option value="PAB">PAB</option>
                                <option value="PGK">PGK</option>
                                <option value="PYG">PYG</option>
                                <option value="PEN">PEN</option>
                                <option value="PHP">PHP</option>
                                <option value="PLN">PLN</option>
                                <option value="QAR">QAR</option>
                                <option value="RON">RON</option>
                                <option value="RUB">RUB</option>
                                <option value="RWF">RWF</option>
                                <option value="SVC">SVC</option>
                                <option value="WST">WST</option>
                                <option value="SAR">SAR</option>
                                <option value="RSD">RSD</option>
                                <option value="SCR">SCR</option>
                                <option value="SLL">SLL</option>
                                <option value="SGD">SGD</option>
                                <option value="SKK">SKK</option>
                                <option value="SBD">SBD</option>
                                <option value="SOS">SOS</option>
                                <option value="ZAR">ZAR</option>
                                <option value="KRW">KRW</option>
                                <option value="XDR">XDR</option>
                                <option value="LKR">LKR</option>
                                <option value="SHP">SHP</option>
                                <option value="SDG">SDG</option>
                                <option value="SRD">SRD</option>
                                <option value="SZL">SZL</option>
                                <option value="SEK">SEK</option>
                                <option value="CHF">CHF</option>
                                <option value="SYP">SYP</option>
                                <option value="STD">STD</option>
                                <option value="TJS">TJS</option>
                                <option value="TZS">TZS</option>
                                <option value="THB">THB</option>
                                <option value="TOP">TOP</option>
                                <option value="TTD">TTD</option>
                                <option value="TND">TND</option>
                                <option value="TRY">TRY</option>
                                <option value="TMT">TMT</option>
                                <option value="UGX">UGX</option>
                                <option value="UAH">UAH</option>
                                <option value="AED">AED</option>
                                <option value="UYU">UYU</option>
                                <option value="USD">USD</option>
                                <option value="UZS">UZS</option>
                                <option value="VUV">VUV</option>
                                <option value="VEF">VEF</option>
                                <option value="VND">VND</option>
                                <option value="YER">YER</option>
                                <option value="ZMK">ZMK</option>
                            </select>
                        </div>



                    </div>

                    <div className="text-right">
                        {/* <button type="button" className="btn btn-secondary mr-2" onClick={e => history.goBack()} >Back</button> */}
                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>
                    </div>

                </div>
            </form></div>
        </Layout>

    </>
}
export default Vapayment;