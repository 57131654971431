import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ApiClient from '../../methods/api/apiClient';
import Layout from '../../components/global/layout';
import loader from '../../methods/loader';
import moment from 'moment';
import methodModel from '../../methods/methods';

const EmpContractDetail = (p) => {
    const history = useHistory()
    const { id, userId } = useParams()
    const [data, setData] = useState()


    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`contract`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    };

    const back = () => {
        history.goBack()
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
    }, [id, userId])

    return (
        <Layout>
            <div className='heightVH'>        <h3 className="hedding d-flex gap-1 align-items-center mb-3" >
                <a to="/blog" onClick={back}>
                    <i className="fa fa-angle-double-left fontBack" title='Back' aria-hidden="true"></i>
                </a>
                Automated Additions Detail
            </h3>



                <div className="sideclass pprofile1">

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Contract Name</label>
                            <div className='profiledetailscls'>{data && data?.projectId?.name || data?.contractName || "---"}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Weekly Limit</label>
                            <div className='profiledetailscls'>{data && data?.weeklyLimit || "0"}{" "}hrs</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Virtual Name</label>
                            <div className='profiledetailscls'>{data && data?.contractTo.fullName || "---"}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Virtual Hourly Rate</label>
                            <div className='profiledetailscls'>{data && data?.hourlyRate || "0"}{" "}USD</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Employer Name</label>
                            <div className='profiledetailscls'>{data && data?.contractFrom?.fullName || "---"}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Employer Hourly Fee</label>
                            <div className='profiledetailscls'>{data && data?.employerHourlyRate || "0"}{" "}USD</div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Start Date</label>
                            <div className='profiledetailscls'>{data && methodModel.convertDate(data?.createdAt) || "---"}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Description</label>
                            <div className='profiledetailscls' dangerouslySetInnerHTML={{ __html: data && data?.projectId?.description || "---" }}></div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Time Zone</label>
                            <div className='profiledetailscls'>{data && data?.timeZone?.value || "---"}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Contract Title</label>
                            <div className='profiledetailscls'>{data && data?.title || data?.contractName || "---"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='profileheddingcls'>Skills</label>
                                <br />
                                {data && data?.projectId?.skills?.map((item) => (<span className="bg_bages">{item?.name || "---"}</span>))}
                            </div>
                        </div>


                        <div className="col-md-12 mb-3">
                            <label className="profileheddingcls">Time Table</label>
                            <div className="table-responsive cont_table">
                                {" "}
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            {data?.timeTable?.map((entry) => (
                                                <th key={entry.day}>{entry.day}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <span className="pt-2 d-block">Start Time</span> </td>
                                            {data?.timeTable?.map((entry) => (
                                                <td key={entry.day}>
                                                    <input
                                                        disabled
                                                        value={entry.startTime || "Off"}
                                                        className="form-control widform"

                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td> <span className="pt-2 d-block">Lunch Break</span></td>
                                            {data?.timeTable?.map((entry) => (
                                                <td key={entry?.day}>
                                                    <input
                                                        disabled
                                                        value={entry.lunchBreak || "Off"}
                                                        className="form-control widform"

                                                    />

                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td><span className="pt-2 d-block">End Time </span> </td>
                                            {data?.timeTable?.map((entry) => (
                                                <td key={entry.day}>
                                                    <input
                                                        disabled
                                                        value={entry.endTime || "Off"}
                                                        className="form-control widform"

                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>

    );
};

export default EmpContractDetail;
