import { Children, useEffect, useRef, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CFormLabel } from "@coreui/react";

const Payment = () => {
  const [BankAccountSelector, setBankAccountSelector] = useState(null);
  const [BankList, setBankList] = useState([]);
  const [ContractDetails, setContractDetails] = useState({});
  const Navigate = useHistory();
  const [PaymentToPay, setPaymentToPay] = useState(0);
  const Searchparams = new URLSearchParams(window.location.search);
  const Contract_Id = Searchparams.get(`contractid`);
  const user = useSelector((state) => state.user);
  const [allCardsData, setAllCardsData] = useState([]);
  const PrimaryCardRef = useRef(null);
  const [paymentMethod, setPaymentMethod] = useState("card"); // Added state for payment method
  const [Loading, setLoading] = useState(false);
  const referrer = document.referrer.toLowerCase();
  const [price, setprice] = useState();
  const [amount, setamount] = useState();
  const [list, setlist] = useState([]);
  const [totalamount, settotalamount] = useState([]);
  const getCards = () => {
    ApiClient.get(`cards`, { userId: user.id }).then((res) => {
      if (!res.length) {
        Navigate.push("/savedcard");
        toast.error("Please add your payment method ");
      }
      setAllCardsData(res);
      res?.some((item) =>
        item.isDefault == true ? (PrimaryCardRef.current = item) : null
      );
    });
  };


  const handleMakePrimaryCard = (e, index, itemData = {}) => {
    setBankAccountSelector(null);
    PrimaryCardRef.current = itemData;
    loader(true);
    const data = allCardsData.map((item, i) => ({
      ...item,
      isDefault: i === index,
    }));
    setAllCardsData([...data]);
    loader(false);
  };

  useEffect(() => {
    getCards();
  }, []);

  const HandlePaymentSubmit = (e) => {
    e.preventDefault();

    let url = "";
    let Payload = {
      // We are commenting this line Because of Server Needs
      payPrice: calculated(),
      contractId: Contract_Id,
      virtualId: user.id,
      // customer_id: user.customer_id,
      cardId: PrimaryCardRef.current.card_id,
      // accountId: BankAccountSelector,
      listOfIds: [
        ...list
      ]

    };
    if (paymentMethod == "card") {
      url = "card/admin-pay-virtual";
      delete Payload.accountId;
    } else {
      url = "CreatePaymentIntent";
      Payload = {
        source_accountId: BankAccountSelector,
        customerId: user.customer_id,
        payAmount: calculated(),
      };
    }
    setLoading(true);
    const loadingToastId = toast.loading(
      "Your Payment is Processing please wait",
      {
        hideProgressBar: false,
        bodyStyle: { background: "black", color: "white" },
        style: { background: "black" },
        progressStyle: { color: "red" },
      }
    );
    ApiClient.post(url, Payload).then((res) => {
      if (res.success) {
        toast.success("Payment Proccessed successfully");
        ApiClient.put("contract", {
          id: Contract_Id,
          isContractPayment: true,
          isWeeklyPayment: true,
        }).then((res) => {
          if (res.success) {
            try {
              if (referrer.includes("zoho")) {
                Navigate.push("/perposallist");
              } else {
                Navigate.goBack();
              }
            } catch {
              Navigate.goBack("/contracts");
            }
          }
        });
        // Navigate.push('/perposallist')
      }
      setLoading(false);
      toast.dismiss(loadingToastId);
    });
  };

  const GetContractDetail = () => {
    loader(true);
    ApiClient.get(`contract?id=${Contract_Id}`).then((res) => {
      if (res.success) {
        setContractDetails(res.data);
        // const data = res.data;
      }
      loader(false);
    });
  };
  // http://66.179.251.9:6040/billing-amount?contractId=66177c73fc63e2575bde4a1e
  // const getprice = () => {
  //   loader(true);
  //   ApiClient.get(`billing-amount?contractId=${Contract_Id}`).then((res) => {
  //     if (res.success) {
  //       setprice(res?.data); 
  //       // const data = res.data;
  //     }
  //     loader(false);
  //   });
  // };

  const calculated = () => {
    let totala = 0;

    totalamount.map((item => {
      totala += item.totalHourlyAmount

    }))
    return parseFloat(totala).toFixed(2)
  }


  useEffect(() => {
    getAmount();
    // alert("he")

  }, [])
  const getAmount = () => {
    loader(true);
    ApiClient.get(`billing-amount?contractId=${Contract_Id}`).then((res) => {
      if (res.success) {
        settotalamount(res?.data)
        setlist(res?.listOfIds)
        calculated();

      }
      loader(false);
    });
  }


  useEffect(() => {
    GetContractDetail();
  }, [Contract_Id]);

  const GetBankAccounts = () => {
    loader(true);
    ApiClient.get(`banks`).then((res) => {
      if (res.success) {
        setBankList(res?.data);
      }
      loader(false);
    });

  };

  useEffect(() => {
    GetBankAccounts();

  }, []);

  const BankAccountSelectorHandler = (id) => {
    setBankAccountSelector(id);
  };

  useEffect(() => {
    ApiClient.get(
      `retainer/list?virtualId=&employerId=${user.id}&contractId=${Contract_Id}`
    ).then((res) => {
      if (res.success) {
        setPaymentToPay(res?.data[0]?.retainerAmount || 0);
      }
    });
  }, []);

  return (
    <section style={{ backgroundColor: "#eee" }}>
      <div className="container pt-5 justify-content-center align-items-center vh-100">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6">
            <div className="card rounded-3">
              <div className="text-center d-flex justify-content-center pt-3">
                <ul className="nav nav-tabs" id="myTabs">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${paymentMethod == "card" ? "active" : ""
                        }`}
                      id="tab1-tab"
                      data-toggle="tab"
                      onClick={(e) => setPaymentMethod("card")}
                      href="#tab1"
                    >
                      Credit/Debit Card
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${paymentMethod == "bank" ? "active" : ""
                        }`}
                      id="tab2-tab"
                      onClick={(e) => setPaymentMethod("bank")}
                    >
                      ACH Payment
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body mx-1 my-2">
                {paymentMethod == "card" ? (
                  <>
                    <h6 className="text-center">Your Cards</h6>
                    {allCardsData.map((item, index) => (
                      <div className="cards_heaight">
                        {" "}
                        <div
                          key={index}
                          className="d-flex align-items-center mb-3"
                        >
                          <div className="d-flex">
                            <input
                              type="radio"
                              checked={item.isDefault}
                              name="payment"
                              id={`${item.id}`}
                              width={20}
                              height={30}
                              onChange={(e) =>
                                handleMakePrimaryCard(e, index, item)
                              }
                              style={{ width: "12px" }}
                              className="mr-2"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor={item.id}
                            >
                              <img
                                src={
                                  item.brand === "Visa"
                                    ? `/assets/img/visa.png`
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5bzxju2tDukq4cs8OdLb93qweBo852s3JjxXaJI1uA&s"
                                }
                                className="visa"
                                alt=""
                              />
                            </label>
                          </div>
                          <div className="mx-2">
                            <p className="d-flex flex-column mb-0">
                              <b>**********{item?.last4}</b>
                              <span className="small text-muted">
                                ending in{" "}
                                {moment(
                                  `${item.exp_month}-01-${item?.exp_year}`
                                ).format("MMM,YYYY")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <h6 className="text-center">Your Bank Accounts</h6>
                    <div className="cards_heaight">
                      {" "}
                      {BankList.length == 0 ? (
                        <p className="text-center text-danger">
                          No bank Accounts
                        </p>
                      ) : (
                        BankList.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center mb-3"
                          >
                            <div className="d-flex">
                              <input
                                type="radio"
                                checked={
                                  BankAccountSelector === item.account_id
                                }
                                name="bank"
                                id={`${item.id}`}
                                width={20}
                                height={30}
                                onChange={(e) =>
                                  BankAccountSelectorHandler(item?.account_id)
                                }
                                style={{ width: "12px" }}
                                className="mr-2"
                              />
                            </div>
                            <label
                              className="mt-1"
                              style={{ cursor: "pointer" }}
                              htmlFor={item.id}
                            >
                              <div className="mx-2 form-group border-2 border-black">
                                <p className="d-flex flex-column mb-0">
                                  {item?.bank_name}
                                  <b>**********{item?.last4}</b>
                                  <span className="small text-muted">
                                    Account Holder:{item?.account_holder_name}
                                  </span>
                                </p>
                              </div>
                            </label>
                          </div>
                        ))
                      )}
                    </div>
                  </>
                )}

                <div className="pt-3">
                  <div className="d-flex justify-content-between ">
                    <div className="d-flex">
                      <div className="d-flex align-items-center pe-3"></div>
                      <div className="d-flex flex-column">
                        <p className="mb-1 payment_mod_total mb-2">
                          Total amount due
                        </p>
                        <h6 className="payment_mod_usd d-flex w-50">
                          USD{" "}
                          <input
                            className="form-control ml-3"
                            style={{ marginTop: "-7px" }}
                            disabled
                            // value={PaymentToPay}
                            // onChange={(e) => setPaymentToPay(e.target.value)}

                            value={calculated()}

                            onChange={(e) => settotalamount(e.target.value)}
                          />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center pb-1 mt-md-3">
                  {paymentMethod == "bank" && BankList.length == 0 ? (
                    <button
                      type="button"
                      disabled
                      className="btn btn-primary btn-lg ml-4"
                    >
                      {calculated == 0 ? "Not Able To Pay" : "Pay amount"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={Loading || calculated == 0}
                      className="btn btn-primary btn-lg ml-4"
                      onClick={(e) => HandlePaymentSubmit(e)}
                    >
                      {calculated == 0 ? "Not Able To Pay" : "Pay amount"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payment;
