import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";

const SpecialityDetail = (p) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [data, setData] = useState();
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`speciality`, { id: did }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  return (
    <Layout>
      <div className="heightVH">
        {" "}
        <div className="normalFlex align-items-center mb-3">
        <h3 className="hedding d-flex gap-1 align-items-center " >
            <a to="/speciality" onClick={back}>
              <i className="fa fa-angle-double-left fontBack"  title='Back' aria-hidden="true"></i>
            </a>
            Speciality Detail
            </h3>
            <div>
      <button className="btn btn-primary d-flex align-items-center gap-1 mb-0"  onClick={(e) => history.push(`/AddEditspeciality/${id}`)}
         
              title="Edit">
      <i
               class="material-icons edit text-white "   
            >
              edit
            </i>Edit
    
      </button>
          </div>
        </div>
   
      
          <div className="sideclass  pprofile1">
          
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls">Name</label>
                <div className="profiledetailscls">{data && data.name}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls">Category</label>
                <div className="profiledetailscls">
                  {data && data.category.name}
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </Layout>
  );
};

export default SpecialityDetail;
