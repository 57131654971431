import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";

const Html = ({
  tab,
  edit,
  ChangeStatus,
  ShowDeleteModal,
  setShowDeleteModal,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  ShowActiveModal,
  setShowActiveModal,
  data,
  isAllow,
  loading1,
  total = { total },
}) => {
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const ConfirmFunc = () => {
    statusChange(StatusData);
  };
  return (
    <Layout>
      <div className="heightVH">
        {" "}
        <CommonActiveModal
          setShow={setShowActiveModal}
          show={ShowActiveModal}
          status={StatusData.status}
          confirm={ConfirmFunc}
        />
        <CommonDeleteModal
          show={ShowDeleteModal}
          setShow={setShowDeleteModal}
          confirm={Delete}
        />
        <div className="normalFlex align-items-center">
          <h3 className="hedding">Assessment</h3>

          <article className="d-flex">
            {isAllow("addAssessment") ? (
              <Link className="btn btn-primary mr-2" to="/AddEditAssessment">
                <i className="fa fa-plus me-2"></i> Add Assessment
              </Link>
            ) : (
              <></>
            )}

            {/* <div className="dropdown addDropdown">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                        </button>
                        <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                            <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                            <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                            <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                        </div>
                    </div> */}
          </article>
        </div>
        {tab == "grid" ? (
          <></>
        ) : (
          <>
            <div className="table-responsive">
              <div className="table_section">
                <table class="table">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data">
                        {" "}
                        Assessment Name
                      </th>

                      <th scope="col" className="table_data">
                        Weightage Per Question
                      </th>
                      <th scope="col" className="table_data">
                        Minimum Passing Score
                      </th>
                      <th scope="col" className="table_data">
                        Skills
                      </th>
                      {/* <th scope="col" className='table_data'>Skill</th> */}

                      <th scope="col" className="table_data">
                        Status
                      </th>
                      <th scope="col" className="table_data">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((itm, index) => (
                      <tr className="data_row">
                        <td
                          className="table_dats"
                          onClick={(e) => edit(itm.id)}
                        >
                          {itm.name}
                        </td>

                        <td
                          className="table_dats"
                          onClick={(e) => edit(itm.id)}
                        >
                          {itm.weightagePerQuestion}
                        </td>
                        <td
                          className="table_dats"
                          onClick={(e) => edit(itm.id)}
                        >
                          {itm.passingScrore}
                        </td>
                        <td
                          className="table_dats"
                          onClick={(e) => edit(itm.id)}
                        >
                         <div className="d-flex">
                         <div className="">{itm.skills &&
                            itm.skills.map((item) => <span className="badge badge-primary me-1">{item.name},</span>)}</div>
                            <span className="badge badge-info badge-info-custom">2+ more</span>
                         </div>
                        </td>
                        {/* <td className='table_dats' onClick={e => edit(itm.id)}>{itm.skill}</td> */}

                        <td className="table_dats">
                          {" "}
                          <div className={` ${itm.status}`}>
                            <span className="contract">
                              {itm.status == "deactive" ? (
                                <Switch
                                  onChange={(e) => {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  }}
                                  checked={false}
                                />
                              ) : (
                                <Switch
                                  onChange={(e) => {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  }}
                                  checked={true}
                                />
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="table_dats">
                          <div className="action_icons">
                            {isAllow("editAssessment") ? (
                              <>
                                <a
                                  className="edit_icon iconsWidthAdjust"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <i class="material-icons edit" title="Edit">
                                    edit
                                  </i>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}

                            {isAllow("deleteAssessment") ? (
                              <span
                                className="edit_icon"
                                onClick={() => {
                                  setDeleteId(itm.id);
                                  setShowDeleteModal("block");
                                }}
                              >
                                <i class="material-icons delete" title="Delete">
                                  {" "}
                                  delete
                                </i>
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>

                        {/* end */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {/* {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>} */}
        {loading1 ? (
          <h3 className="text-center mt-2">Loading...</h3>
        ) : data.length == 0 ? (
          <div className="text-danger text-center">No Data</div>
        ) : null}
        {!loaging && total > filters.count ? (
          <div className="paginationWrapper">
            <span>
              Show {filters.count} from {total} Assessments
            </span>
            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}
        {loaging ? (
          <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default Html;
