import { useEffect, useState } from 'react';
import statusModel from '../../../models/status.model';
import loader from '../../../methods/loader';
import ApiClient from '../../../methods/api/apiClient';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../../../components/global/layout';
import { useSelector } from 'react-redux';
import environment from '../../../environment';
import { useHistory } from 'react-router-dom';

export default function AddEditHardSkills() {
    const { id } = useParams();
    const user = useSelector(state => state.user);
    const navigate = useHistory();
    const [form, setForm] = useState({ id: "", hardSkillName: "" });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (id) {
            loader(true);
            ApiClient.get(`hardskill/detail/?id=${id}`).then(res => {
                if (res.success) {
                    const newData = res.data;
                    setForm({ id: newData?.id, hardSkillName: newData?.name, name: newData?.professionDetail?.name });
                }
                loader(false);
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        loader(true);
        const payload = { id: form.id, hardSkillName: form.hardSkillName };
        ApiClient.put('hardskill', payload).then((res) => {
            if (res?.success) {
                toast.success(res?.message);
                navigate.goBack();
            }
            loader(false);
        });
    };

    const isAllow = (key = '') => {
        const permissions = user.role?.permissions;
        let value = permissions?.[key];
        if (user?.role?.id === environment?.adminRoleId) value = true;
        return value;
    };

    return (
        <Layout>
            <div className='heightVH'>
                <form onSubmit={handleSubmit}>
                    <div className="d-flex gap-1 align-items-center mb-3">
                        <i onClick={() => navigate.goBack()} className="fa fa-angle-double-left fontBack text-dark" title="Back"></i>
                        <h3 className="hedding">{id ? 'Edit' : 'Add'} Professions</h3>
                    </div>
                    <div className="pprofile1">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Professions<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='name'
                                    value={form.name}
                                    onChange={e => setForm({ ...form, name: e.target.value })}
                                    // required
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-3 mb-3">
                                <label>Hard Skill<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='hardSkillName'
                                    value={form.hardSkillName}
                                    onChange={e => setForm({ ...form, hardSkillName: e.target.value })}
                                    required
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            {(!id || (id && isAllow('editSkillType'))) && <button type="submit" className="btn btn-primary">Save</button>}
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
}

