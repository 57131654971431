import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import methodModel from "../../methods/methods";
import Layout from "../../components/global/layout";
import dayjs from "dayjs";
import environment from "../../environment";

import axios from "axios";
import moment from "moment";
import './style.scss';
import { DateRangePicker } from "react-date-range";
import CustomDateRangePicker from "./CustomDateRange";
export default function EmployerReport() {
  const user = useSelector((state) => state.user);
  const [filters, setfilters] = useState({
    //  name: "",  employerId: user.id, contractId: "", virtualId: ""
    start_date: "", end_date: "", sortBy: 'start_date desc', search: ""
  });
  const [EmpList, setEmpList] = useState([]);
  const Navigate = useHistory();
  const [VAList, setVAList] = useState([]);
  const [Data, setData] = useState([]);
  console.log(Data, '==Data')
  const [Count, setCount] = useState(0);
  const [loading, setloading] = useState(true)
  const searchState = useSelector((state) => state.search);
  const [FullData, setFullData] = useState({})
  const GetReports = (p = {}) => {
    const NewFilters = { ...filters, ...p };
    NewFilters.start_date = methodModel.MomentDateConvertor(NewFilters.start_date)
    NewFilters.end_date = methodModel.MomentDateConvertor(NewFilters.end_date)
    loader(true);
    ApiClient.get(`employer/list`, NewFilters).then((res) => {
      if (res.success) {
        setFullData(res)
        setData(res?.data);
        if (Count == 0) {
          let data = res?.data.map((item) =>
            item?.billings_details.map((itm) => itm)
          );
          const flattenedData = [].concat(...data);
          let compressedDat = flattenedData;
          compressedDat.map((item, index) => {
            compressedDat[index]["Date"] = new Date(
              `${item?._id.year}/${item?._id.month}/${item?._id.day}`
            );
          });

          const [maxDate, minDate] = methodModel.findMinMaxDates(
            compressedDat,
            "Date"
          );
          // setSelectedDateRange([dayjs(minDate), dayjs(maxDate)]);

          setCount(Count + 1);
        }
      }
      loader(false)
      setTimeout(() => { setloading(false) }, 500)
      // setTimeout(() => { loader(false) }, 500)
    });
  };


  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data });
      GetReports({ search: searchState.data });
    }
  }, [searchState]);

  const GetVALIST = () => {
    // loader(true);
    ApiClient.get(`contracts?search=&status=accepted&addedBy=&sortBy=contractName asc`).then(
      async (res) => {
        if (res.success) {
          const newdata = res.data;

          const updateddata = [];
          await newdata.map((item, index) => {
            updateddata.push({
              id: item?._id,
              name: item?.contractName || item?.project_details?.title || item?.project_details?.name,
            });
          });
          updateddata.unshift({ id: '', name: 'All' })
          setVAList(updateddata);
        }
        // loader(false)
      }
    );
  };

  const GetEmpList = () => {
    // loader(true);
    ApiClient.get(
      `users/list?role=${environment.EmployerId}&isDeleted=false&sortBy=fullName asc`
    ).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        newdata.unshift({ id: '', fullName: 'All' })
        setEmpList(newdata);
      }
      // loader(false)
    });
  };
  useEffect(() => {
    GetEmpList();
    GetVALIST();
    GetReports();
  }, []);
  const [SelectedDateRange, setSelectedDateRange] = useState({
    startDate: '',
    endDate: '',
    type: 'now',
    key: 'selection',
  });

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setfilters({
      ...filters,
      start_date: dates[0] || "",
      end_date: dates[1] || "",
    });
    GetReports({ start_date: dates[0] || "", end_date: dates[1] || "" });
    // You can perform additional filtering or other actions based on the selected date range
  };


  const [VirtualList, setVirtualList] = useState([]);
  const GetVirtualList = () => {
    // loader(true);
    ApiClient.get(
      `users/list?role=${environment.VirtualId}&isDeleted=false&isVerifiedDocument=accepted&sortBy=fullName asc`
    ).then((res) => {
      if (res.success) {
        const newdata = res?.data
        newdata.unshift({ id: '', fullName: 'All' })
        setVirtualList(newdata);
      }
      // loader(false)
    });
  };
  useEffect(() => {
    GetVirtualList();
  }, []);

  const DownloadEmployerReport = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}exportEmployerReport?start_date=${methodModel.MomentDateConvertor(filters?.start_date)}&end_date=${methodModel.MomentDateConvertor(filters?.end_date)}&contractId=${filters?.contractId ? filters?.contractId : ""}&employerId=${filters?.employerId ? filters?.employerId : ""}&search=${filters?.search ? filters?.search : ""}&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `EmployerReports.xlsx`;
    link.click();
  };
  const GetTotalHours = () => {
    let Result = 0;
    Data.map((item) => {
      Result += Number(item.totalDurationOfTime)
    })
    return parseFloat(Result).toFixed(2)
  }


  const DownloadPdf = async (e) => {
    e.preventDefault();
    loader(true)
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}epmloyer-report/pdf?start_date=${methodModel.MomentDateConvertor(filters?.start_date)}&end_date=${methodModel.MomentDateConvertor(filters?.end_date)}&contractId=${filters?.contractId ? filters?.contractId : ""}&employerId=${filters?.employerId ? filters?.employerId : ""}&search=${filters?.search ? filters?.search : ""}&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          // const a = document.createElement("a");
          // a.href = environment?.api + pdfUrl
          // a.click();
          // return
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "EmployerReports.pdf";
                a.click();
                loader(false);
              })
          } else {
            loader(false);
          }
        })
    }, 3000);
    loader(false)
  };

  const [contractid, setContractId] = useState()

  const [TotalHours, setTotalHours] = useState({ hours: "", minutes: "" });
  const [TotalPaidHours, setTotalPaidHours] = useState({
    hours: "",
    minutes: "",
  });
  const [TotalUnPaidHours, setTotalUnPaidHours] = useState({
    hours: "",
    minutes: "",
  });
  const GetHoursCalculaions = (type = "") => {
    ApiClient.get(
      `billing/list?addedBy=&contractId=${contractid}&paidHours=${type}`
    ).then((res) => {
      if (res.success) {
        let hours = res?.total_billingHours;
        let minutes = res?.total_billingMinutes;
        let remainder = minutes / 60;
        let quotient = minutes % 60;
        hours += parseInt(remainder);
        minutes = quotient;
        if (type == "") {
          setTotalHours({ hours: hours || "00", minutes: minutes || "00" });
        } else if (type == "false") {
          setTotalUnPaidHours({
            hours: hours || "00",
            minutes: minutes || "00",
          });
        } else {
          setTotalPaidHours({ hours: hours || "00", minutes: minutes || "00" });
        }
      }
    });
  };
  useEffect(() => {
    if (filters.contractId) {

      GetHoursCalculaions("");
      GetHoursCalculaions(true);
      GetHoursCalculaions("false");
    }
  }, [filters.contractId]);

  const Sort = (key) => {
    let sorder = 'asc';
    if (filters.key === key) {
      sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
    }
    setfilters({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setfilters({ ...filters, sortBy, key, sorder });
    GetReports({ sortBy, key, sorder });
  };


  const searchHandle = (e) => {
    e.preventDefault()
    // setfilters({ ...filters, search: e })
    GetReports({ search: filters?.search })
  }

  const clear = () => {
    setfilters({ ...filters, search: "" })
    GetReports({ search: "" })
  }

  const searchChange = (data) => {
    setfilters({ ...filters, search: data })
    // GetReports({ search: data })

    if (!data) {
      GetReports()
    }
  }
  return (
    <>
      <Layout>
        <div className="heightVH"> <div className="cardit">
          <div className="normalFlex mb-4  flex-direction-set">
            {" "}
            <h3 className="report_heading">Employer </h3>
            <div className="d-flex align-items-center flex-direction-set">
              <div className="d-flex justify-content-end  set_serchbx flex-column me-3">
                {/* <label className='mt-2 ml-1'>Search </label> */}
                <form className='headerSearch h-searchOne' onSubmit={e => searchHandle(e)} >
                  <input type="text" placeholder="Search..." name='search' value={filters?.search} onChange={e => searchChange(e.target.value)} className="form-control"></input>
                  <i className="fa fa-search" onClick={e => searchHandle(e)} aria-hidden="true"></i>
                  {filters?.search != "" ? <i className="fa fa-times" onClick={clear} aria-hidden="true"></i> : ""}
                </form>

              </div>
              <div className="report-btn">
                <button
                  className="btn btn-primary "
                  onClick={(e) => DownloadPdf(e)}
                >
                  Pdf Export
                </button>
                <button
                  className="btn btn-primary ml-2"
                  onClick={(e) => DownloadEmployerReport(e)}
                >
                  Export
                </button>
              </div>
            </div>
          </div>

          {/* <button className='btn btn-primary mt-5' style={{ float: "right" }} onClick={e => DownloadEmployerReport(e)}>Export</button> */}
          <div className='custom-flex justify-content-between align-items-end  mb-3'>

            {/* <div className='row'> */}

            {/* <div className="col-md-4 mb-4">
                <label>Date range</label>
                <RangePicker
                  defaultValue={DefaultValueSet}
                  className="dateRangePicker"
                  placeholder={["Start Date", "End Date"]}
                  format="MM-DD-YYYY"
                  onChange={handleDateChange}
                  value={SelectedDateRange}
                />
              </div> */}

            <div className="d-flex  flex-wrap  fullWidth gap-2">
              <div className="datePickeWidth fullWidth">
                <label>Date range</label>
                {/* <RangePicker
                  className="dateRangePicker"
                  placeholder={["Start Date", "End Date"]}
                  format="MM-DD-YYYY"
                  onChange={handleDateChange}

                /> */}
                <CustomDateRangePicker
                  onChange={handleDateChange}
                  value={SelectedDateRange}
                />
              </div>
              <div className=' mWidthFull fullWidth'>
                <label>Contract  </label>
                <div class="dropdown">
                  <button class="btn employer_filt btn-secondary dropdown-toggle fullWidth mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa fa-sliders mr-1" aria-hidden="true"></i>
                    <span className="">  {!filters.contractId ? "Select Contract" : VAList.find(item => item.id == filters.contractId).name}</span>
                  </button>
                  <ul class="dropdown-menu">
                    {VAList.map((item) => (<li><a class="dropdown-item" onClick={e => { setfilters(prev => ({ ...filters, contractId: item?.id })); setContractId(item?.id); GetReports({ contractId: item?.id }) }}>{item?.name}</a></li>))}

                  </ul>
                </div>
              </div>

              <div className=' mWidthFull fullWidth'>
                <label>Employer  </label>
                <div class="dropdown">
                  <button
                    class="btn employer_filt w-100 btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-sliders mr-1" aria-hidden="true"></i>{" "}
                    {!filters.employerId
                      ? "Select Employer"
                      : EmpList.find((item) => item.id == filters.employerId)
                        .fullName}
                  </button>
                  <ul class="dropdown-menu">
                    {EmpList.map((item) => (
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={(e) => {
                            setfilters((prev) => ({
                              ...filters,
                              employerId: item?.id,
                            }));
                            GetReports({ employerId: item?.id });
                          }}
                        >
                          {item?.fullName}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className='d-flex gap-2 align-items-center mWidthFull fullWidth'>
                <div className="mWidthFull fullWidth">
                  <label>Virtual Assistant  </label>
                  <div class="dropdown fullWidth">
                    <button class="btn employer_filt w-100 btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa fa-sliders mr-1" aria-hidden="true"></i> {!filters.virtualId ? "Select VA" : VirtualList.find(item => item.id == filters.virtualId)?.fullName}

                    </button>
                    <ul class="dropdown-menu fullWidth">
                      {VirtualList.map((item) => (
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={(e) => {
                              setfilters((prev) => ({
                                ...filters,
                                virtualId: item?.id,
                              }));
                              GetReports({ virtualId: item?.id });
                            }}
                          >
                            {item?.fullName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="clearMargin">
                  <label className="clearOpacity"> clear </label>
                  {filters.virtualId || filters.contractId || filters.start_date || filters.end_date || filters.employerId ? <button className='btn btn-primary d-block mb-0' onClick={() => {
                    setfilters({ virtualId: "", end_date: "", start_date: "", contractId: "", name: "", employerId: "", virtualId: "" }); setSelectedDateRange({
                      startDate: '',
                      endDate: '',
                      type: 'now',
                      key: 'selection',
                    }); GetReports({ virtualId: "", end_date: "", start_date: "", contractId: "", name: "", employerId: "", virtualId: "" })
                  }}>Clear</button> : null}
                </div>


              </div>


            </div>
            {/* </div> */}


          </div>

          <div className="d-flex gap-3 flex-wrap  mb-2">
            <div className="  " >
              <p className={`pms backitagin  text-center backitagindiv`} >

                <div>
                  <span className="fontw">        {FullData?.totalHours || 0}:
                    {FullData?.totalMinutes < 10
                      ? `0${FullData?.totalMinutes < 0 ? 0 : FullData?.totalMinutes}`
                      : FullData?.totalMinutes}</span>
                  <br />
                  Total Hours
                </div>
              </p>
            </div>
            <div className="    " >
              <p className={`pms backit text-center  backitagindiv pinkbg`} >

                <div>  <span className="fontw">        {FullData?.paidHours || 0}:
                  {FullData?.paidMinutes < 10
                    ? `0${FullData?.paidMinutes < 0 ? 0 : FullData?.paidMinutes}`
                    : FullData?.paidMinutes}</span>
                  <br />
                  Total Paid Hours</div>
              </p>
            </div>
            <div className="  " >
              <p className={`pms backitagin  text-center backitagindiv graybgp`} >
                <div>
                  <span className="fontw">        {FullData?.unpaidHours || FullData?.totalHours || 0}:
                    {!FullData?.unpaidMinutes ? (FullData?.totalMinutes < 10 ? `0${FullData?.totalMinutes}` : FullData?.totalMinutes) : FullData?.unpaidMinutes < 10
                      ? `0${FullData?.unpaidMinutes < 0 ? 0 : FullData?.unpaidMinutes}`
                      : FullData?.unpaidMinutes}</span>
                  <br />
                  Total UnPaid Hours</div>
              </p>
            </div>
          </div>
          <div className="mt-2">
            <div className="cmc_table2">
              <div className="row cms firstcms2 cmcRowAdjust">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <label>Contract Name</label>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <label>Virtual Assistant Name</label>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <label>Employer Name</label>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center cursor-pointer" style={{ cursor: 'pointer' }} onClick={e => Sort('start_date')}>
                  <label style={{ cursor: 'pointer' }}>Start Date</label>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <label>Total Hours</label>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <label>Total Amount</label>
                </div>
              </div>

              {Data.map((item, index) => (
                <div className="row cms3 cmcRowAdjust">
                  <div className="col-2 ">
                    <p
                      className="pms npms"

                    >
                      {item?.contractDetail?.createdBy === "admin" ? item?.projectsDetail?.name || item?.contractDetail?.contractName || item?.contractDetail?.title : item?.contractDetail?.contractName || "---"}
                    </p>
                  </div>
                  <div className="col-2 ">
                    <p
                      className="pms npms sixfont"

                    >
                      {item?.virtualDetail?.fullName}{" "}
                    </p>
                  </div>
                  <div className="col-2 ">
                    <p
                      className="pms npms "

                    >
                      {item?.employerDetail?.fullName}{" "}
                    </p>
                  </div>
                  <div className="col-2 ">
                    <p
                      className="pms npms "

                    >
                      {methodModel.convertDate(item?.contractDetail?.startDate)}{" "}
                    </p>
                  </div>

                  <div className="col-2">
                    <b style={{ fontWeight: "300" }} className="text-black">
                      <p className="pms gms">
                        {item?.totalDurationOfTime_hour}:{item?.totalDurationOfTime_min < 10 ? '0' + item?.totalDurationOfTime_min : item?.totalDurationOfTime_min} hrs
                      </p>
                    </b>
                  </div>
                  <div className="col-2">
                    <p className="pms gms">
                      {parseFloat(item?.TotalEmployerHourlyRate || 0).toFixed(
                        2
                      )}{" "}
                      USD
                    </p>
                  </div>
                  {item?.billingDetail?.map((itm) => (
                    <>
                      <div className="col-2 ">
                        <p className={`pms ${index % 2 == 0 ? "backit" : "backitagin"} `}>
                          {itm?.startTime ? methodModel.getDayNameByDate(
                            `${methodModel.convertDate(itm?.startTime)}`
                          ) : '---'}{" "}
                          {itm?.startTime ? methodModel.convertDate(itm?.startTime) : '--'}{" "}

                        </p>
                      </div>
                      <div className="col-2 ">
                        <p className={`pms ${index % 2 == 0 ? "backit" : "backitagin"} `}>
                          {itm?.endTime ? <>{methodModel.getDayNameByDate(
                            `${methodModel.convertDate(itm?.endTime)}`
                          )}{" "}
                            {methodModel.convertDate(itm?.endTime)}{" "}</> : "---"}

                        </p>
                      </div>

                      <div className="col-2 " style={{ color: itm?.isOverTime ? '#f25858' : '' }}>
                        <p className="pms ">
                          {methodModel.ConvertTime(itm?.startTime)}
                        </p>
                      </div>
                      <div className="col-2 " style={{ color: itm?.isOverTime ? '#f25858' : '' }}>
                        <p className="pms ">
                          {itm?.endTime ? methodModel.ConvertTime(itm?.endTime) : " "}
                        </p>
                      </div>
                      <div className="col-2 " style={{ color: itm?.isOverTime ? '#f25858' : '' }}>
                        <p className="pms ">

                          {itm?.startTime && (itm?.endTmeForHour || itm?.endTime) ? methodModel?.GetHourMinutesDates(itm?.startTime, itm?.endTmeForHour || itm?.endTime, itm?.memo) : (0).toFixed(2)}
                        </p>
                      </div>

                      <div className="col-2 ">
                        <p className="pms ">---</p>
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>

            {loading ? <h3 className="text-center mt-2">Loading...</h3> : Data.length == 0 ? (
              <div className="text-danger text-center">No Reports here</div>
            ) : null}
          </div>


        </div></div>
      </Layout>
    </>
  );
}
