import React from "react";
import Layout from "../../components/global/layout";

const Html = (
    {
        history,
        data,
        Deletedata,
        view,
        loading
    }
) => {
    return <>
        <Layout>
         <div className="heightVH">   <div className="normalFlex">
                <h3 className="hedding">
                    Testimonials
                </h3>
                <div >
                    <button onClick={() => history.push("/testonimal/add")} className="btn btn-primary"><i className="fa fa-plus me-2"></i>Add Testimonials</button>
                </div>
            </div>
            <div className="py-4">
              <div className="table table-responsive testmonialTable">  <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Description</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => <tr>

                            <td onClick={e => view(item.id)}>{item.title}</td>
                            <td onClick={e => view(item.id)}>{item?.designation || "---"}</td>
                            <td className="table_dats" >
                                <div className="text-truncates"
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                ></div>
                            </td>
                            <td>
                                <div className="action_icons d-flex gap-1">
                                    <a className='edit_icon mr-0' title="Edit">
                                        <i onClick={() => history.push("/testonimal/edit/" + item.id)} class="material-icons edit" title="Edit">edit</i>
                                    </a>
                                    <a className='delete_icon' title="Delete">
                                        <i onClick={e => Deletedata(e, item.id)} class="material-icons delete my-2" title="Delete">delete</i>
                                    </a>
                                    <a className='detail_icon' title="Detail">
                                        <i onClick={() => history.push("/testonimal/detail/" + item.id)} class="fa fa-eye my-2 mx-2" title="View"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table></div>
                {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
              <div className="text-danger text-center">No Data</div>
            ) : null}
            </div></div>
        </Layout>

    </>
}
export default Html;