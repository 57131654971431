import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import environment from '../../../environment';

const FaqDetail = (p) => {
    const history = useHistory()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`faq`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    };

    const back = () => {
        history.goBack()
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
    }, [id, userId])

    return (
        <Layout>
      <div className='heightVH'>      <div className='normalFlex mb-3'>
            <h3 className="hedding d-flex gap-1 align-items-center " >
            <a to="/blog" onClick={back}>
              <i className="fa fa-angle-double-left fontBack"  title='Back' aria-hidden="true"></i>
            </a>
            FAQs Detail
          </h3>
          <div className="">
                <button onClick={e=>history.push(`/AddEditfaq/${id}`)} className='btn btn-primary gap-1 mb-0 d-flex align-items-center'>
                <i  class="material-icons edit text-white " title="Edit">edit</i>
                   Edit
                </button>
            </div>
            </div>
            {/* <div className="text-right">
                <div>
                <i onClick={e=>history.push(`/AddEditfaq/${id}`)} class="material-icons edit mr-3 backhead" title="Edit">edit</i>
                    <a to="/blog" onClick={back}>  <i className="fa fa-angle-double-left mb-3 backheadarrow " title='Back' aria-hidden="true"></i></a>
                </div>
            </div> */}
           
                <div className="sideclass pprofile1">
              

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Question</label>
                            <div className='profiledetailscls'>{data && data.question}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Category</label>
                            <div className='profiledetailscls'>{data&&data.category.name}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="profileheddingcls">Answer</label>
                            {/* <div className='profiledetailscls'>{data && data.answer}</div> */}
                            <div className='profiledetailscls' dangerouslySetInnerHTML={{__html: data && data.answer || "--"}}></div>  
                        </div> 
                        {data?.video&&data?.video!=""?
           <div className="col-md-12 mb-3">
                <label className="profileheddingcls">Video</label>
                <div>
                  <div className="imagethumbWrapper">
                  <video width={300} height={150} src={`${environment.api+"images/videos/"+data?.video}`} controls/>
                  </div>

            
                </div>
              </div>:null}
                                             
                                                             
            
                    </div>
                </div>
           </div>
        </Layout>

    );
};

export default FaqDetail;
