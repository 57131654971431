import React, { useEffect, useState } from "react";
import methodModel from "../../methods/methods";
import { Link } from "react-router-dom";
import Layout from "../../components/global/layout";
import Select from "react-select";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { User } from "../../methods/auth";
import { useSelector } from "react-redux";
import environment from "../../environment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Header from '../../components/global/header';
import loader from "../../methods/loader";
import Multiselect from "multiselect-react-dropdown";
import { Formik } from "formik";
import moment from "moment";
import Pagination from 'react-js-pagination';
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { HiOutlineArrowDown } from 'react-icons/hi';
import { HiArrowSmUp } from 'react-icons/hi';


const Projects = () => {
  const searchState = useSelector((state) => state.search);
  const [ShowActiveModal, setShowActiveModal] = useState("none")
  const [StatusData, setStatusData] = useState({})
  const [SearchData, setSearchData] = useState("");
  const [ShowDeleteModal, setShowDeleteModal] = useState("");
  const [filters, setfilters] = useState({ page: 1, count: 20, search: "", status: "", skills: [], technology: [] })
  const [total, settotal] = useState(0);
  const [View, setView] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [Editid, setEditid] = useState("")
  const user = useSelector(state => state.user);
  const [SelectedSkills, setSelectedSkills] = useState([])
  const [SkillsData, setSkillsData] = useState([]);
  const GetAllSkills = () => {
    ApiClient.get(`skills`).then(res => {
      if (res.success) {
        const data = res.data;
        const array = [];
        data.map((item, index) => {
          array.push({ name: item.name, id: item.id })
        })
        setSkillsData(array);
      }
    })

  }
  useEffect(() => {
    GetAllSkills()
  }, [])
  const [loading, setloading] = useState(true)

  const [ProjectsData, setProjectsData] = useState([]);
  const GetAllProjects = (p = {}) => {
    const newfilters = { ...filters, ...p, skills: p.skills ? p.skills.map((item) => item.id).toString() : filters.skills.map((item) => item.id).toString(), technology: p.technology ? p.technology.map((item) => item.id).toString() : filters.technology.map((item) => item.id).toString() };
    loader(true);
    ApiClient.get(`projects`, newfilters).then(res => {
      if (res.success) {
        setProjectsData(res.data);
        settotal(res.total)
      }
      loader(false);
      setloading(false)
    })
  }
  useEffect(() => {
    GetAllProjects();
  }, [])


  //  for Document 
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [images1, setimages1] = useState([]);
  const [ProtofolioError1, setProtofolioError1] = useState(false);
  // const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images1];
    array.splice(index, 1);
    setimages1(array);
  };

  const imageResult = (e) => {
    if (e.target.files && e.target.files.length > 0) {

      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images1;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date())
            if (files.length == images1.length) {
              setDocumentUploadLoading(false)
            }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;

      }
      setProtofolioError1(false);
      setdocuments(newarray);
      setimages1(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }

  };
  const [EditData, setEditData] = useState({});
  const [DeleteId, setDeleteId] = useState("");

  const HandleProjectDelete = () => {
    // if(window.confirm("Do you want to Delete this?")){
    loader(true);
    ApiClient.delete(`project?id=${DeleteId}`).then(result => {
      if (result.success) {
        setShowDeleteModal("none")
        toast.success("Job Post Deleted Successfully");
        GetAllProjects();
      }
      loader(false);
    })
    // }
  }
  const pageChange = e => {
    setfilters({ ...filters, page: e });
    GetAllProjects({ page: e });
  };
  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    if (SearchData == "") {

    } else {
      setfilters({ ...filters, search: SearchData })
      GetAllProjects({ search: SearchData })
    }
  }

  const HandleSearchFilter = (value) => {
    setfilters({ ...filters, search: "" });
    setSearchData("");
    GetAllProjects({ search: "" })
  }
  const statusChange = () => {
    let status = 'active'
    if (StatusData.status == 'active') status = 'deactive'

    // if(window.confirm(`Do you want to ${status=='active'?'Activate':'Deactivate'} this Project`)){
    loader(true)
    ApiClient.put(`change/status?model=projects`, { status, id: StatusData.id }).then(res => {
      if (res.success) {
        toast.success(`Project ${status == 'active' ? 'Activated' : 'Deactivated'} Successfully`)
        GetAllProjects()
        setShowActiveModal("none")
      }
      loader(false)
    })
    // }
  }

  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data })
      GetAllProjects({ search: searchState.data, page: 1 })
    }
  }, [searchState])
  const ChangeStatus = (e) => {
    setfilters({ ...filters, status: e, page: 1 })
    GetAllProjects({ status: e, page: 1 })
  }

  const [TechnologyData, setTechnologyData] = useState([]);
  const [SelectedTechnology, setSelectedTechnology] = useState([]);
  const GetAllTechnologies = () => {
    ApiClient.get(`technologies?status=active`).then(res => {
      if (res.success) {
        const newdata = res.data;
        const array = [];
        newdata.map((item) => {
          array.push({ name: item.name, id: item.id });
        })
        setTechnologyData(array);
      }
    })
  }

  const [AllEmployeers, setAllEmployeers] = useState([]);
  const [SelectedEmployeer, setSelectedEmployeer] = useState({ label: "All", value: "" });
  const GetAllEmplopyeer = () => {
    ApiClient.get(`users/list?status=active&role=64e5cdfc5bb9a041a39046d5&isDeleted=false`).then(res => {
      if (res.success) {
        const data = res.data;
        const MyArray = [{ label: "All", value: "" }];
        data.map((item) => {
          MyArray.push({ label: item.fullName, value: item.id })
        })
        setAllEmployeers(MyArray);
      }
    })
  }
  useEffect(() => {
    GetAllTechnologies();
    GetAllEmplopyeer()
  }, []);

  const sorting = (key) => {
    let sorder = 'asc';
    if (filters.key === key) {
      sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
    }
    setfilters({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setfilters({ ...filters, sortBy, key, sorder });
    GetAllProjects({ sortBy, key, sorder });
  };

  const renderSortIcon = (columnKey) => {
    if (filters.key !== columnKey)
      return <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
    else {
      if (filters.sorder === 'asc') {
        <HiArrowSmUp className="shrink-0 inline text-sm" />;
      } else {
        <HiOutlineArrowDown className="shrink-0 inline text-sm" />;
      }
    }
    return filters.sorder === 'asc' ? (
      <HiArrowSmUp className="shrink-0 inline text-sm" />
    ) : (
      <HiOutlineArrowDown className="shrink-0 inline text-sm" />
    );
  };


  const ReviewJob = (e, id) => {
    e.preventDefault()
    loader(true)
    ApiClient.put('job/review', { id: id, admin_review: "reviewed" }).then((res) => {
      if (res.success) {
        toast.success(res?.message)
        GetAllProjects()
      }
      loader(false)
    })
  }

  return (
    <Layout>
      <div className="heightVH">    <CommonActiveModal show={ShowActiveModal} setShow={setShowActiveModal} confirm={statusChange} status={StatusData.status} />
        <CommonDeleteModal show={ShowDeleteModal} setShow={setShowDeleteModal} confirm={HandleProjectDelete} />
        <>
          <section className="">
            {/* <form className='' style={{float:"right"}}  onSubmit={e=>HandleSearchSubmit(e)}> 
          <div class="input-group">
          <input type="search" placeholder='Search' onChange={e=>e.target.value==""?HandleSearchFilter(e.target.value):setSearchData(e.target.value)} id="form1" class="form-control d-flex-inline" /> 
          <div class="input-group-append">
          <button type="submit" class="btn btn-primary d-inline-flex pl-3 pr-3">
          <i class="fas fa-search"></i>
        </button>
        </div>
        
        </div>
       </form>  */}
            <div className="normalFlex ">

              <h3 className="hedding mb-2">All Job Posts </h3>
              <div className="d-flex flex-wrap gap-2">
                <div className="dropdown addDropdown mt-0 ">
                  <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                  </button>
                  <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                    <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                    <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                    <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                  </div>
                </div>
                <div className=" dropdownRadius ">
                  <Select
                    value={SelectedEmployeer}
                    placeholder="All "
                    className=""
                    onChange={e => { setSelectedEmployeer(e); setfilters({ ...filters, userId: e.value }); GetAllProjects({ userId: e.value }) }}
                    options={AllEmployeers}
                  />
                </div>
                {/* <div className="mr-3">
                      { <Multiselect
            displayValue="name"
            className="d-inline bg-white" 
            options={TechnologyData}   
            placeholder="All Technologies"
            selectedValues={filters.technology}
            onSelect={e=>{setfilters({...filters,technology:e});GetAllProjects({technology:e}) }}
            onRemove={e=>{setfilters({...filters,technology:e});GetAllProjects({technology:e}) }}
            /> }
                    </div> */}

                <div className="jobsMultiSelect">
                  <Multiselect
                    displayValue="name"
                    options={SkillsData}
                    placeholder="All Skills"
                    selectedValues={filters.skills}
                    onSelect={e => { setfilters({ ...filters, skills: e }); GetAllProjects({ skills: e }) }}
                    onRemove={e => { setfilters({ ...filters, skills: e }); GetAllProjects({ skills: e }) }}
                  />
                  <div>
                    {(filters.skills.length == 0) || (filters.userId == "") || (filters.technology.length == 0) ? null : <button className="btn btn-primary ml-2" onClick={e => { setfilters({ ...filters, skills: [], technology: [], status: "", userId: "" }); GetAllProjects({ skills: [], technology: [], status: "", userId: "" }); setSelectedEmployeer([]); }}>Clear</button>}
                  </div>
                </div>
              </div>


            </div>

            <div className=" position-relative">
              {/* <button className="btn btn-primary project w-auto position-absolute right" data-toggle="modal" onClick={e=>{ setView(false);setEdit(false);setimages1([]);setSelectedSkills([]);setProtofolioError1(false)}} data-target="#exampleModal">Add Project</button> */}


              <div className="table-responsive">
                <div className='table_section mb-3'>
                  <table class="table">
                    <thead className='table_head'>
                      <tr className='heading_row'>
                        <th onClick={(e) => sorting('name')} scope="col" className='cursor-pointer table_data'>Title{' '}
                          <span className="mr-2">{renderSortIcon('name')}</span></th>
                        {/* <th scope="col" className='table_data'>Category</th>                      */}
                        {/* <th scope='col' className='table_data'>Specialty</th> */}
                        {/* <th scope="col" className="table_data">Skill Type</th> */}
                        <th scope="col" className="table_data">Budget</th>
                        <th scope="col" className="table_data">Added By</th>
                        <th onClick={(e) => sorting('createdAt')}
                          scope="col" className="table_data pointer">Date{" "} <span className="mr-2">
                            {renderSortIcon('createdAt')}
                          </span></th>
                        <th scope="col" className="table_data">Status</th>
                        <th scope="col" className="table_data">Review Status</th>
                        <th scope="col" className='table_data'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProjectsData.map((item, index) => (<tr className='data_row'>
                        <td className='table_dats overflow_data'>{item?.name || item?.title || "---"}</td>
                        {/* <td>
                          {item.skillType?.length > 0 ?
                            <>
                              {item.skillType?.map((item) => {
                                return <span className="btn btn-primary mr-2">{item?.name}</span>
                              })}
                            </>
                            :
                            <span>No Skill Type</span>
                          }
                        </td> */}
                        {/* <td className='table_dats' >{item.categoryDetail&&item.categoryDetail.name}</td> */}
                        {/* <td className='table_dats' >{item.specialtyDetail&&item.specialtyDetail.name}</td> */}
                        <td className="table_dats"> USD {item?.hourlyRate || "---"}</td>
                        <td className="table_dats">{item.addedByDetail && item.addedByDetail.fullName || "---"}</td>
                        <td className="table_dats">{moment(item.createdAt).format("MM/DD/YYYY") || "---"}</td>
                        <td className="table_dats">{item.status == 'deactive' ? <Switch onChange={e => { setStatusData(item); setShowActiveModal("block") }} checked={false} />
                          : <Switch onChange={e => { setStatusData(item); setShowActiveModal("block") }} checked={true} />}</td>
                        <td className="table_dats">

                          <span className="d-flex align-items-center">{item?.admin_review === "reviewed" ? <button className="btn btn-primary moneyButtons">Reviewed</button> :
                            <button className="reviewButton" onClick={e => ReviewJob(e, item?.id)}>Review</button>
                          }
                          </span>
                        </td>

                        <td className='table_dats'>
                          <div className="action_icons">

                            <i className="fa fa-eye mt-2" data-toggle="modal" data-target="#exampleModal" onClick={e => { setProtofolioError1(false); setEditid(item.id); setView(true); setEditData(item); setEdit(true); setimages1(item.documents); setSelectedSkills(item?.hardSkills) }}></i>

                            {/* {isAllow('deleteSkillType') ? */}
                            <span className='edit_icon' onClick={() => { setDeleteId(item.id); setShowDeleteModal("block") }}>
                              <i class="material-icons delete" title='Delete'> delete</i>
                            </span>
                            {/* : <></>} */}
                          </div>
                        </td>
                        {/* end */}
                      </tr>))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* {ProjectsData?.length == 0 ? <div className="text-center text-danger m-5"><h5>No Data</h5></div> : null} */}
              {loading ? <h3 className="text-center mt-2">Loading...</h3> : ProjectsData.length == 0 ? (
                <div className="text-danger text-center">No Data</div>
              ) : null}

            </div>
            {total > filters.count ? (
              <div className='' style={{ float: "right" }}>

                <Pagination

                  activePage={filters.page}
                  itemClassPrev='back_page'
                  itemClassNext='next_page'
                  itemsCountPerPage={filters.count}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={pageChange}
                />
              </div>
            ) : null}

          </section>


          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{Edit ? View ? "View" : "Update" : "Add"} Project</h5>
                  <button type="button" class="close" id="closeprojectmodal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: Edit ? EditData?.name : "",
                    description: Edit ? EditData?.description : "",
                    documents: [],
                    technology: Edit ? EditData?.technology : "",
                    startDate: Edit ? EditData?.startDate : "",
                    endDate: Edit ? EditData?.endDate : ""
                  }}
                  onSubmit={(values) => {

                    const payload = { id: Editid, name: values.name, description: values.description, technology: values.technology, startDate: values.startDate, endDate: values.endDate, documents: images1, skills: SelectedSkills }
                    let method = "post";
                    if (Edit) {
                      method = "put";
                    } else {
                      delete payload.id;
                    }
                    if (payload.documents && payload.documents.length == 0) {
                      setProtofolioError1(true)
                      return false
                    } else {
                      loader(true);
                      ApiClient.allApi("project", payload, method).then(Res => {
                        if (Res.success) {
                          document.getElementById("closeprojectmodal").click();
                          GetAllProjects();
                          toast.success(Res.message);
                        }
                        loader(false);
                      })
                    }
                  }}
                >{({ values, handleChange, handleSubmit, handleBlur }) => (
                  <form onSubmit={handleSubmit}>
                    <div class="modal-body">
                      <div className="container-fluid"> <div className="row">
                        <div className="col-md-6 modalBorderBottom">
                          <div className="form-group">
                            <label className="weightlabel" htmlFor="">Title</label>
                            <p className=" text-capitalize weightdata">{EditData?.name || EditData?.title || "---"}</p>
                          </div>
                        </div>
                        <div className="col-md-6 modalBorderBottom">
                          <div className="form-group">
                            <label className="weightlabel">Skill Type</label>
                            <p className=" text-capitalize weightdata">
                              {EditData?.skillType_detail?.length > 0 ?
                                <>
                                  {EditData?.skillType_detail?.map((item) => {
                                    return <span className="bg_bages text-capitalize">{item?.name}</span>
                                  })}
                                </>
                                :
                                <span>No Skill Type</span>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 modalBorderBottom">
                          <div className="form-group">
                            <label className="weightlabel">Hard Skills</label>
                            <br />
                            {EditData?.hardSkill_detail?.length > 0 ?
                              <>
                                {EditData?.hardSkill_detail && EditData?.hardSkill_detail?.map((item) => (<span className="bg_bages text-capitalize">{item?.name || "---"}</span>))}
                              </>
                              : <span className="weightdata">No Hard Skills</span>}
                          </div>
                        </div>
                        <div className="col-md-6 modalBorderBottom">
                          <div className="form-group">
                            <label className="weightlabel">Budget</label>
                            <p className=" weightdata"> USD {EditData?.hourlyRate}</p>
                          </div>
                        </div>

                        <div className="col-md-12 modalBorderBottom">
                          <label className="weightlabel">Documents</label>
                          <div>
                            {images1?.map((item, index) =>
                              <div className="mr-3">
                                <p className="text-capitalize">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    width={40}
                                    className="document_image"
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                    onClick={(e) =>
                                      window.open(
                                        `${environment.api}images/document/${item}`
                                      )
                                    }
                                  />
                                </p>
                              </div>)}
                            {images1?.length === 0 || !images1?.length ? <div className="text-danger errorFont ">No Document</div> : null}
                          </div>
                          {/* {View ? null : <div className={`profile_btn_portfolio `}>
                            <label className="add_portfolio edit ml-3">
                              <input
                                id="bannerImage"
                                type="file"
                                multiple={true}
                                className="d-none"
                                // accept="image/*"
                                onChange={(e) => imageResult(e)}
                              />
                              <span className="add_portfolio">
                                <i class="material-icons add_port">add</i>
                              </span>
                            </label>
                          </div>} */}
                        </div>
                        <div>
                          {/* <div class="imagesRow d-flex">
                            {DoumentUploadLoading == true ? (
                              <div className="text-success">
                                Uploading...{" "}
                                <i className="fa fa-spinner fa-spin"></i>
                              </div>
                            ) : (
                              images1?.map((item, index) => (
                                <div className="mr-3">
                                  <p className="text-capitalize">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      width={40}
                                      className="document_image"
                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                      onClick={(e) =>
                                        window.open(
                                          `${environment.api}images/document/${item}`
                                        )
                                      }
                                    />
                                    {View ? null : <i
                                      className="fa fa-trash text-danger shadow-danger delet_icon"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        HanldDocumentDelete(e, index)
                                      }
                                    ></i>}
                                  </p>
                                </div>
                              ))
                            )}
                          </div> */}
                        </div>
                        {ProtofolioError1 && images1.length == 0 ? (
                          <div className="text-danger text-center mt-3 ml-5">
                            Please  Upload Documents {" "}
                          </div>
                        ) : null}

                        {values?.description ?
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="weightlabel" htmlFor="">Description</label>
                              <p className="weightdata" dangerouslySetInnerHTML={{ __html: values?.description }}></p>
                            </div>
                          </div>
                          : null}
                      </div></div>
                      <div className="col-md-12 text-right">
                        {View ? null : <button type="submit" className="btn btn-primary">Submit</button>}
                      </div>
                    </div>
                  </form>)}
                </Formik>
              </div>
            </div>
          </div>
        </></div>
    </Layout>
  );
};

export default Projects;
