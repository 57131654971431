import { useEffect, useState } from "react";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";
import { useSelector } from "react-redux";
import environment from "../../../environment";

export default function AddEditSkills() {
    const user = useSelector((state) => state.user);
    const { id } = useParams();
    const history = useHistory();
    const [formValues, setFormValues] = useState([{ name: "" }]);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(`skill?id=${id}`).then((result) => {
                if (result.success) {
                    const skill = result.data;
                    setFormValues([{ name: skill.name }]);
                }
                loader(false)
            })
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        let method = id ? "put" : "post";
        let url = "skill";
        let payload;

        if (id) {
            // Editing a skill: send only one record
            payload = {
                id: id,
                name: formValues[0].name // Only one skill is being edited
            };
        } else {
            // Adding skills: send multiple records
            payload = {
                data: formValues.map(value => ({
                    name: value.name
                }))
            };
        }

        console.log('Payload', payload);
        loader(true);
        ApiClient.allApi(url, payload, method).then((result) => {
            loader(false);
            if (result.success) {
                toast.success(result.message);
                history.push("/skills");
            }
        }).catch((error) => {
            loader(false);
            console.error('Error:', error);
            toast.error("An error occurred. Please try again.");
        });
    };

    const handleChange = (i, e) => {
        const { name, value } = e.target;
        const newFormValues = [...formValues];
        newFormValues[i][name] = value;
        setFormValues(newFormValues);
    };

    const addFormFields = () => {
        setFormValues([...formValues, { name: '' }]);
    };

    const removeFormFields = (i) => {
        const newFormValues = formValues.filter((_, index) => index !== i);
        setFormValues(newFormValues);
    };

    const isAllow = (key = "") => {
        let permissions = user.role?.permissions;
        let value = permissions?.[key];
        if (user.role.id === environment.adminRoleId) value = true;
        return value;
    };

 
    return (
        <Layout>
            <div className="heightVH">
                <form onSubmit={handleSubmit}>
                    <div className="d-flex gap-1 align-items-center mb-3">
                        <i
                            onClick={() => history.goBack()}
                            className="fa fa-angle-double-left fontBack text-dark"
                            title="Back"
                        ></i>
                        <h3 className="hedding">
                            {id ? 'Edit' : 'Add'} Soft Skill
                        </h3>
                    </div>
                    <div className="pprofile1">
                        <div className='d-flex flex-column w-100 px-3'>
                            <label>Name<span className="star">*</span></label>
                            <div className='row'>
                                {formValues.map((element, index) => (
                                    <div className="col-md-3 mb-3" key={index}>
                                        <div className='d-flex harrd_skills'>
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                className='form-control'
                                                value={element.name}
                                                onChange={e => handleChange(index, e)}
                                            />
                                            {formValues?.length === 1 ? null :
                                                <span className="edit_icon" onClick={() => { removeFormFields(index); }}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {id ? null : (
                            <div className="ml-2 skills_sofs">
                                <button className="btn btn-primary" type="button" onClick={addFormFields}>
                                    Add Soft Skill
                                </button>
                            </div>
                        )}
                        <div className="text-right">
                            {(!id || (id && isAllow("editSkills"))) && (
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
}
