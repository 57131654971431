import React, { useEffect, useState } from "react";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import methodModel from "../../../methods/methods";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../../methods/loader";


const DetailTestonomal = () => {
    const history = useHistory()
    const { id } = useParams()
    const [form, setForm] = useState({})
    const [images, setImages] = useState({ image: "" })

    useEffect(() => {

        loader(true)
        ApiClient.get(`testimonial?id=${id}`).then((res) => {
            const form = res.data
            setForm({ ...form, title: form.title, description: form.description, designation: form.designation })
            setImages({ ...images, image: form.image })

            loader(false)
        })

    }, [])
    return (
        <>
            <Layout>
               <div className="heightVH"> <div className="normalFlex">
                   <div className="d-flex gap-1 align-items-center"> 
                   <i onClick={() => history.push("/testonimal")} className="fa fa-angle-double-left fontBack  " title="Back"></i>

                   <h3 className="hedding">
                        Detail   Testimonials
                    </h3>
                      </div>
                    <div >

                        <button  onClick={() => history.push(`/testonimal/edit/${id}`)}  className='btn btn-primary d-flex align-items-center gap-1' >
                            <i class="material-icons edit  text-white" title="Edit">edit</i>
                            <span>Edit</span>
                        </button>
                       
                    </div>
                </div>
                <div className="pprofile1 my-3">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label>
                                Title<span className="star">*</span>
                            </label>
                            <div className="profiledetailscls">{form.title}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>
                                Designation<span className="star">*</span>
                            </label>
                            <div className="profiledetailscls">{form.designation || "---"}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>
                                Description<span className="star">*</span>
                            </label>
                            <div className='profiledetailscls' dangerouslySetInnerHTML={{ __html: form?.description }}></div>
                        </div>
                        <div className="col-md-12  mb-2">
                            <label className='lablefontcls'>Image</label>
                        </div>
                        <img
                            src={methodModel.userImg(form && form?.image)}
                            className="uploadimage uploadimageag"

                        />
                    </div>
                </div></div>
            </Layout>
        </>
    )
}

export default DetailTestonomal;