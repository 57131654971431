import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../methods/loader";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import { toast } from "react-toastify";

export default function Contactus() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [id, setId] = useState("");
  const [loading, setloading] = useState(true)

  const Listingdata = () => {
    loader(true);
    ApiClient.get("getallContactUs").then((res) => {
      setData(res.data);
    });
    setloading(false)
    loader(false);
  };

  useEffect(() => {
    Listingdata();
  }, []);

  const handleDelete = (id, status) => {
    if (!status) {
      setId(id);
      setShowDeleteModal("block");
    } else {
      ApiClient.delete(`contactUs?id=${id}`).then((res) => {
        if (res.success) {
          Listingdata();
          setShowDeleteModal("none");
          toast.success(res?.message);
        }
      });
    }
  };

  const Delete = () => {
    handleDelete(id, true);
  };

  return (
    <>
      <Layout>
        <div className="heightVH">
          {" "}
          <CommonDeleteModal
            show={ShowDeleteModal}
            setShow={setShowDeleteModal}
            confirm={Delete}
          />
          <h3 className=" hedding">Contacts</h3>
          <div className="table-responsive table_section mt-3">
            <table class="table table contracts_table ">
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Objective</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr>
                    <td scope="col">{item.firstName}</td>
                    <td scope="col">{item.lastName}</td>
                    <td scope="col">{item.email}</td>
                    <td>
                      <div
                        className="text_limit_table"
                        dangerouslySetInnerHTML={{ __html: item.message }}
                      ></div>
                    </td>
                    <td>
                      <div class="action_icons table_cons mt-1">
                        <span class="edit_icon" title="View">
                          <i onClick={() => history.push("/contact/" + item?._id)} class="fa fa-eye"></i>
                        </span>
                        <span class="edit_icon" title="Delete">
                          <i onClick={() =>
                            handleDelete(item?._id || item?.id, false)
                          } class="fa fa-trash text-danger"></i>
                        </span>
                      </div>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
              <div className="text-danger text-center">No Data</div>
            ) : null}
          </div>
        </div>
      </Layout>
    </>
  );
}
